import { Component, OnInit } from "@angular/core";
import { KukriProduct } from "../models/kukri-product";
import { TableRow } from "../../components/bs-table/models/table-row";
import { ShopAdminNavLinks } from "../models/shopadmin-nav-links";
import { HttpClientService } from "../../shared/service/impl/http-client.service";
import { SmoothDndOnDropEvent } from "../../shared/ngx-smooth-dnd/smooth-dnd-on-drop-event";
import { SmoothDndService } from "../../shared/ngx-smooth-dnd/smooth-dnd.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { KukriShopProductGroup } from "../models/kukri-shop-product-group";

@Component({
    selector: "my-app",
    templateUrl: "./shopadmin-product-variation-list.component.html",
})
export class ShopAdminProductVariationListComponent implements OnInit {
    public prodGroupId: number;
    public baseProductId: number;

    public baseProduct: KukriProduct;
    public productVariations: Array<KukriProduct>;

    public headerRow: TableRow;
    public bodyRows: Array<TableRow> = [];

    public shopProductGroups: Array<KukriShopProductGroup>;

    public hostPrefix: string;

    public shopProductVariations: any;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private httpClientService: HttpClientService,
        private smoothDndService: SmoothDndService
    ) {
        let inputJsonString = document.getElementById(
            "pageInputJson"
        ) as HTMLInputElement;
        let jsonData = JSON.parse(inputJsonString.value);

        console.log(jsonData);

        // if (jsonData.prodGroupId != null) {
        //     this.prodGroupId = jsonData.prodGroupId;
        // }

        // if (jsonData.baseProduct != null) {
        //     this.baseProduct = jsonData.baseProduct;
        // }

        // if (jsonData.productVariations != null) {
        //     this.productVariations = jsonData.productVariations;
        // }

        this.productVariations = [];

        if (jsonData.shopProductGroups != null)
            this.shopProductGroups = jsonData.shopProductGroups;

        // this.updateTable();

        this.hostPrefix = `${window.location.protocol}//${window.location.host}/teamshop/shopadmin`;
    }

    ngOnInit() {
        // TODO: This may be better as a service
        this.route.params.subscribe((data: Params) => {
            console.log(data);
            this.prodGroupId = parseInt(data.productGroupID);
            this.baseProductId = parseInt(data.productID);
        });

        this.shopProductGroups.filter(productGroup => {
            if (productGroup.id === this.prodGroupId) {
                this.shopProductVariations = productGroup;
                console.log(
                    "shopProductVariations",
                    this.shopProductVariations
                );

                console.log(productGroup);
                productGroup.variationProducts.filter(variationProduct => {
                    if (variationProduct.baseProductId === this.baseProductId) {
                        this.productVariations.push(variationProduct);
                    }
                });
                console.log(this.productVariations);
                this.baseProduct = this.productVariations[0];
            }
        });

        console.log(this.shopProductGroups);

        this.updateTable();
    }

    public updateTable() {
        this.headerRow = new TableRow(null, -1, "Name", "Status Code", "Edit/Delete");

        if (
            this.productVariations != null &&
            this.productVariations.length > 0
        ) {
            this.productVariations.forEach(variation => {
                const name =
                    variation.description != null
                        ? variation.description
                        : this.baseProduct.description;
                const status = variation.statusCode;

                this.bodyRows.push(
                    new TableRow(variation, variation.shopProductVariationId, name, status)
                );
            });
            console.log(this.bodyRows);
        }
    }

    public onRowDrop(event: SmoothDndOnDropEvent) {
        this.bodyRows = this.smoothDndService.updateAssociatedArray(
            event.addedIndex,
            event.removedIndex,
            this.bodyRows
        );
    }

    public editVariation(editRow: TableRow) {
        window.location.href = ShopAdminNavLinks.EDIT_PRODUCT_VARIATION.addUrlParam(
            "shopProdVariationId",
            editRow.id
        )
            .addUrlParam("prodGroupId", this.prodGroupId)
            .getHref();
    }

    public deleteVariation(deleteRow: TableRow) {
        const url = ShopAdminNavLinks.DELETE_PRODUCT_VARIATION.addUrlParam(
            "shopProdVariationId",
            deleteRow.id
        )
            .addUrlParam("prodGroupId", this.prodGroupId)
            .getHref();
        this.httpClientService
            .postAjaxForm(url, {})
            .subscribe(response => console.log(response));
        this.bodyRows = this.bodyRows.filter(row => row.id != deleteRow.id);
    }

    public createNewVariation() {
        window.location.href = ShopAdminNavLinks.CREATE_PRODUCT_VARIATION.addUrlParam(
            "prodId",
            this.baseProduct.id
        )
            .addUrlParam("prodGroupId", this.prodGroupId)
            .getHref();
    }

    public cancel() {
        window.location.href = ShopAdminNavLinks.SHOP_RETRIEVE_PRODUCTS_NG.getHref();
    }

    public goBack() {
        this.router.navigate(["retrieveProductsNg.action"]);
    }

    public editItemComponent(shopProductVariationId: number): void {
        let selectedShopProductVariation: KukriProduct;

        this.shopProductVariations.variationProducts.filter((item: KukriProduct) => {
            if (item.shopProductVariationId == shopProductVariationId) {
                selectedShopProductVariation = item;
                console.log(selectedShopProductVariation);
            }
        });

        this.router.navigate(["edit-product-variations"], {
            state: { productVariation: selectedShopProductVariation },
        });
    }
}
