import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "shopadmin-add-product-group",
    templateUrl: "./shopadmin-add-product-group.component.html"
})
export class ShopadminAddProductGroupComponent implements OnInit {
    @Output() readonly newProductGroup: EventEmitter<any> = new EventEmitter<any>();

    public productGroup: any;

    constructor(private dialogRef: MatDialogRef<ShopadminAddProductGroupComponent>) {
        this.productGroup = {
            name: "",
            sortOrder: "",
            hideGroup: false
        };
    }

    ngOnInit() {}

    public createNewProductGroup(quoteFormValues: any, groupHideCheckbox: boolean) {
        quoteFormValues.groupHide = groupHideCheckbox; // TODO - The Group Hide checkbox value needs to be assigned onto the form values
        this.newProductGroup.emit(quoteFormValues);
        this.dialogRef.close(quoteFormValues);
    }

    public closeAddGroupModal(values?: any) {
        this.dialogRef.close(values);
    }
}
