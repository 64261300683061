import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {SysCountryServiceImpl} from '../shared/service/impl/SysCountryServiceImpl';
import {HttpClientService} from '../shared/service/impl/http-client.service';
import {GoogleTagManagerServiceImpl} from '../shared/service/impl/GoogleTagManagerServiceImpl';
import {AuthService, AuthServiceConfig} from 'angularx-social-login';


import {CustomerAddressComponent} from './customer-address.component';
import {CustomerDetailComponent} from './customer-detail.component';
import {CustomerLoginComponent} from './customer-login.component';
import {CustomerAddressEditComponent} from './customer-address-edit.component';
import {CustomerForgottenPasswordComponent} from './customer-forgotten-password.component';
import {CustomerChangePasswordComponent} from './customer-change-password.component';

import {LoaderModule} from '../loader/loader.module';
import {socialAuthServiceConfig} from './customer-login.bootstrap.module';
import {CustomerLoginServiceImpl} from "./services/customer-login.service-impl";
import {CustomerLoginModalModule} from "./customer-login-modal.bootstrap.module";
import {AlertService} from "../components/alert/services/alert.service";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ToastrModule} from "ngx-toastr";
import {CustomerChangeForgottenPasswordComponent} from "./customer-change-forgotten-password.component";

@NgModule({
    imports: [
        LoaderModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        ToastrModule.forRoot(),
        CustomerLoginModalModule,
        BrowserAnimationsModule
    ],
    declarations: [
        CustomerAddressComponent,
        CustomerDetailComponent,
        CustomerLoginComponent,
        CustomerAddressEditComponent,
        CustomerForgottenPasswordComponent,
        CustomerChangeForgottenPasswordComponent,
        CustomerChangePasswordComponent,
    ],
    exports: [
        LoaderModule,
        CustomerAddressComponent,
        CustomerDetailComponent,
        CustomerLoginComponent,
        CustomerAddressEditComponent,
        CustomerForgottenPasswordComponent,
        CustomerChangeForgottenPasswordComponent,
        CustomerChangePasswordComponent,
        CustomerLoginModalModule,
    ],
    providers: [
        SysCountryServiceImpl,
        HttpClientService,
        AlertService,
        GoogleTagManagerServiceImpl,
        CustomerLoginServiceImpl,
        AuthService,
        {
            provide: AuthServiceConfig,
            useFactory: socialAuthServiceConfig,
        }],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CustomerModule {
}