import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditableComponent } from './editable.component';
import { BsInputModule } from "../bs-input/bs-input.module";

@NgModule({
  declarations: [EditableComponent],
  exports:      [
    EditableComponent
  ],
  imports:      [
    CommonModule,
    BsInputModule
  ]
})
export class EditableModule { }
