import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ShopadminMenuComponent } from "./shopadmin-menu/shopadmin-menu.component";
import { BsAccordionModule } from "../../components/bs-accordion/bs-accordion.module";
import { BsNavBaseModule } from "../../components/bs-nav-base/bs-nav-base.module";

@NgModule({
    declarations: [ShopadminMenuComponent],
    imports: [CommonModule, BsAccordionModule, BsNavBaseModule],
    exports: [ShopadminMenuComponent],
})
export class ShopadminMenuModule {}
