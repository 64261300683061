<img
	*ngIf="lazyLoad"
	class="shop-item__image position-absolute"
	alt=""
	offset="0"
	errorImage="../images/placeholder.png"
	[lazyLoad]="productImage"
/>
<img
	*ngIf="!lazyLoad"
    class="shop-item__image position-absolute"
    alt=""
    offset="0"
    errorImage="../images/placeholder.png"
    src="{{ productImage }}"
/>
<!--    [lazyLoad]="productImage"-->
<img
    class="shop-item__placeholder"
    src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' viewBox%3D'0 0 545 545'%3E %3Crect fill%3D'%23f0f0f0' width%3D'100%25' height%3D'100%25'%2F%3E%3C%2Fsvg%3E"
    alt=""
/>
