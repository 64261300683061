import {Injectable} from "@angular/core";

@Injectable()
export class hexSorterService {
    constructor() {}

    public hexToRgbS(hexVal: string): number[] {
        let r, g, b: number;

        let rgb: number[] = hexVal.length == 4 ?
            [
                r = this.hexToDec(hexVal[1] + hexVal[1]),
                g = this.hexToDec(hexVal[2] + hexVal[2]),
                b = this.hexToDec(hexVal[3] + hexVal[3])]
            :
            [
                r = this.hexToDec(hexVal[1] + hexVal[2]),
                g = this.hexToDec(hexVal[3] + hexVal[4]),
                b = this.hexToDec(hexVal[5] + hexVal[6])]
        ;

        return rgb;
    }

    public rgbToHsvS(rgbVal: number[]): number[] {
        let r: number = rgbVal[0] / 255;
        let g: number = rgbVal[1] / 255;
        let b: number = rgbVal[2] / 255;

        let h, s, min, max, del, dR, dG, dB: number;
        let hsv: number[] = [];

        min = Math.min(r, g, b);
        max = Math.max(r, g, b);
        del = max - min;

        if (del == 0) {
            h = 0;
            s = 0;
        } else {
            s = del / max;

            dR = ((max - r) / 6 + del / 2) / del;
            dG = ((max - g) / 6 + del / 2) / del;
            dB = ((max - b) / 6 + del / 2) / del;

            if (r == max) {
                h = dB - dG;
            } else if (g == max) {
                h = 1 / 3 + dR - dB;
            } else if (b == max) {
                h = 2 / 3 + dG - dR;
            }

            if (h < 0) {
                h++;
            }

            if (h > 1) {
                h--;
            }
        }

        hsv["h"] = Math.round(h * 360);
        hsv["s"] = s;
        hsv["v"] = max;

        return hsv;
    }

    public compareHue(a: any, b: any) {
        if (a.hsvValue["h"] < b.hsvValue["h"]) {
            return -1;
        }
        if (a.hsvValue["h"] > b.hsvValue["h"]) {
            return 1;
        }
        return 0;
    }

    public compareValue(a: any, b: any) {
        if (a.hsvValue["v"] < b.hsvValue["v"]) {
            return 1;
        }
        if (a.hsvValue["v"] > b.hsvValue["v"]) {
            return -1;
        }
        return 0;
    }

    ///////////////////////////////////////////////
    // the methods below were already here, and don't work the way I want them to
    // not sure if they're being used elsewhereand don't want to break anything so have left here for now

    private hexValueSanitize(color: any) {
        return color
            .replace(
                /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
                (m: any, r: any, g: any, b: any) => r + r + g + g + b + b
            )
            .replace("#", "");
    }

    private hexToDec(hex: any) {
        return parseInt((hex + "").replace(/[^a-f0-9]/gi, ""), 16);
    }

    private decToHex(number: any) {
        return number < 0
            ? 0xffffffff + number + 1
            : parseInt(number, 10).toString(16);
    }

    private hexToRgb(hex: any) {
        hex = this.hexValueSanitize(hex);
        return hex.length == 3
            ? [
                  this.hexToDec(hex[0] + hex[0]),
                  this.hexToDec(hex[1] + hex[1]),
                  this.hexToDec(hex[2] + hex[2]),
              ]
            : [
                  this.hexToDec(hex[0] + hex[1]),
                  this.hexToDec(hex[2] + hex[3]),
                  this.hexToDec(hex[4] + hex[5]),
              ];
    }

    private hexBrightness(hex: any, type: any) {
        let conversion;

        if (type == "BT601") {
            conversion = [0.299, 0.587, 0.114]; //BT601
        } else if (type == "BT709") {
            conversion = [0.2126, 0.7152, 0.0722]; //BT709
        } else if (type == "BT2020") {
            conversion = [0.2627, 0.678, 0.0593]; //BT2020
        } else {
            conversion = [0.299, 0.587, 0.114]; //BT601
        }

        hex = this.hexValueSanitize(hex);

        return (
            this.hexToDec(hex[0] + hex[1]) * conversion[0] +
            this.hexToDec(hex[2] + hex[3]) * conversion[1] +
            this.hexToDec(hex[4] + hex[5]) * conversion[2]
        );
    }

    private rgbToHsv(color: any) {
        let r = color[0] / 255;
        let g = color[1] / 255;
        let b = color[2] / 255;

        let h, s, min, max, del, dR, dG, dB;
        let hsl: any[] = [];

        min = Math.min(r, g, b);
        max = Math.max(r, g, b);
        del = max - min;

        if (del == 0) {
            h = 0;
            s = 0;
        } else {
            s = del / max;

            dR = ((max - r) / 6 + del / 2) / del;
            dG = ((max - g) / 6 + del / 2) / del;
            dB = ((max - b) / 6 + del / 2) / del;

            if (r == max) {
                h = dB - dG;
            } else if (g == max) {
                h = 1 / 3 + dR - dB;
            } else if (b == max) {
                h = 2 / 3 + dG - dR;
            }

            if (h < 0) {
                h++;
            }

            if (h > 1) {
                h--;
            }
        }

        hsl["h"] = h;
        hsl["s"] = s;
        hsl["v"] = 0.9;

        return hsl;
    }

    private hexToHsv(hex: any) {
        let rgb, hsv;

        hex = this.hexValueSanitize(hex);

        rgb = this.hexToRgb(hex);
        hsv = this.rgbToHsv(rgb);

        return hsv;
    }

    private mostBrightColor(colors: any, type: any) {
        let mostBright = false;
        let hex;

        colors.forEach((color: any) => {
            hex = this.hexValueSanitize(color);

            // brightness = this.hexBrightness(hex, type);
            // let brightness: number = this.hexBrightness(hex, type);
            if (!mostBright || (this.hexBrightness(hex, type) > this.hexBrightness(mostBright, type))) {
                mostBright = hex;
            }
        });

        return `#${mostBright}`;
    }

    private mostSaturatedColor(colors: any) {
        let mostSaturated = false;
        let hex, hsv, saturation, oldHsv: any;

        colors.forEach((color: any) => {
            hex = this.hexValueSanitize(color);
            hsv = this.hexToHsv(hex);

            saturation = hsv["s"];

            if (mostSaturated) {
                oldHsv = this.hexToHsv(mostSaturated);
            }

            if (!mostSaturated || saturation > oldHsv["s"]) {
                mostSaturated = hex;
            }
        });

        return `#${mostSaturated}`;
    }

    private colorMixer(hex1: any, hex2: any, percent: any) {
        hex1 = this.hexValueSanitize(hex1);
        hex2 = this.hexValueSanitize(hex2);

        if (hex1.length == 3) {
            hex1 =
                hex1.repeat(hex1[0], 2) +
                hex1.repeat(hex1[1], 2) +
                hex1.repeat(hex1[2], 2);
        }

        if (hex2.length == 3) {
            hex2 =
                hex2.repeat(hex2[0], 2) +
                hex2.repeat(hex2[1], 2) +
                hex2.repeat(hex2[2], 2);
        }

        let red_hex = this.decToHex(
            (percent * this.hexToDec(hex1[0] + hex1[1]) +
                (100 - percent) * this.hexToDec(hex2[0] + hex2[1])) /
                100
        ).padStart(2, "0");
        let green_hex = this.decToHex(
            (percent * this.hexToDec(hex1[2] + hex1[3]) +
                (100 - percent) * this.hexToDec(hex2[2] + hex2[3])) /
                100
        ).padStart(2, "0");
        let blue_hex = this.decToHex(
            (percent * this.hexToDec(hex1[4] + hex1[5]) +
                (100 - percent) * this.hexToDec(hex2[4] + hex2[5])) /
                100
        ).padStart(2, "0");

        return `#${red_hex + green_hex + blue_hex}`;
    }

    public sortColors(colors: any, type: any) {
        const input = colors.slice(0);
        const output = [];

        while (input.length > 0) {
            const color = this[type](input);
            let index = input.indexOf(color);
            if (index > -1) {
                input.splice(index, 1);
            }
            output.push(color);
        }
        return output;
    }

    private mixSortColors(colors: any, type: any, mixcolor: any, percentage: any) {
        const input = colors.slice(0);
        const output = [];

        while (input.length > 0) {
            const color = this[type](input);
            let index = input.indexOf(color);
            if (index > -1) {
                input.splice(index, 1);
            }
            output.push(this.colorMixer(color, mixcolor, percentage));
        }
        return output;
    }

    private isTypeColourMatched(colourObject: any, color: string) {
        return (colourObject.description && colourObject.description.toLowerCase().indexOf(color.toLowerCase()) !== -1) ||
            (colourObject.simpleColour && colourObject.simpleColour.toLowerCase().indexOf(color.toLowerCase()) !== -1)
    }

    public convertAndSortColours(colourCategory: any[]): any[] {
        let greys: any = [];
        let reds: any = [];
        let oranges: any = [];
        let yellows: any = [];
        let greens: any = [];
        let blues: any = [];
        let purples: any = [];
        let pinks: any = [];

        // Kukri Black 001 has different hex values for product and branding - it will break the system if changed
        // The black used on the physical product is true black, but for the purposes of showing the outline of the item front end, it appears slightly grey
        // Changing the hex value here of the product black to true black for sorting purposes only
        // The lighter black should still be shown on the product
        // converting hex colours to HSV
        colourCategory.forEach(colourObject => {
            if (colourObject.description == "Kukri Black -001") {
                colourObject["choiceHexValue"] = "#000000";
            }
            // if the colour is kukri black 001 use choiceHexValue for the sort
            if (colourObject.choiceHexValue) {
                let rgbVal = this.hexToRgbS(colourObject.choiceHexValue);
                colourObject["hsvValue"] = this.rgbToHsvS(rgbVal);
            } else if (colourObject.hexValue || colourObject.primaryHexCode) {
                let rgbVal = this.hexToRgbS(colourObject.hexValue || colourObject.primaryHexCode);
                colourObject["hsvValue"] = this.rgbToHsvS(rgbVal);
            } else {
             console.log('Ooops');
            }
        });

        // sorting them by hue
        colourCategory.sort(this.compareHue);

        // grouping them based on hue then sorting on value
        colourCategory.forEach(colourObject => {
            let hue: number = colourObject.hsvValue["h"];

            if ((hue == 0 && colourObject.hsvValue["s"] == 0) ||
                colourObject.hsvValue["s"] < 0.182 ||
                this.isTypeColourMatched(colourObject, 'grey') ||
                this.isTypeColourMatched(colourObject, 'white') ||
                this.isTypeColourMatched(colourObject, 'black')) {
                greys.push(colourObject);
            } else {
                // change this to switch/case
                if ((hue >= 0 && hue <= 10) ||
                    (hue >= 340 && hue <= 360) ||
                    this.isTypeColourMatched(colourObject, 'red')) {
                    reds.push(colourObject);
                } else if ((hue >= 11 && hue <= 45) ||
                    this.isTypeColourMatched(colourObject, 'orange')) {
                    oranges.push(colourObject);
                } else if ((hue >= 46 && hue <= 68) ||
                    this.isTypeColourMatched(colourObject, 'yellow')) {
                    yellows.push(colourObject);
                } else if ((hue >= 69 && hue <= 165) ||
                    this.isTypeColourMatched(colourObject, 'green')) {
                    greens.push(colourObject);
                } else if ((hue >= 166 && hue <= 260) ||
                    this.isTypeColourMatched(colourObject, 'blue')) {
                    blues.push(colourObject);
                } else if ((hue >= 261 && hue <= 305) ||
                    this.isTypeColourMatched(colourObject, 'purple')) {
                    purples.push(colourObject);
                } else if ((hue >= 306 && hue <= 339) ||
                    this.isTypeColourMatched(colourObject, 'pink')) {
                    pinks.push(colourObject);
                }
            }
        });

        // sorting each group by value
        greys = greys.sort(this.compareValue);
        reds = reds.sort(this.compareValue);
        oranges = oranges.sort(this.compareValue);
        yellows = yellows.sort(this.compareValue);
        greens = greens.sort(this.compareValue);
        blues = blues.sort(this.compareValue);
        purples = purples.sort(this.compareValue);
        pinks = pinks.sort(this.compareValue);

        return [...greys, ...oranges, ...yellows, ...greens, ...blues, ...purples, ...pinks, ...reds];
    };

}
