import { Component, Input, enableProdMode } from "@angular/core";

enableProdMode();

@Component({
  selector: "app-size-guide",
  templateUrl: "./product-size-guide.component.html"
})
export class ProductSizeGuideComponent {
  constructor() {}

  @Input() newProductFlag: any;
}
