<bs-input
    *ngIf="selected"
    [id]="id"
    [type]="editableType"
    [classes]="classes"
    [value]="inputValue"
    (focusOutEvent)="onSubmit($event)"
    (keyPressEvent)="onKeyPress($event)"
></bs-input>
<span style="border-bottom: 1px dotted" *ngIf="!selected" (click)="onClick()">
    <ng-content></ng-content>
</span>