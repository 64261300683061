import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ColourSelectComponent } from "./colour-select.component";

@NgModule({
    declarations: [ColourSelectComponent],
    imports: [CommonModule],
    exports: [ColourSelectComponent],
})
export class ColourSelectModule {}
