import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { LoaderService } from "../LoaderService";

@Injectable()
export class LoaderServiceImpl implements LoaderService {
    private loaderSubject = new Subject<boolean>();

    constructor() {}

    public show(): void {
        // console.log("Loader service show method");
        this.loaderSubject.next(true);
    }

    public hide(): void {
        // console.log("Loader service hide method");
        this.loaderSubject.next(false);
    }

    public getLoaderHandler(): Observable<any> {
        return this.loaderSubject.asObservable();
    }
}
