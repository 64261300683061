import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SmoothDndService {

  constructor() { }

  public updateAssociatedArray(addedIndex: number, removedIndex: number, arrayToUpdate: Array<any>): Array<any> {
    if(addedIndex > -1 && addedIndex < arrayToUpdate.length+1 &&
        removedIndex > -1 && removedIndex < arrayToUpdate.length+1) {
      const temp = arrayToUpdate[addedIndex];
      arrayToUpdate[addedIndex] = arrayToUpdate[removedIndex];
      arrayToUpdate[removedIndex] = temp;
    }

    return arrayToUpdate;
  }
}
