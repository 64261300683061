import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { NgxSmoothDnDModule } from "ngx-smooth-dnd";

import { LoaderModule } from "../../loader/loader.module";
import { LoaderComponent } from "../../loader/loader.component";

import { ShopAdminProductVariationListComponent } from "./shopadmin-product-variation-list.component";
import { BsTableModule } from "../../components/bs-table/bs-table.module";

import { HttpClientService } from "../../shared/service/impl/http-client.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AlertService } from "../../components/alert/services/alert.service";
import { ToastrModule } from "ngx-toastr";

@NgModule({
    imports: [
        LoaderModule,
        BrowserModule,
        BsTableModule,
        HttpClientModule,
        ToastrModule.forRoot(),
        BrowserAnimationsModule,
        NgxSmoothDnDModule,
    ],
    declarations: [ShopAdminProductVariationListComponent],
    exports: [ShopAdminProductVariationListComponent],
    providers: [HttpClientService, AlertService],
    bootstrap: [LoaderComponent, ShopAdminProductVariationListComponent],
})
export class ShopAdminProductVariationListModule {}
