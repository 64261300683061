import { Injectable, Optional } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { tap } from "rxjs/operators";
import { HttpClientInterface } from "../http-client.interface";
import { LoaderServiceImpl } from "./LoaderServiceImpl";
import { AlertService } from "../../../components/alert/services/alert.service";

declare var $: any;

@Injectable()
export class HttpClientService implements HttpClientInterface {
    private csrfHeaderName: string;
    private csrfToken: string;

    constructor(
        private http: HttpClient,
        @Optional() private loaderService: LoaderServiceImpl,
        private alertService: AlertService,
    ) {
        this.csrfToken = $("meta[name='_csrf']").attr("content");
        this.csrfHeaderName = $("meta[name='_csrf_header']").attr("content");
    }

    public postAjaxWithFile(url: string, postData: any, files: any): any {
        try {
            this.showLoader();
            let headers = new HttpHeaders();
            if (
                this.csrfToken != undefined &&
                this.csrfToken != null &&
                this.csrfToken != "" &&
                this.csrfHeaderName != undefined &&
                this.csrfHeaderName != null &&
                this.csrfHeaderName != ""
            ) {
                headers = headers.set(this.csrfHeaderName, this.csrfToken);
            }
            let formData: FormData = new FormData();
            // for single file
            // formData.append('files', files[0], files[0].name);
            // For multiple files
            //           for (let i = 0; i < files.length; i++) {
            //               formData.append(`files[]`, files[i], files[i].name);
            //           }
            if (files != undefined && files != null) {
                for (let key in files) {
                    let file = files[key];
                    formData.append(key, file, files.name);
                }
            }

            if (
                postData !== "" &&
                postData !== undefined &&
                postData !== null
            ) {
                for (let property in postData) {
                    if (postData.hasOwnProperty(property)) {
                        formData.append(property, postData[property]);
                    }
                }
            }

            return new Promise((resolve: any, reject: any) => {
                this.http
                    .post<Response>(url, formData, {
                        headers: headers,
                    })
                    .subscribe(
                        res => {
                            resolve(res);
                            this.hideLoader();
                        },
                        error => {
                            reject(error);
                            this.hideLoader();
                        }
                    );
            });
        } catch (e) {
            this.alertService.error(e);
        }

        return null;
    }

    public postNonAjaxForm(uri: string, submitData: any): boolean {
        try {
            $("body").append(
                '<form name="submitNonAjaxForm" id="submitNonAjaxForm" method="POST"/>'
            );
            $("#submitNonAjaxForm").attr("action", uri);
            if (submitData == undefined && submitData == null) {
                submitData = {};
            }
            // ZS: Added csrf spring security token
            if (
                this.csrfToken != undefined &&
                this.csrfToken != null &&
                this.csrfToken != "" &&
                this.csrfHeaderName != undefined &&
                this.csrfHeaderName != null &&
                this.csrfHeaderName != ""
            ) {
                submitData["_csrf"] = this.csrfToken;
            }
            for (let i in submitData) {
                if (submitData.hasOwnProperty(i)) {
                    $("#submitNonAjaxForm").append(
                        $('<input type="hidden" >')
                            .attr("id", i)
                            .attr("name", i)
                            .val(submitData[i])
                    );
                }
            }
            $("#submitNonAjaxForm").submit();
            $("#submitNonAjaxForm").remove();
        } catch (e) {
            this.alertService.error(e);
            return false;
        }
        return true;
    }

    public postAjaxForm(
        uri: string,
        submitData: any,
        options?: {},
        hideLoader?: boolean,
        pageRedirect?: boolean
    ): Observable<Response> {
        try {
            if (hideLoader == undefined || hideLoader == null || !hideLoader) {
                this.showLoader();
            }

            if (options == undefined || options == null) {
                let headers = new HttpHeaders();
                if (
                    this.csrfToken != undefined &&
                    this.csrfToken != null &&
                    this.csrfToken != "" &&
                    this.csrfHeaderName != undefined &&
                    this.csrfHeaderName != null &&
                    this.csrfHeaderName != ""
                ) {
                    headers = headers.set(this.csrfHeaderName, this.csrfToken);
                }
                options = { headers: headers };
            }

            return this.http.post<Response>(uri, submitData, options).pipe(
                tap({
                    next: x => {
                        if (
                            pageRedirect == undefined ||
                            pageRedirect == null ||
                            !pageRedirect
                        ) {
                            this.hideLoader();
                        }
                    },
                    error: err => {
                        this.handleError(err);
                        this.hideLoader();
                    },
                    complete: () => {
                        if (
                            pageRedirect == undefined ||
                            pageRedirect == null ||
                            !pageRedirect
                        ) {
                            this.hideLoader();
                        }
                    },
                })
            );
        } catch (e) {
            this.alertService.error(e);
        }

        return null;
    }

    public executeAjaxRequest(uri: string, options?: {}): Observable<Response> {
        try {
            this.showLoader();
            if (options == undefined || options == null) {
                let headers = new HttpHeaders({
                    Accept: "application/json",
                    "Content-Type": "application/json",
                });
                if (
                    this.csrfToken != undefined &&
                    this.csrfToken != null &&
                    this.csrfToken != "" &&
                    this.csrfHeaderName != undefined &&
                    this.csrfHeaderName != null &&
                    this.csrfHeaderName != ""
                ) {
                    headers = headers.set(this.csrfHeaderName, this.csrfToken);
                }
                options = { headers: headers };
            }
            // return this.http.get<Response>(uri, options).pipe(res => {
            //     this.handleError(res);
            //     // this.hideLoader();
            //     return res;
            // });

            return this.http.get<Response>(uri, options).pipe(
                tap({
                    next: x => {
                        // console.log("tap success", x);
                        this.hideLoader();
                    },
                    error: err => {
                        // console.log("tap error", err);
                        this.handleError(err);
                        this.hideLoader();
                    },
                    complete: () => {
                        // console.log("tap complete");
                        this.hideLoader();
                    },
                })
            );
        } catch (e) {
            this.hideLoader();
            this.alertService.error(e);
        }
    }

    private extractData(res: any): any {
        return res;
    }

    private handleError(error: any) {
        let errMsg = error.message
            ? error.message
            : error.status
            ? `${error.status} - ${error.statusText}`
            : "Server error";

        if (error.status == 404) {
            errMsg = "ajax request on invalid session:" + errMsg;
        }

        //console.log("ajx call error: "+ error); // log to console instead
        return throwError(errMsg);
    }

    public showLoader(): void {
        try {
            if (this.loaderService != null) {
                this.loaderService.show();
            }
        } catch (e) {
            this.alertService.error(e);
        }
    }

    public hideLoader(): void {
        try {
            if (this.loaderService != null) {
                this.loaderService.hide();
            }
        } catch (e) {
            this.alertService.error(e);
        }
    }
}
