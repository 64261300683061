<h1 class="text-center mb-4">{{ baseProduct.description }} Variations</h1>
<table *ngIf="bodyRows?.length > 0; else noProductVariations" class="table table-responsive table-striped mb-4">
    <thead bs-table-head [row]="headerRow" class="thead-dark"></thead>
    <tbody>
        <tr bs-table-row *ngFor="let row of bodyRows" [tableRow]="row">
            <button class="btn btn-primary" (click)="editItemComponent(row.id)">
                Edit
            </button>
            <button class="btn btn-secondary" (click)="deleteVariation(row)">
                Delete
            </button>
        </tr>
    </tbody>
</table>

<button class="btn btn-primary" (click)="createNewVariation()">
    Create New Variation
</button>
<button class="btn btn-secondary" (click)="goBack()">Cancel</button>

<ng-template #noProductVariations>
    <p>No product variations exist, would you like to <a href="" (click)="createNewVariation()">create a new product variation</a>?</p>
</ng-template>