import {HttpClientService} from './http-client.service';
import {SysCountryService} from '../SysCountryService';
import {Injectable} from '@angular/core';

@Injectable()
export class SysCountryServiceImpl implements SysCountryService {

    constructor(private httpClientService: HttpClientService) { }

    public retrieveCountryRegions(countryId: string, resultObj: any) : void {
        let uri: string = "lookupCountryRegionsAjaxNg?delCountryId=" + countryId;
        this.httpClientService.executeAjaxRequest(uri)
                            .subscribe(res => {
                                if (resultObj != null) {
                                    resultObj.splice(0, resultObj.length);
                                }
                                Array.prototype.push.apply(resultObj, res["regions"]);
                            },
                            error => console.log(error));

    }

    public retrieveCountryRegionsAndResetCountry(countryId: string, resultObj: any, countryObj: any) : void {
        let uri: string = "lookupCountryRegionsAndResetCountryAjaxNg?delCountryId=" + countryId;
        this.httpClientService.executeAjaxRequest(uri)
                            .subscribe(res => {
                                if (resultObj != null) {
                                    resultObj.splice(0, resultObj.length);
                                }
                                Array.prototype.push.apply(resultObj, res["regions"]);
                                countryObj =  res["country"];
                            },
                            error => console.log(error));

    }
}