<!-- Modal - Size Guide -->
<div
    class="modal fade"
    id="size-guide-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="size-guide-modal"
    aria-hidden="true"
>
    <div class="modal-dialog modal-lg mt-4 mx-md-auto" role="document">
        <div class="modal-content">
            <div
                class="modal-header position-relative"
                [class.p-0]="newProductFlag"
            >
                <h5
                    *ngIf="!newProductFlag"
                    class="modal-title"
                    id="size-guide-modal"
                >
                    Size Guide
                </h5>
                <img
                    *ngIf="newProductFlag"
                    src="../images/site/size-guide-alert.jpg"
                />
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true" class="text-white">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>
                    All values are based on body measurements, with the
                    exception of the inside leg, which is a garment measurement.
                    Actual garment measurements will depend upon the fit of the
                    specific product. For more information please see our fit
                    guide.
                </p>
                <div id="accordion-size-guide" role="tablist">
                    <div class="card">
                        <div
                            class="card-header card-header-sizes bg-grey-light3 bg-grey-light3 collapsed"
                            data-toggle="collapse"
                            data-parent="#accordion-size-guide"
                            href="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                            role="tab"
                            id="headingOne"
                        >
                            <h5 class="mb-0">
                                <a
                                    >Men's Unisex
                                    <span
                                        class="icon-keyboard_arrow_down"
                                    ></span>
                                </a>
                            </h5>
                        </div>
                        <div
                            id="collapseOne"
                            class="collapse p-4"
                            role="tabpanel"
                            aria-labelledby="headingOne"
                            data-parent="#accordion-size-guide"
                        >
                            <div class="row">
                                <div
                                    class="col-12 col-md-5 text-center justify-content-center"
                                >
                                    <table
                                        class="table table-striped table-bordered"
                                    >
                                        <thead class="thead-dark">
                                            <tr>
                                                <th scope="col">Size</th>
                                                <th scope="col">
                                                    To Fit Chest
                                                </th>
                                                <th scope="col">
                                                    To Fit Waist
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">2XS</th>
                                                <td>34"</td>
                                                <td>28"</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">XS</th>
                                                <td>36"</td>
                                                <td>30"</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">S</th>
                                                <td>38"</td>
                                                <td>32"</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">M</th>
                                                <td>40"</td>
                                                <td>34"</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">L</th>
                                                <td>42"</td>
                                                <td>36"</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">XL</th>
                                                <td>44"</td>
                                                <td>38"</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">XXL</th>
                                                <td>46"</td>
                                                <td>40"</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">3XL</th>
                                                <td>48"</td>
                                                <td>42"</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">4XL</th>
                                                <td>50"</td>
                                                <td>44"</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table
                                        class="table table-striped table-bordered"
                                    >
                                        <thead class="thead-dark">
                                            <tr>
                                                <th scope="col" colspan="2">
                                                    Leg Lengths
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">Short</th>
                                                <td>30"</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Regular</th>
                                                <td>32"</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Long</th>
                                                <td>34"</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div
                                    class="col-12 col-md-7 justify-content-center"
                                >
                                    <img
                                        src="https://8d14f65d94078b77527f-f0488b010f53cdb50245948df4de0bad.ssl.cf3.rackcdn.com/mens-size-guide-2x.jpg"
                                        alt=""
                                        class="img-fluid p-md-4"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div
                            class="card-header card-header-sizes border-top-0 bg-grey-light3 collapsed"
                            data-toggle="collapse"
                            data-parent="#accordion-size-guide"
                            href="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                            role="tab"
                            id="headingTwo"
                        >
                            <h5 class="mb-0">
                                <a>
                                    Women's
                                    <span
                                        class="icon-keyboard_arrow_down"
                                    ></span>
                                </a>
                            </h5>
                        </div>
                        <div
                            id="collapseTwo"
                            class="collapse p-4"
                            role="tabpanel"
                            aria-labelledby="headingTwo"
                            data-parent="#accordion-size-guide"
                        >
                            <div class="row">
                                <div
                                    class="col-12 col-md-5 text-center justify-content-center"
                                >
                                    <table
                                        class="table table-striped table-bordered"
                                    >
                                        <thead class="thead-dark">
                                            <tr>
                                                <th scope="col">Size</th>
                                                <th scope="col">
                                                    To Fit Chest
                                                </th>
                                                <th scope="col">
                                                    To Fit Waist
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">6</th>
                                                <td>30"</td>
                                                <td>24"</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">8</th>
                                                <td>32"</td>
                                                <td>26"</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">10</th>
                                                <td>34"</td>
                                                <td>28"</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">12</th>
                                                <td>36"</td>
                                                <td>30"</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">14</th>
                                                <td>38"</td>
                                                <td>32"</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">16</th>
                                                <td>40"</td>
                                                <td>34"</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">18</th>
                                                <td>42"</td>
                                                <td>36"</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">20</th>
                                                <td>44"</td>
                                                <td>38"</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">22</th>
                                                <td>46"</td>
                                                <td>40"</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table
                                        class="table table-striped table-bordered"
                                    >
                                        <thead class="thead-dark">
                                            <tr>
                                                <th scope="col" colspan="2">
                                                    Leg Lengths
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">Short</th>
                                                <td>28"</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Regular</th>
                                                <td>30"</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Long</th>
                                                <td>32"</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div
                                    class="col-12 col-md-7 justify-content-center"
                                >
                                    <img
                                        src="https://8d14f65d94078b77527f-f0488b010f53cdb50245948df4de0bad.ssl.cf3.rackcdn.com/womens-size-guide-2x.jpg"
                                        alt=""
                                        class="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div
                            class="card-header card-header-sizes border-top-0 bg-grey-light3 collapsed"
                            data-toggle="collapse"
                            data-parent="#accordion-size-guide"
                            href="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                            role="tab"
                            id="headingThree"
                        >
                            <h5 class="mb-0">
                                <a>
                                    Kids Unisex
                                    <span
                                        class="icon-keyboard_arrow_down"
                                    ></span>
                                </a>
                            </h5>
                        </div>
                        <div
                            id="collapseThree"
                            class="collapse p-4"
                            role="tabpanel"
                            aria-labelledby="headingThree"
                            data-parent="#accordion-size-guide"
                        >
                            <div class="row">
                                <div
                                    class="col-12 col-md-5 text-center justify-content-center"
                                >
                                    <table
                                        class="table table-striped table-bordered"
                                    >
                                        <thead class="thead-dark">
                                            <tr>
                                                <th scope="col">Size</th>
                                                <th scope="col">
                                                    To Fit Chest
                                                </th>
                                                <th scope="col">
                                                    To Fit Waist
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">3/4</th>
                                                <td>20-22"</td>
                                                <td>21-22"</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">5/6</th>
                                                <td>23-24""</td>
                                                <td>22-23"</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">7/8</th>
                                                <td>25-26"</td>
                                                <td>23-24"</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">9/10</th>
                                                <td>27-28"</td>
                                                <td>24-25"</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">11/12</th>
                                                <td>29-30"</td>
                                                <td>25-26"</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">13/14</th>
                                                <td>31-32"</td>
                                                <td>26-27"</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div
                                    class="col-12 col-md-7 justify-content-center"
                                >
                                    <img
                                        src="https://8d14f65d94078b77527f-f0488b010f53cdb50245948df4de0bad.ssl.cf3.rackcdn.com/kids-youth-size-guide-2x.jpg"
                                        alt=""
                                        class="img-fluid"
                                    />
                                </div>
                                <hr />
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div
                            class="card-header border-top-0 card-header-sizes bg-grey-light3 collapsed"
                            data-toggle="collapse"
                            data-parent="#accordion-size-guide"
                            href="#girls-size-chart"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                            role="tab"
                            id="headingFour"
                        >
                            <h5 class="mb-0">
                                <a>
                                    Youths Unisex
                                    <span
                                        class="icon-keyboard_arrow_down"
                                    ></span>
                                </a>
                            </h5>
                        </div>
                        <div
                            id="girls-size-chart"
                            class="collapse p-4"
                            role="tabpanel"
                            aria-labelledby="headingFour"
                            data-parent="#accordion-size-guide"
                        >
                            <div class="row">
                                <div
                                    class="col-12 col-md-5 text-center justify-content-center"
                                >
                                    <table
                                        class="table table-striped table-bordered"
                                    >
                                        <thead class="thead-dark">
                                            <tr>
                                                <th scope="col">Size</th>
                                                <th scope="col">
                                                    To Fit Chest
                                                </th>
                                                <th scope="col">
                                                    To Fit Waist
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">YS</th>
                                                <td>28-29"</td>
                                                <td>25-26"</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">YM</th>
                                                <td>30-31"</td>
                                                <td>26-27"</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">YL</th>
                                                <td>32-33"</td>
                                                <td>27-28"</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div
                                    class="col-12 col-md-7 justify-content-center"
                                >
                                    <img
                                        src="https://8d14f65d94078b77527f-f0488b010f53cdb50245948df4de0bad.ssl.cf3.rackcdn.com/kids-youth-size-guide-2x.jpg"
                                        alt=""
                                        class="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
