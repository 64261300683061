import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { HttpClientService } from "../shared/service/impl/http-client.service";
import { SysCountryServiceImpl } from "../shared/service/impl/SysCountryServiceImpl";
import { GoogleTagManagerServiceImpl } from "../shared/service/impl/GoogleTagManagerServiceImpl";
import { sortByPipesModule } from "../shared/common/sort-by-pipes.module";
//import { SafeHTMLPipe }      from '../shared/pipe/SafeHTMLPipe';
import { SafeHtmlPipeModule } from "../shared/common/safehtml-pipe.module";
import { BuyKitbagComponent } from "./buy-kitbag.component";
import { EditKitbagComponent } from "./edit-kitbag.component";
import { LoaderModule } from "../loader/loader.module";
import { TeamPlayersComponent } from "../shared/team-players/team-players.component";
import { ProductSizeGuideBootstrapModule } from "../shop/product-size-guide.bootstrap.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AlertService } from "../components/alert/services/alert.service";
import { ToastrModule } from "ngx-toastr";
import {KitDesignLiteServiceImpl} from "../core/service/impl/KitDesignLiteServiceImpl";

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        ToastrModule.forRoot(),
        BrowserAnimationsModule,
        SafeHtmlPipeModule,
        LoaderModule,
        ProductSizeGuideBootstrapModule,
        sortByPipesModule,
    ],
    declarations: [
        EditKitbagComponent,
        BuyKitbagComponent,
        TeamPlayersComponent,
    ],
    exports: [
        LoaderModule,
        EditKitbagComponent,
        BuyKitbagComponent,
        SafeHtmlPipeModule,
        ProductSizeGuideBootstrapModule,
        sortByPipesModule,
    ],
    providers: [
        KitDesignLiteServiceImpl,
        SysCountryServiceImpl,
        HttpClientService,
        AlertService,
        GoogleTagManagerServiceImpl,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class KitbagModule {}
