import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientService } from '../shared/service/impl/http-client.service';

import { HeaderComponent } from './header.component';
import { FooterComponent } from './footer.component';
import { AlertService } from "../components/alert/services/alert.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";

@NgModule({
  imports:      [ BrowserModule, FormsModule, HttpClientModule, ToastrModule.forRoot(), BrowserAnimationsModule],
  declarations: [ HeaderComponent, FooterComponent ],
  exports :[HeaderComponent, FooterComponent],
  providers: [HttpClientService, AlertService]
})
export class HeaderFooterModule { }