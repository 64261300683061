import {Directive, ElementRef, Input, OnInit, AfterViewInit} from '@angular/core';

interface boundingBox {
    containerWidth: number;
    containerHeight: number;
}

@Directive({
    selector: '[draw-logos]'
})

export class drawLogosDirective implements OnInit {

    private HTMLCanvasElement: HTMLCanvasElement;
    private canvasElement: any;
    private devicePixelRatio: number;

    @Input() productAttributes: any;
    @Input() groupIdx: any;

    constructor(private element: ElementRef) {
        this.HTMLCanvasElement = this.element.nativeElement as HTMLCanvasElement;
        this.devicePixelRatio = window.devicePixelRatio;
    }

    public ngOnInit() {
        // console.log("ngOnInit")
        // this.setCanvasProperties();
        // this.scaleCanvasToDeviceDpi();
        // this.drawLogosToCanvas(this.productAttributes);
    }

    public ngAfterViewInit() {
        this.setCanvasProperties();
        this.scaleCanvasToDeviceDpi();
        this.drawLogosToCanvas(this.productAttributes);
    }

    private setCanvasProperties() {
        const useElement = typeof this.groupIdx === 'number' && $('#productImageLogoCanvas-'+this.groupIdx).length > 0 ?
            $('#productImageLogoCanvas-'+this.groupIdx)[0] : this.HTMLCanvasElement
        const canvasElementComputedStyleValues: any =
            this.getElementComputedStyleValues(useElement, ["width", "height"]);
        const { width, height } = canvasElementComputedStyleValues;
        const minSize = Number(width.slice(0, -2) * 3) * 12/100

        this.canvasElement = {};

        this.canvasElement.widthOrginal = 900;
        this.canvasElement.heightOriginal = 900;

        this.canvasElement.styleWidth = typeof this.groupIdx === 'number' ? minSize : Number(width.slice(0, -2));
        this.canvasElement.styleHeight = typeof this.groupIdx === 'number' ? minSize : Number(height.slice(0, -2));

        this.canvasElement.widthNewScaledToDpi = Math.floor(this.canvasElement.styleWidth * this.devicePixelRatio);
        this.canvasElement.heightNewScaledToDpi = Math.floor(this.canvasElement.styleHeight * this.devicePixelRatio);

        this.canvasElement.widthNewRatio = this.getRatio(this.canvasElement.widthNewScaledToDpi, this.canvasElement.widthOrginal);
        this.canvasElement.heightNewRatio = this.getRatio(this.canvasElement.heightNewScaledToDpi, this.canvasElement.heightOriginal);
    }

    private scaleCanvasToDeviceDpi() {
        this.HTMLCanvasElement.setAttribute('width', String(this.canvasElement.widthNewScaledToDpi));
        this.HTMLCanvasElement.setAttribute('height', String(this.canvasElement.heightNewScaledToDpi));
    }

    private drawLogosToCanvas(productAttributes: any) {
        for (let productAttribute of productAttributes) {
            if (productAttribute.attributeType == 'LOGO-CS' &&
                productAttribute.positionView == 'front' &&
                productAttribute.logoUrl != null) {
                this.drawLogoToCanvas(productAttribute);
            }
        }
    }

    private drawLogoToCanvas(productAttribute: any) {
        let context: CanvasRenderingContext2D = this.HTMLCanvasElement.getContext("2d");
        let imageLogo: HTMLImageElement = new Image();
        // let logoScale: number = 1;
        let logoURL: string = productAttribute.logoDisplayUrl;
        let { containerWidth, containerHeight } = this.getBoundingBox(productAttribute.nanTextFontSize);

        containerWidth = containerWidth * this.canvasElement.widthNewRatio;
        containerHeight = containerHeight * this.canvasElement.heightNewRatio;

        let positionX: number = productAttribute.positionX * this.canvasElement.widthNewRatio;
        let positionY: number = productAttribute.positionY * this.canvasElement.heightNewRatio;

        imageLogo.onload = () => {
            const logoScaleWidth: number = containerWidth / imageLogo.width; // 60 / 500
            const logoScaleHeight: number = containerHeight / imageLogo.height;

            const logoScale: number = this.setLogoScale(logoScaleHeight, logoScaleWidth);

            const logoImageWidth: number = Math.floor(imageLogo.width * logoScale);
            const logoImageHeight: number = Math.floor(imageLogo.height * logoScale);

            positionX = Math.floor(positionX - (logoImageWidth / 2));
            positionY = Math.floor(positionY - (logoImageHeight / 2));

            context.drawImage(
                imageLogo,
                positionX,
                positionY,
                logoImageWidth,
                logoImageHeight
            );
        };
        imageLogo.src = logoURL;
    }

    private setLogoScale(logoScaleHeight: number, logoScaleWidth: number): number {
        return logoScaleHeight > logoScaleWidth ? logoScaleWidth : logoScaleHeight;
    }

    private getRatio(newSize: number, orginalSize: number): number {
        return newSize / orginalSize;
    }

    private getElementComputedStyleValues(element: HTMLElement, propertyValues: string[]): object {
        const elementComputedStyleValues: object = {};

        for (let propertyValue of propertyValues) {
            elementComputedStyleValues[propertyValue] = this.getElementComputedStyleValue(element, propertyValue);
        }
        return elementComputedStyleValues;
    }

    private getElementComputedStyleValue(element: HTMLElement, propertyValue: string): string {
        return window.getComputedStyle(element).getPropertyValue(propertyValue);
    }

    private getBoundingBox(nanTextFontSize: any): boundingBox {
        if (nanTextFontSize != null) {
            nanTextFontSize = nanTextFontSize.split(",");
            return {
                containerWidth: parseInt(nanTextFontSize[0]),
                containerHeight: parseInt(nanTextFontSize[1])
            }
        } else {
            return {
                containerWidth: 100,
                containerHeight: 100
            }
        }
    }

}
