import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { HttpClientService } from "../shared/service/impl/http-client.service";
import { SysCountryServiceImpl } from "../shared/service/impl/SysCountryServiceImpl";
import { GoogleTagManagerServiceImpl } from "../shared/service/impl/GoogleTagManagerServiceImpl";
//import { SafeHTMLPipe }      from '../shared/pipe/SafeHTMLPipe';
import { SafeHtmlPipeModule } from "../shared/common/safehtml-pipe.module";
import { OrderBasketComponent } from "./order-basket.component";
import { OrderEditPlayerComponent } from "./order-edit-player.component";
import { OrderCheckoutComponent } from "./order-checkout.component";
import { OrderCompleteComponent } from "./order-complete.component";
import { QuoteCompleteComponent } from "./quote-complete.component";
import { LoaderModule } from "../loader/loader.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AlertService } from "../components/alert/services/alert.service";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ToastrModule } from "ngx-toastr";

@NgModule({
    imports: [
        LoaderModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        ToastrModule.forRoot(),
        MatTooltipModule,
        BrowserAnimationsModule,
        SafeHtmlPipeModule,
    ],
    declarations: [
        OrderBasketComponent,
        OrderEditPlayerComponent,
        OrderCheckoutComponent,
        OrderCompleteComponent,
        QuoteCompleteComponent,
    ],
    exports: [
        LoaderModule,
        OrderBasketComponent,
        OrderEditPlayerComponent,
        OrderCheckoutComponent,
        OrderCompleteComponent,
        QuoteCompleteComponent,
        SafeHtmlPipeModule,
    ],
    providers: [
        SysCountryServiceImpl,
        HttpClientService,
        AlertService,
        GoogleTagManagerServiceImpl,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OrderModule {}
