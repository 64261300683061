import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientService } from '../shared/service/impl/http-client.service';
import { SysCountryServiceImpl } from '../shared/service/impl/SysCountryServiceImpl';
import { GoogleTagManagerServiceImpl } from '../shared/service/impl/GoogleTagManagerServiceImpl';

import { SafeHtmlPipeModule } from '../shared/common/safehtml-pipe.module';
import { LoaderModule } from '../loader/loader.module';

import { AccountLogoComponent } from './account-logo.component';
import { AccountMainComponent } from './account-main.component';
import { AccountOrdersComponent } from './account-orders.component';
import { AccountDesignsComponent } from './account-designs.component';
import { AccountShopListComponent } from './account-shop-list.component';
import { AccountKitbagsComponent } from './account-kitbags.component';
import { AccountTeamsComponent } from './account-teams.component';
import { AccountTeamEditComponent } from './account-team-edit.component';
import { AccountShareRevokeDesignComponent } from './account-share-revoke-design.component';
import { AccountBrochuresComponent } from './account-brochures.component';
import { AccountBrochureEditComponent } from './account-brochure-edit.component';
import { AlertService } from "../components/alert/services/alert.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";

@NgModule({
  imports:      [ BrowserModule, FormsModule, HttpClientModule, ToastrModule.forRoot(), SafeHtmlPipeModule, LoaderModule, BrowserAnimationsModule],
  declarations: [ AccountLogoComponent, AccountMainComponent, AccountOrdersComponent, AccountDesignsComponent, AccountShopListComponent, AccountKitbagsComponent, AccountTeamsComponent, AccountTeamEditComponent, AccountShareRevokeDesignComponent, AccountBrochuresComponent, AccountBrochureEditComponent],
  exports :[LoaderModule, AccountLogoComponent, AccountMainComponent, AccountOrdersComponent, AccountDesignsComponent, SafeHtmlPipeModule, AccountShopListComponent, AccountKitbagsComponent, AccountTeamsComponent, AccountTeamEditComponent, AccountShareRevokeDesignComponent, AccountBrochuresComponent, AccountBrochureEditComponent],
  providers: [SysCountryServiceImpl, HttpClientService, AlertService, GoogleTagManagerServiceImpl],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AccountModule { }