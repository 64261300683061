import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { RouterModule } from "@angular/router";

// Custom Modules
import { SafeHtmlPipeModule } from "../shared/common/safehtml-pipe.module";

// Components
import { ProductItemComponent } from "./product-item.component";
import { ProductItemPriceComponent } from "./product-item-price/product-item-price.component";
import { ProductItemTagComponent } from "./product-item-tag/product-item-tag.component";
import { ProductItemPersonaliseComponent } from "./product-item-personalise/product-item-personalise.component";
import { ProductItemLogosComponent } from "./product-item-logos/product-item-logos.component";
import { ProductItemImageComponent } from "./product-item-image/product-item-image.component";
import { ProductItemImageSvgComponent } from "./product-item-image-svg/product-item-image-svg.component";
import { ProductItemCodeComponent } from "./product-item-code/product-item-code.component";

@NgModule({
    declarations: [
        ProductItemComponent,
        ProductItemPriceComponent,
        ProductItemTagComponent,
        ProductItemPersonaliseComponent,
        ProductItemLogosComponent,
        ProductItemImageComponent,
        ProductItemImageSvgComponent,
        ProductItemCodeComponent,
    ],
    imports: [
        CommonModule,
        LazyLoadImageModule,
        SafeHtmlPipeModule,
        RouterModule,
    ],
    exports: [
        ProductItemComponent,
        ProductItemPriceComponent,
        ProductItemTagComponent,
        ProductItemPersonaliseComponent,
        ProductItemLogosComponent,
        ProductItemImageComponent,
        ProductItemImageSvgComponent,
        ProductItemCodeComponent,
    ],
})
export class ProductItemModule {}
