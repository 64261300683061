import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector:    'bs-button',
  templateUrl: './bs-button.component.html'
})
export class BsButtonComponent implements OnInit {

  @Input() public id: string | number;
  @Input() public text: string;
  @Input() public classes?: string = "btn btn-secondary";
  @Input() public type?: string;
  @Input() public iconClass?: string;
  @Input() public isDisabled?: boolean;

  constructor() { }

  ngOnInit() {
  }
}
