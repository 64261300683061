import {User} from "./user.class";
import {Customer as CustomerInterface} from "./customer.interface";

export class Customer implements CustomerInterface {
	id: number = -1;
	title: string = "";
	firstname: string = "";
	surname: string = "";
	email: string = "";
	accountType: string = "";
	companyName: string = "";
	passwordConfirm: string = "";
	password: string = "";
	user: User = new User();
}