import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BsTableComponent } from "./bs-table.component";
import { BsTableHeadComponent } from "./bs-table-head/bs-table-head.component";
import { BsTableBodyComponent } from "./bs-table-body/bs-table-body.component";
import { BsTableFootComponent } from "./bs-table-foot/bs-table-foot.component";
import { BsTableRowComponent } from "./bs-table-row/bs-table-row.component";
import { BsTableCellComponent } from "./bs-table-cell/bs-table-cell.component";
import { BsTableCaptionComponent } from "./bs-table-caption/bs-table-caption.component";
import { BsTableButtonComponent } from "./bs-table-button/bs-table-button.component";
import { EditableModule } from "../editable/editable.module";

@NgModule({
    declarations: [
        BsTableComponent,
        BsTableHeadComponent,
        BsTableBodyComponent,
        BsTableFootComponent,
        BsTableRowComponent,
        BsTableCellComponent,
        BsTableCaptionComponent,
        BsTableButtonComponent,
    ],
    imports: [CommonModule,
              EditableModule],
    exports: [
        BsTableComponent,
        BsTableCaptionComponent,
        BsTableHeadComponent,
        BsTableBodyComponent,
        BsTableFootComponent,
        BsTableButtonComponent,
        BsTableRowComponent,
    ],
})
export class BsTableModule {}
