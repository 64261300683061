import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {sortByAZPipe} from "../pipe/sort-by-a-z.pipe";
import {sortByNumberPipe} from "../pipe/sort-by-number.pipe";
import {hexSorterService} from "../service/hex-sorter.service";

@NgModule({
    declarations: [sortByAZPipe, sortByNumberPipe],
    exports: [sortByAZPipe, sortByNumberPipe],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [hexSorterService],
})
export class sortByPipesModule {}
