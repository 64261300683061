import { Injectable } from "@angular/core";
import { GoogleTagManagerServiceImpl } from "../../shared/service/impl/GoogleTagManagerServiceImpl";
import { ProductItemService } from "../models/product-item.interface";

@Injectable()
export class ProductItemServiceImpl implements ProductItemService {
    constructor(private googleTagManagerService: GoogleTagManagerServiceImpl) {}

    public deriveProductUrl(
        product: any,
        mainColorHex: any,
        secondaryColorHex: any,
        detailColorHex: any,
        shopUrl: string
    ): string {
        // TODO: This could be split into design products and off the shelf products, wherever custom colors will never be selected

        let colourUrl: string = "";

        if (mainColorHex) {
            mainColorHex = mainColorHex.replace("#", "");
            colourUrl = `&selectedMainColour=${mainColorHex}`;
        }

        if (secondaryColorHex) {
            secondaryColorHex = secondaryColorHex.replace("#", "");
            colourUrl += `&selectedSecondaryColour=${secondaryColorHex}`;
        }

        if (detailColorHex) {
            detailColorHex = detailColorHex.replace("#", "");
            colourUrl += `&selectedDetailColour=${detailColorHex}`;
        }

        // TODO: Will likely need to review how flexible the shop url is when it comes to using this component in the admin screens

        const productType: string = product.productType;
        const baseProductId: any = product.baseProductId;
        const shopProductVariationId: any = product.shopProductVariationId;
        const teamshopUrl = `/teamshop/${shopUrl}`;

        if (productType == "Bundle")
            return `${teamshopUrl}/buyBundleProductNg?shopBundleId=${baseProductId}`;
        if (productType == "DesignTemplate")
            return `${teamshopUrl}/kdSelectFit?designTemplateId=${baseProductId}${colourUrl}`;
        if (productType == "Design")
            return `${teamshopUrl}/buyDesignProductNg?productId=${baseProductId}${colourUrl}`;
        if (shopProductVariationId != null)
            return `${teamshopUrl}/retrieveProdNg?prodId=${baseProductId}&shopProdVariationId=${shopProductVariationId}`;
        return `${teamshopUrl}/retrieveProdNg?prodId=${baseProductId}`;
    }

    // TODO: Need to make this optional
    // Send Product Click information to Google Analytics
    public gtmProductClick(
        productObject: any,
        list: string,
        position: number,
        shopTitle: string
    ): void {
        let productDatas: Array<any> = [];

        let productData: any = this.googleTagManagerService.buildProductDataForGTMPush(
            productObject.code, // ID
            productObject.description, // Name
            "Kukri", // Brand
            productObject.productType, // Category
            null, // Variant
            productObject.unitChargeExclTax, // Price
            null, // Quantity
            null, // Coupon
            position // Uses Index
        );

        list = `${shopTitle} - ${list}`;

        if (productData != undefined) {
            productDatas.push(productData);
        }

        this.googleTagManagerService.pushClickProductEventToGoogleAnalytics(
            productDatas,
            list
        );
    }
}
