import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AuthService, AuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';

import { CustomerLoginComponent } from './customer-login.component';
import { CustomerModule } from './customer.module';
import { LoaderModule } from '../loader/loader.module';
import { LoaderComponent } from '../loader/loader.component';
import { CustomerLoginServiceImpl } from "./services/customer-login.service-impl";

export function socialAuthServiceConfig() {
	let pageInputJson = $('#pageInputJson');
    if(pageInputJson != null && pageInputJson.val() != null) {
        const jsonData = JSON.parse(pageInputJson.val().toString());
        return new AuthServiceConfig([
            {
                id:       GoogleLoginProvider.PROVIDER_ID,
                provider: new GoogleLoginProvider(jsonData.socialMediaProviderGoogleAppId),
                lazyLoad: true
            },
            {
                id:       FacebookLoginProvider.PROVIDER_ID,
                provider: new FacebookLoginProvider(jsonData.socialMediaProviderFacebookAppId),
                lazyLoad: true
            }
        ]);
    }
  }

@NgModule({ 
 imports: [LoaderModule, CustomerModule],
 bootstrap: [LoaderComponent, CustomerLoginComponent],
 schemas: [CUSTOM_ELEMENTS_SCHEMA],
 providers: [
    AuthService,
    {provide: AuthServiceConfig,
     useFactory: socialAuthServiceConfig},
     CustomerLoginServiceImpl
]
})
export class CustomerLoginBootstrapModule { }
