import { NgModule } from "@angular/core";
import {CommonModule} from "@angular/common";

import { BsAccordionComponent } from "./bs-accordion.component";

@NgModule({
    imports: [CommonModule],
    declarations: [BsAccordionComponent],
    exports: [BsAccordionComponent],
})
export class BsAccordionModule {}
