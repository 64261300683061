import { Injectable } from '@angular/core';
import { ActiveToast, IndividualConfig, ToastrService } from "ngx-toastr";

enum ALERT_TITLE {
	INFO = 'INFO',
	SUCCESS = 'SUCCESS',
	ERROR = 'ERROR',
	WARNING = 'WARNING'
}

enum ALERT_POSITION_CLASS {
	TOP_RIGHT = 'toast-top-right',
	BOTTOM_RIGHT = 'toast-bottom-right',
	BOTTOM_LEFT = 'toast-bottom-left',
	TOP_LEFT = 'toast-top-left',
	TOP_FULL_WIDTH = 'toast-top-full-width',
	BOTTOM_FULL_WIDTH = 'toast-bottom-full-width',
	TOP_CENTER = 'toast-top-center',
	BOTTOM_CENTER = 'toast-bottom-center'
}

@Injectable()
export class AlertService {

	private defaultSuccessDuration: number = 4000;
	private defaultErrorDuration: number = 12000;

	constructor(private notification: ToastrService) { }

	public info(message: string | string[] | String[], timeOut?: number, title?: string): ActiveToast<any> {
		timeOut = timeOut != null && timeOut > 0 ? timeOut : this.defaultErrorDuration;
		title = title != null ? title : ALERT_TITLE.INFO;

		return this.notification.info(this.consolidateMessage(message), title, this.getNotificationConfig(timeOut));
	}

	public success(message: string | string[] | String[], timeOut?: number, title?: string): ActiveToast<any> {
		timeOut = timeOut != null && timeOut > 0 ? timeOut : this.defaultSuccessDuration;
		title = title != null ? title : ALERT_TITLE.SUCCESS;

		return this.notification.success(this.consolidateMessage(message), title, this.getNotificationConfig(timeOut));
	}

	public error(message: string | string[] | String[], timeOut?: number, title?: string): ActiveToast<any> {
		timeOut = timeOut != null && timeOut > 0 ? timeOut : this.defaultErrorDuration;
		title = title != null ? title : ALERT_TITLE.ERROR;

		return this.notification.error(this.consolidateMessage(message), title, this.getNotificationConfig(timeOut));
	}

	public warning(message: string | string[] | String[], timeOut?: number, title?: string): ActiveToast<any> {
		timeOut = timeOut != null && timeOut > 0 ? timeOut : this.defaultErrorDuration;
		title = title != null ? title : ALERT_TITLE.WARNING;

		return this.notification.warning(this.consolidateMessage(message), title, this.getNotificationConfig(timeOut));
	}

	private consolidateMessage(message: string | string[] | String[]): string {
		if(message != null) {
			return typeof message !== "string" ? message.join('\n') : message;
		} else {
			return "Error Encountered.\n Please report this issue to our customer support service."
		}
	}

	private getNotificationConfig(timeOut?: number): Partial<IndividualConfig> {
		return {
			timeOut: timeOut,
			tapToDismiss: false,
			closeButton: true,
			disableTimeOut: timeOut == null || timeOut < 1,
			progressAnimation: "decreasing",
			positionClass: ALERT_POSITION_CLASS.BOTTOM_RIGHT
		};
	}

}
