import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientService } from '../shared/service/impl/http-client.service';

import { HeaderMsComponent } from './header-ms.component';
import { FooterMsComponent } from './footer-ms.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AlertService } from "../components/alert/services/alert.service";
import { ToastrModule } from "ngx-toastr";

@NgModule({
  imports:      [ BrowserModule, FormsModule, HttpClientModule, ToastrModule.forRoot(), BrowserAnimationsModule],
  declarations: [ HeaderMsComponent, FooterMsComponent ],
  exports :[HeaderMsComponent, FooterMsComponent],
  providers: [HttpClientService, AlertService]
//  ,bootstrap:    [ CustomerAddressComponent, CustomerDetailComponent]
})
export class HeaderFooterMsModule { }