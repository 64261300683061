import { Injectable }     from '@angular/core';
import { GoogleTagManagerService }     from '../GoogleTagManagerService';

declare var $ : any;
declare var dataLayer : any;

@Injectable()
export class GoogleTagManagerServiceImpl implements GoogleTagManagerService {
    constructor() { }
  
    public buildProductDataForGTMPush(id : string, 
    								name : string,
    								brand? : string,
                                    category? : string, 
                                    variant? : string,
                                    price? : string,
                                    quantity? : number,
                                    coupon? : string,
                                    position? : number) : any {
      try {
        let productData : any = {};
        productData['id'] = id;
        productData['name'] = name;

        if (brand != undefined && brand != null) {
          productData['brand'] = brand;
        }
        if (category != undefined && category != null) {
          productData['category'] = category;
        }
        if (variant != undefined && variant != null) {
          productData['variant'] = variant;
        }
        if (price != undefined && price != null) {
          productData['price'] = price;
        }
        if (quantity != undefined && quantity != null) {
          productData['quantity'] = quantity;
        }
        if (coupon != undefined && coupon != null) {
          productData['coupon'] = coupon;
        }
        if (position != undefined && position != null) {
          productData['position'] = position;
        }
      return productData;
      } catch (e) {
        console.log(e);
      }
    }

    public buildImpressionDataForGTMPush(id : string, name : string, list? : string, brand? : string,
                                        category? : string, variant? : string,
                                        position? : number, price? : string) : any {
      try {
        let impressionData : any = {}; 
        impressionData['id'] = id;
        impressionData['name'] = name;

        if (list != undefined && list != null) {
          impressionData['list'] = list;
        }
        if (brand != undefined && brand != null) {
          impressionData['brand'] = brand;
        }
        if (category != undefined && category != null) {
          impressionData['category'] = category;
        }
        if (variant != undefined && variant != null) {
          impressionData['variant'] = variant;
        }
        if (position != undefined && position != null) {
          impressionData['position'] = position;
        }
        if (price != undefined && price != null) {
          impressionData['price'] = price;
        }
        return impressionData;
        } catch (e) {
          console.log(e);
        }
    }
      
    public buildActionDataForGTMPush (id : string, affiliation? : string, revenue? : string, tax? : string,
                             shipping? : string, coupon? : string, list? : string, step? : number, option? : string) : any {
          try {
            let actionData : any = {}; 
       
           actionData['id'] = id;
          
           if (affiliation != undefined && affiliation != null) {
               actionData['affiliation'] = affiliation;
           }
            
           if (revenue != undefined && revenue != null) {
               actionData['revenue'] = revenue;
           }
           
           if (tax != undefined && tax != null) {
               actionData['tax'] = tax;
           }
            
           if (shipping != undefined && shipping != null) {
               actionData['shipping'] = shipping;
           }
            
           if (coupon != undefined && coupon != null) {
               actionData['coupon'] = coupon;
           }
            
           if (list != undefined && list != null) {
               actionData['list'] = list;
           }
            
           if (step != undefined && step != null) {
               actionData['step'] = step;
           }
            
           if (option != undefined && option != null) {
               actionData['option'] = option;
           }
           
           return actionData;
         } catch (e) {
            console.log(e);
         }
    }  
    
    // Push product impressions to Google Analytics
    // https://developers.google.com/tag-manager/enhanced-ecommerce#promo-impressions
    public pushImpressionProductEventToGoogleAnalytics(productData : Array<any>) : void {
      try {
          dataLayer.push({
             'event':'productImpressions',
             'ecommerce' : {
               'impressions' : productData
             },
         }); 
      } catch (e) {
        console.log(e);
      }
    }

    // Send product click data to Google Analytics
    // https://developers.google.com/tag-manager/enhanced-ecommerce#product-clicks
    public pushClickProductEventToGoogleAnalytics(productData : Array<any>, list? : string) : void {
      try {
         dataLayer.push({
          'event': 'productClick',
          'ecommerce': {
              'click' : {
                'actionField': {'list': list}, // TODO: Pass in Dynamic Value
                'products' : productData
              }
            }
        });
      } catch (e) {
        console.log(e);
      }
    }

    // Push product details views to Google Analytics
    // https://developers.google.com/tag-manager/enhanced-ecommerce#details
    public pushDetailImpressionProductEventToGoogleAnalytics(productData : Array<any>) : void {
      try {
         dataLayer.push({
            'event':'productDetailImpression',
            'ecommerce' : {
              'detail' : {
                // 'actionField': {'list': 'TODO: Pass in Dynamic Value from the product list clicked on'},
                'products' : productData
              }
            }
        });
      } catch (e) {
        console.log(e);
      }
    }

    // https://developers.google.com/tag-manager/enhanced-ecommerce#add
    public pushAddProductEventToGoogleAnalytics(productData : Array<any>) : void {
      try {
         dataLayer.push({
            'event': 'addToCart', // startCheckoutEvent
            'ecommerce' : {
              'add' : {
                'products' : productData
              }
            }
        });
      } catch (e) {
        console.log(e);
      }
    }

    // https://developers.google.com/tag-manager/enhanced-ecommerce#add
    public pushRemoveProductEventToGoogleAnalytics(productData : Array<any>) : void {
      try {
         dataLayer.push({
            'event': 'removeFromCart',
            'ecommerce' : {
              'remove' : {
                'products' : productData
              }
            }
        });
      } catch (e) {
        console.log(e);
      }
    }

    // Measure checkout steps
    // https://developers.google.com/tag-manager/enhanced-ecommerce#checkout
    public pushEnhancedECommerceEventToGTM(productData : Array<any>, eventName : string, step : number) : void {
      try {
        if (productData != undefined && productData != null) {
          dataLayer.push({
            'event': eventName,
            'ecommerce': {
              'checkout': {
                'actionField': {'step': step},
                'products' : productData
              }
            }
          });
        }    
      } catch (e) {
        console.log(e);
      }
    }

    public pushPurchaseProductCompletedEventToGoogleAnalytics(actionData : any, productData? : Array<any>) : void {
      try {
        if (productData != undefined && productData != null) {
          dataLayer.push({
            'event':'Payment Successful',
            'ecommerce' : {
              'purchase' : {
                'actionField' : actionData,
                'products' : productData
              }
            }
          });
        } else {
          dataLayer.push({
            'event':'Payment Successful',
            'ecommerce' : {
              'purchase' : {
                'actionField' : actionData
              }
            }
          });
        }
      } catch (e) {
         console.log(e);
      }
    }
  
    public pushVirtualPageEventToGTM(event : string, virtualPageURL : string, virtualPageTitle : string) : void {
      try {
        if (event != undefined && event != null && event != ""
            && virtualPageURL != undefined && virtualPageURL != null && virtualPageURL != ""
            && virtualPageTitle != undefined && virtualPageTitle != null && virtualPageTitle != "") {
            dataLayer.push({
              'event': event,
              'virtualPageURL':virtualPageURL,
              'virtualPageTitle' : virtualPageTitle
            });
        }
      } catch (e) {
        console.log(e); 
      }
    }

    // Replaced with new functions
    // public pushCheckoutProductCommitShippingEventToGoogleAnalytics(productData? : Array<any>) : void {
    //     try {
    //          if (productData != undefined && productData != null) {
    //               dataLayer.push({
    //               'event': 'commitShippingEvent2',
    //               'ecommerce': {
    //                 'checkout': {
    //                   'actionField': {'step': 2},
    //                   'products' : productData
    //                }
    //              }
    //             });
    //          } else {
    //             dataLayer.push({
    //               'event': 'commitShippingEvent2',
    //               'ecommerce': {
    //                 'checkout': {
    //                   'actionField': {'step': 2}
    //                }
    //              }
    //             });
    //          }
    //     } catch (e) {
    //       console.log(e);
    //     }
    // }

    // public pushCheckoutProductStep2PaymentImpressionEventToGoogleAnalytics(productData? : Array<any>) : void {
    //     try {
    //          if (productData != undefined && productData != null) {
    //               dataLayer.push({
    //               'event': 'startPaymentImpressionEvent2',
    //               'ecommerce': {
    //                 'checkout': {
    //                   'actionField': {'step': 2},
    //                   'products' : productData
    //                }
    //              }
    //             });
    //          } else {
    //             dataLayer.push({
    //               'event': 'startPaymentImpressionEvent2',
    //               'ecommerce': {
    //                 'checkout': {
    //                   'actionField': {'step': 2}
    //                }
    //              }
    //             });
    //          }
    //     } catch (e) {
    //       console.log(e);
    //     }
    // }

    // public pushCheckoutProductStep3PaymentEventToGoogleAnalytics(productData? : Array<any>) : void {
    //     try {
    //          if (productData != undefined && productData != null) {
    //               dataLayer.push({
    //               'event': 'startPaymentEvent2',
    //               'ecommerce': {
    //                 'checkout': {
    //                   'actionField': {'step': 3},
    //                   'products' : productData
    //                }
    //              }
    //             });
    //          } else {
    //             dataLayer.push({
    //               'event': 'startPaymentEvent2',
    //               'ecommerce': {
    //                 'checkout': {
    //                   'actionField': {'step': 3}
    //                }
    //              }
    //             });
    //          }
    //     } catch (e) {
    //       console.log(e);
    //     }
    // }
      
    // public pushCheckoutProductStep4PaymentCompletedEventToGoogleAnalytics(productData? : Array<any>) : void {
    //    try {
    //          if (productData != undefined && productData != null) {
    //               dataLayer.push({
    //               'event': 'completedPayment2Event',
    //               'ecommerce': {
    //                 'checkout': {
    //                   'actionField': {'step': 3},
    //                   'products' : productData
    //                }
    //              }
    //             });
    //          } else {
    //             dataLayer.push({
    //               'event': 'completedPayment2Event',
    //               'ecommerce': {
    //                 'checkout': {
    //                   'actionField': {'step': 3}
    //                }
    //              }
    //             });
    //          }
    //     } catch (e) {
    //       console.log(e);
    //     }
    // }
  
 }