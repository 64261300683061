import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductAltColourPickerComponent} from './product-alt-colour-picker.component';

@NgModule({
  declarations: [ProductAltColourPickerComponent],
  imports: [
    CommonModule
  ],
  exports: [ProductAltColourPickerComponent],
})
export class ProductAltColourPickerModule { }
