import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientService } from '../../shared/service/impl/http-client.service';

import { HeaderCheckoutComponent } from './header-checkout.component';
import { FooterCheckoutComponent } from './footer-checkout.component';
import { AlertService } from "../../components/alert/services/alert.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";

@NgModule({
  imports:      [ BrowserModule, FormsModule, HttpClientModule, ToastrModule.forRoot(), BrowserAnimationsModule],
  declarations: [ HeaderCheckoutComponent, FooterCheckoutComponent ],
  exports :[HeaderCheckoutComponent, HeaderCheckoutComponent],
  providers: [HttpClientService, AlertService]
  //,bootstrap: [ HeaderComponent, FooterComponent]
})
export class HeaderFooterCheckoutModule { }
