import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {SafeHTMLPipe} from '../pipe/SafeHTMLPipe';
import {SvgReSizePipe} from '../pipe/SvgReSizePipe';
import {ResizeFontSvg} from '../pipe/ResizeFontSvgPipe';
import {SvgRemoveDummyImage} from '../pipe/SvgRemoveDummyImage';

@NgModule({
  //imports:      [ BrowserModule, FormsModule, HttpClientModule],
  declarations: [SafeHTMLPipe, SvgReSizePipe, ResizeFontSvg, SvgRemoveDummyImage],
  exports :[SafeHTMLPipe, SvgReSizePipe, ResizeFontSvg, SvgRemoveDummyImage],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SafeHtmlPipeModule { }