import { CommonModule } from "@angular/common";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { ProductSizeGuideComponent } from "./product-size-guide.component";

@NgModule({
    imports: [CommonModule],
    declarations: [ProductSizeGuideComponent],
    exports: [CommonModule, ProductSizeGuideComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ProductSizeGuideBootstrapModule {}
