// Core Modules
import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {HttpClientModule} from "@angular/common/http";
import {LazyLoadImageModule} from "ng-lazyload-image";
import {AuthService, AuthServiceConfig} from "angularx-social-login";
// Custom Modules
import {SafeHtmlPipeModule} from "../shared/common/safehtml-pipe.module";
import {ProductSizeGuideBootstrapModule} from "./product-size-guide.bootstrap.module";
import {CustomerLoginModalModule} from "../customer/customer-login-modal.bootstrap.module";
import {socialAuthServiceConfig} from "../customer/customer-login.bootstrap.module";
import {BsAccordionModule} from "../components/bs-accordion/bs-accordion.module";
import {ColourSelectModule} from "../components/colour-select/colour-select.module";
// import { VirtualScrollerModule } from "ngx-virtual-scroller";
// Services
import {ProductListServiceImpl} from "./product-list.service-impl";
import {ProductItemServiceImpl} from "../product-item/services/product-item.service";
import {SysCountryServiceImpl} from "../shared/service/impl/SysCountryServiceImpl";
import {HttpClientService} from "../shared/service/impl/http-client.service";
import {GoogleTagManagerServiceImpl} from "../shared/service/impl/GoogleTagManagerServiceImpl";
import {KitDesignLiteServiceImpl} from "../core/service/impl/KitDesignLiteServiceImpl";
// Components
// import { ProductListComponent } from "./product-list.component";
// import { ProductItemComponent } from "./product-item.component";
// import { ProductItemPriceComponent } from "./product-item-price.component";
// import { ProductItemTagComponent } from "./product-item-tag.component";
// import { ProductItemPersonaliseComponent } from "./product-item-personalise.component";
// import { ProductItemLogosComponent } from "./product-item-logos.component";
// import { ProductItemImageComponent } from "./product-item-image.component";
// import { ProductItemImageSvgComponent } from "./product-item-image-svg.component";
import {ProductDetailComponent} from "./product-detail.component";
import {ProductBundleComponent} from "./product-bundle.component";
import {VoucherListComponent} from "./voucher-list.component";
import {ShopListComponent} from "./shop-list.component";
// import { ProductSizeGuide } from "./product-size-guide-component";
import {ProductSizeGuideOther} from "./product-size-guide-other.component";
import {ProductSizeGuideTurbo} from "./product-size-guide-turbo.component";
import {LandingPageComponent} from "./landing-page.component";
import {LandingPageTemplateAComponent} from "./landing-page-templates/landing-page-template-a.component";
import {LandingPageTemplateBComponent} from "./landing-page-templates/landing-page-template-b.component";
import {LandingPageTemplateCComponent} from "./landing-page-templates/landing-page-template-c.component";
import {WelcomeComponent} from "./welcome.component";
import {AutoCompleteComponent} from "../autocomplete/auto-complete.component";
import {PageNotFoundComponent} from "./page-not-found.component";
import {ColourPickerDropdownComponent} from "./colour-picker-dropdown.component";

import {LoaderModule} from "../loader/loader.module";
import {ProductListComponent} from "./product-list.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AlertService} from "../components/alert/services/alert.service";
import {ToastrModule} from "ngx-toastr";
import {drawLogosDirective} from './draw-logos.directive';
import {ProductAltColourPickerModule} from "../components/product-alt-colour-picker/product-alt-colour-picker.module";
// import { ProductItemModule } from "./product-item.module";

// import { BsAccordionComponent } from "../components/bs-accordion/bs-accordion.component";
// import { ColourSelectComponent } from "../components/colour-select/colour-select.component";

//import { CategoryLandingComponent }  from './category-landing.component';

@NgModule({
    imports: [
        LoaderModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        ToastrModule.forRoot(),
        BrowserAnimationsModule,
        SafeHtmlPipeModule,
        LazyLoadImageModule,
        MatAutocompleteModule,
        ProductSizeGuideBootstrapModule,
        CustomerLoginModalModule,
        // VirtualScrollerModule,
        // ProductItemModule,
        BsAccordionModule,
        ColourSelectModule,
        ProductAltColourPickerModule,
    ],
    declarations: [
        ProductListComponent,
        // ProductItemContainerComponent,
        ProductDetailComponent,
        ShopListComponent,
        ProductBundleComponent,
        WelcomeComponent,
        PageNotFoundComponent,
        // ProductSizeGuide,
        ProductSizeGuideOther,
        ProductSizeGuideTurbo,
        VoucherListComponent,
        LandingPageComponent,
        LandingPageTemplateAComponent,
        LandingPageTemplateBComponent,
        LandingPageTemplateCComponent,
        AutoCompleteComponent,
        ColourPickerDropdownComponent,
        // BsAccordionComponent,
        // ColourSelectComponent,
        // ProductItemComponent,
        // ProductItemPriceComponent,
        // ProductItemTagComponent,
        // ProductItemPersonaliseComponent,
        // ProductItemLogosComponent,
        // ProductItemImageComponent,
        // ProductItemImageSvgComponent,
        drawLogosDirective
    ],
    exports: [
        LoaderModule,
        SafeHtmlPipeModule,
        ProductListComponent,
        // ProductItemComponent,
        // ProductItemContainerComponent,
        ProductDetailComponent,
        ShopListComponent,
        ProductBundleComponent,
        WelcomeComponent,
        PageNotFoundComponent,
        VoucherListComponent,
        LandingPageComponent,
        ProductSizeGuideBootstrapModule,
        CustomerLoginModalModule,
        ColourPickerDropdownComponent,
        ProductAltColourPickerModule,
    ],
    providers: [
        ProductListServiceImpl,
        ProductItemServiceImpl,
        SysCountryServiceImpl,
        HttpClientService,
        AlertService,
        GoogleTagManagerServiceImpl,
        KitDesignLiteServiceImpl,
        AuthService,
        {
            provide: AuthServiceConfig,
            useFactory: socialAuthServiceConfig,
        },
    ],
    //bootstrap:    [ CustomerAddressComponent, CustomerDetailComponent]
})
export class ProductModule { }
