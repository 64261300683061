<p>
    shopadmin-product-variation-edit works!
</p>

<!-- TODO: Add go back -->
<button class="btn btn-secondary" (click)="goBack()">Cancel</button>


<!-- TODO: Next add the tabs, if that is even the way we want to go, for now it may be best to simply get all the content on the page and refactor-->



<!-- FIXME: CUSTOMISATIONS TAB -->

<h1>{{ shopProductVariation.description }}</h1>

<!-- TODO: shopProductAttributes is missing, Need to add in the database? or pass in logos from back end? -->
<h2>Logos</h2>






<!-- FIXME: ADDITIONAL DETAILS TAB -->

<!-- shopProductVariation.name -->
<label for="What would you like to call this product in your store"></label>
<input type="text" maxlength="100" size="50">

<!-- 
<div class="row mb-3">
    <div class="form-group col-6">
        <s:label name="prodName" value="What would you like to call this product in your store" />
        <s:textfield name="shopProductVariation.name"  value="%{shopProductVariation.name}" class="form-control" />
    </div>
</div> -->

<label for="">What additional description details would you like to display?</label>
<textarea name="" id="" cols="30" rows="10"></textarea>

<!-- <div class="row mb-3">
  <div class="form-group col-6">
      <s:label name="prodDesc" value="What additional description details would you like to display?" />
      <s:textarea rows="4" maxlength="200" name="shopProductVariation.description" value="%{shopProductVariation.description}" class="form-control" />
  </div>
</div> -->



<!-- FIXME: PRODUCT CUSTOM IMAGES TAB -->

<!-- TODO: Need to check where the shop product variation images is coming from -->
<!-- <s:form id="customiseWithKitdesigner" action="customiseWithKitdesigner" theme="css_xhtml">
    <input type="hidden" name="${_csrf.parameterName}" value="${_csrf.token}" />
    <h1>Shop Product Variation Images </h1>

    <s:if test="%{shopProductVariation.imageFront!=null}">
        <p> Front Image :</p> <img src="${shopProductVariation.filePath}${shopProductVariation.imageFront}" width="100" height="100" />
    </s:if>

    <s:if test="%{shopProductVariation.imageBack!=null}">
        <img src="${shopProductVariation.filePath}${shopProductVariation.imageBack}" width="100" height="100" />
    </s:if>

    <s:if test="%{shopProductVariation.imageLeft!=null}">
        <img src="${shopProductVariation.filePath}${shopProductVariation.imageLeft}" width="100" height="100" />
    </s:if>
    <s:if test="%{shopProductVariation.imageRight!=null}">
        <img src="${shopProductVariation.filePath}${shopProductVariation.imageRight}" width="100" height="100" />
    </s:if>

    <div class="">
        <s:if test="%{designTemplateId!=null}">
            <a class="button" href="http://${handShakeUrl}&fromShopProduct=true&shopProductVariationId=${shopProductVariation.id}&designTemplateId=${designTemplateId}" />Customise this Product With KitDesigner</a>
        </s:if>
    </div>
</s:form> -->









<!--
<ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="productCustomisations-tab" data-toggle="tab" href="#productCustomisations" role="tab" aria-controls="productCustomisations" aria-selected="true">Product Customisations</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="productAdditionalDetails-tab" data-toggle="tab" href="#productAdditionalDetails" role="tab" aria-controls="productAdditionalDetails" aria-selected="false">Product Additional Details</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="productCustomImages-tab" data-toggle="tab" href="#productCustomImages" role="tab" aria-controls="productCustomImages" aria-selected="false">Product Custom Images</a>
                        </li>
                    </ul>
                    <div class="tab-content mt-4 mb-5" id="myTabContent">

                        <div class="tab-pane fade show active" id="productCustomisations" role="tabpanel" aria-labelledby="productCustomisations-tab">

                            <s:form id="saveShopProductVariation" action="saveShopProductVariation" theme="simple">

                                <input type="hidden" name="${_csrf.parameterName}" value="${_csrf.token}" />
                                <s:hidden name="shopProductVariation.id" value="%{shopProductVariation.id}" />
                                <s:hidden name="prodId" value="%{shopProductVariation.product.id}" />
                                <s:hidden name="prodGroupId" value="%{#parameters['prodGroupId']}" />
                                <s:hidden id="currencySymbol" name="currencySymbol" value="%{site.getCurrencySymbol()}" />
                                <s:if test="shopProductVariation.product.stockItems.size()>0">
                                    <s:hidden id="baseTotal" name="baseTotal" value="%{shopProductVariation.product.stockItems.toArray()[0].variationChargeInclTax}" />
                                </s:if>

                                <s:if test="%{extendedUser == null}">
                                    <s:set var="kukriAdmin" value="false" />
                                </s:if>
                                <s:elseif test="%{extendedUser.hasRole('ROLE_KUKRIADMIN') == true}">
                                    <s:set var="kukriAdmin" value="true" />
                                </s:elseif>
                                <s:else>
                                    <s:set var="kukriAdmin" value="false" />
                                </s:else>

                                <p><strong>
                                <s:property value="#product.description" /></strong> - Switch Product Attributes on / off and configure accordingly
                                </p>

                                <s:if test="#product.productAttributes.size()>0">
                                    <s:if test="shopProductVariation.shopProductAttributes.size()>0">


                                        <div id="accordion">



                                            <div class="card">
                                                <div class="card-header" id="headingOne">
                                                    <h5 class="mb-0">
                                                        <a data-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne" class="font--light d-block">
                                                    LOGO
                                                    <span class="icon-keyboard_arrow_down" style="top: 6px; font-size: 32px; margin-right: 12px;"></span>
                                                </a>
                                                    </h5>
                                                </div>
                                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                                    <div class="card-body">
                                                        <s:iterator value="shopProductVariation.shopProductAttributeLogoList" var="attribute" status="rowStatus">

                                                            <div class="row">

                                                                <div class="form-group col-4">
                                                                    <s:select cssClass="form-control" name="%{#attribute.positionSequence!=null ? #attribute.productAttribute.baseAttribute.code + #attribute.positionSequence : #attribute.productAttribute.baseAttribute.code + 1 }" value="%{#attribute.shopLogo.id}" list="shopLogos"
                                                                        listKey="id" listValue="%{getIsActive() ? logo.description + ' : '+ logo.qxLogoId + ' (Active)' : logo.description + ' : '+ logo.qxLogoId + ' (Not Active)'}" headerKey="None" headerValue="None"
                                                                        label="%{#attribute.productAttribute.baseAttribute.description + ' - ' + site.getCurrencySymbol() + (#attribute.productAttribute.baseAttribute.getChargeIncTax(site) )}" theme="xhtml"
                                                                    />
                                                                </div>

                                                                <s:if test="#kukriAdmin == true">
                                                                    <div class="form-group col-2">
                                                                        <label>Position Sequence</label>
                                                                        <s:select cssClass="form-control" name="%{#attribute.positionSequence!=null ? #attribute.productAttribute.baseAttribute.code + #attribute.positionSequence + 'attributePositionSequence' : #attribute.productAttribute.baseAttribute.code + 1 + 'attributePositionSequence'}"
                                                                            value="%{#attribute.positionSequence}" list="#{'1':'1','2':'2', '3':'3'}" listKey="key" listValue="value" headerKey="" headerValue="" />
                                                                    </div>

                                                                    <div class="form-group col-1">
                                                                        <div class="form-check form-check-inline">
                                                                            <s:checkbox cssClass="form-check-input custom-checkbox" name="%{#attribute.positionSequence!=null ? #attribute.productAttribute.baseAttribute.code + #attribute.positionSequence +'_chargeableFlag' : #attribute.productAttribute.baseAttribute.code + 1 +'_chargeableFlag' }"
                                                                                value="#attribute.chargeableFlag" label="Charge?" labelposition="left" theme="xhtml" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="form-group col-1">
                                                                        <div class="form-check form-check-inline">
                                                                            <s:checkbox cssClass="form-check-input custom-checkbox" name="%{#attribute.positionSequence!=null ? #attribute.productAttribute.baseAttribute.code + #attribute.positionSequence +'_dynamicLogoFlag' : #attribute.productAttribute.baseAttribute.code + 1 +'_dynamicLogoFlag' }"
                                                                                value="#attribute.dynamicLogoFlag" label="Dynamic?" labelposition="left" theme="xhtml" />
                                                                        </div>
                                                                    </div>
                                                                    <s:if test="#attribute.id !=null">
                                                                        <div class="form-group col-1">
                                                                            <div class="form-check form-check-inline">
                                                                                <s:checkbox name="%{#attribute.id + '_clone'}" value="" label="Clone?" labelposition="left" theme="xhtml" cssClass="form-check-input custom-checkbox" />
                                                                            </div>
                                                                        </div>
                                                                    </s:if>
                                                                </s:if>


                                                            </div>

                                                        </s:iterator>
                                                    </div>
                                                </div>
                                            </div>



                                            <div class="card">
                                                <div class="card-header" id="headingTwo">
                                                    <h5 class="mb-0">
                                                        <a class="collapsed font--light d-block" data-toggle="collapse" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    NAMES AND NUMBERS
                                                    <span class="icon-keyboard_arrow_down" style="top: 6px; font-size: 32px; margin-right: 12px;"></span>
                                                </a>
                                                    </h5>
                                                </div>
                                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                                    <div class="card-body">
                                                        <s:iterator value="shopProductVariation.shopProductAttributeFlagList" var="attribute" status="rowStatus">
                                                            <s:if test="%{#rowStatus.index == 0}">
                                                                <sj:div cssClass="form-row">
                                                                    <sj:div cssClass="col-3">
                                                                        <strong>
                                                                    <s:label name="colourHeadSpace" value="Position" theme="simple"></s:label>
                                                                </strong>
                                                                    </sj:div>
                                                                    <sj:div cssClass="col-2">
                                                                        <strong>
                                                                    <s:label name="colourHead" value="Colour" theme="simple"></s:label>
                                                                </strong>
                                                                    </sj:div>
                                                                    <sj:div cssClass="col-1">
                                                                        <strong>
                                                                    <s:label name="textCaseDisplayHead" value="Text Case" theme="simple"></s:label>
                                                                </strong>
                                                                    </sj:div>
                                                                    <%-- <sj:div cssClass="col-2">
                                                                        <s:label name="nameTypeHeadSpace" value=" " theme="simple"></s:label>
                                                                    </sj:div> --%>
                                                                        <sj:div cssClass="col-2">
                                                                            <strong>
                                                                    <s:label name="nameTypeHead" value="Name Type" theme="simple"></s:label>
                                                                </strong>
                                                                        </sj:div>
                                                                        <%-- <sj:div cssClass="col-2">
                                                                        <s:label name="nameTypeDisplayHeadSpace" value=" " theme="simple"></s:label>
                                                                    </sj:div> --%>
                                                                            <sj:div cssClass="col-2">
                                                                                <strong>
                                                                    <s:label name="nameTypeDisplayHead" value="Display" theme="simple"></s:label>
                                                                </strong>
                                                                            </sj:div>
                                                                            <s:if test="#kukriAdmin == true">
                                                                                <sj:div cssClass="col-1">
                                                                                    <strong>
                                                                        <s:label name="namePositionSequenceHead" value="Sequence" theme="simple"></s:label>
                                                                    </strong>
                                                                                </sj:div>
                                                                                <sj:div cssClass="col-1">
                                                                                    <strong>
                                                                        <s:label name="namePositionCloneHead" value="Clone" theme="simple"></s:label>
                                                                    </strong>
                                                                                </sj:div>
                                                                            </s:if>
                                                                </sj:div>
                                                            </s:if>
                                                            <sj:div cssClass="form-row">
                                                                <sj:div cssClass="form-group col-3">
                                                                    <div class="form-check form-check-inline">
                                                                        <s:checkbox cssClass="form-check-input custom-checkbox__left" name="%{#attribute.positionSequence!=null ? #attribute.productAttribute.baseAttribute.code + #attribute.positionSequence : #attribute.productAttribute.baseAttribute.code + 1 }" value="#attribute.allowedFlag"
                                                                            label="%{#attribute.productAttribute.baseAttribute.description + ' - ' + site.getCurrencySymbol()+ (#attribute.productAttribute.baseAttribute.getChargeIncTax(site) )}" labelposition="right"
                                                                            theme="xhtml" />
                                                                    </div>
                                                                </sj:div>
                                                                <sj:div cssClass="form-group col-2">
                                                                    <s:select cssClass="form-control" name="%{#attribute.positionSequence!=null ? #attribute.productAttribute.baseAttribute.code + #attribute.positionSequence + 'attributeTextColour' : #attribute.productAttribute.baseAttribute.code + 1 + 'attributeTextColour'}"
                                                                        value="%{#attribute.attributeTextColour}" list="kukriColoursList" listKey="simpleColour" listValue="simpleColour" theme="xhtml" />
                                                                </sj:div>
                                                                <sj:div cssClass="form-group col-1">
                                                                    <s:select cssClass="form-control" name="%{#attribute.positionSequence!=null ? #attribute.productAttribute.baseAttribute.code + #attribute.positionSequence + 'attributeTextCase' : #attribute.productAttribute.baseAttribute.code + 1 + 'attributeTextCase'}"
                                                                        value="%{#attribute.textCase}" label="TextCase" list="#{'Uppercase':'Uppercase','Lowercase':'Lowercase','Mixedcase':'Mixedcase'}" listKey="key" listValue="value" headerKey="" headerValue=""
                                                                    />
                                                                </sj:div>
                                                                <s:if test="%{#attribute.productAttribute.baseAttribute.sysAttributeType.code == 'NAME-CS'}">
                                                                    <sj:div cssClass="form-group col-2">
                                                                        <s:select cssClass="form-control" name="%{#attribute.positionSequence!=null ? #attribute.productAttribute.baseAttribute.code + #attribute.positionSequence + 'attributeNameType' : #attribute.productAttribute.baseAttribute.code + 1 + 'attributeNameType'}"
                                                                            value="%{#attribute.nameType}" list="nameTypes" />
                                                                    </sj:div>
                                                                    <sj:div cssClass="form-group col-2">
                                                                        <s:select cssClass="form-control" name="%{#attribute.positionSequence!=null ? #attribute.productAttribute.baseAttribute.code + #attribute.positionSequence + 'attributeNameTypeDisplay' : #attribute.productAttribute.baseAttribute.code + 1 + 'attributeNameTypeDisplay'}"
                                                                            value="%{#attribute.nameTypeDisplay}" list="nameTypeDisplayList" />
                                                                    </sj:div>
                                                                </s:if>
                                                                <s:if test="%{#attribute.productAttribute.baseAttribute.sysAttributeType.code == 'NUMBER-CS'}">
                                                                    <sj:div cssClass="form-group col-2">
                                                                        <s:textfield cssClass="form-control" name="%{#attribute.positionSequence!=null ? #attribute.productAttribute.baseAttribute.code + #attribute.positionSequence + 'attributeNameType' : #attribute.productAttribute.baseAttribute.code + 1 + 'attributeNameType'}"
                                                                            value="Number" disabled="true" />
                                                                    </sj:div>
                                                                    <sj:div cssClass="form-group col-2">
                                                                        <s:textfield cssClass="form-control" name="%{#attribute.positionSequence!=null ? #attribute.productAttribute.baseAttribute.code + #attribute.positionSequence + 'attributeNameTypeDisplay' : #attribute.productAttribute.baseAttribute.code + 1 + 'attributeNameTypeDisplay'}"
                                                                            value="Number" disabled="true" />
                                                                    </sj:div>
                                                                </s:if>
                                                                <s:if test="#kukriAdmin == true">
                                                                    <sj:div cssClass="form-group col-1">
                                                                        <s:select cssClass="form-control" name="%{#attribute.positionSequence!=null ? #attribute.productAttribute.baseAttribute.code + #attribute.positionSequence + 'attributePositionSequence' : #attribute.productAttribute.baseAttribute.code+ 1 + 'attributePositionSequence' }"
                                                                            value="%{#attribute.positionSequence}" label="PositionSequence" list="#{'1':'1','2':'2', '3':'3'}" listKey="key" listValue="value" headerKey="" headerValue="" />
                                                                    </sj:div>

                                                                    <s:if test="#attribute.id !=null">
                                                                        <sj:div cssClass="form-group col-1">
                                                                            <div class="form-check form-check-inline">
                                                                                <s:checkbox cssClass="form-check-input custom-checkbox__clone" name="%{#attribute.id +'_clone'}" value="" label="" labelposition="right" theme="xhtml" />
                                                                            </div>
                                                                        </sj:div>
                                                                    </s:if>

                                                                </s:if>
                                                            </sj:div>
                                                        </s:iterator>
                                                    </div>
                                                </div>
                                            </div>



                                            <div class="card">
                                                <div class="card-header" id="headingThree">
                                                    <h5 class="mb-0">
                                                        <a class="collapsed font--light d-block" data-toggle="collapse" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    TEXT
                                                    <span class="icon-keyboard_arrow_down" style="top: 6px; font-size: 32px; margin-right: 12px;"></span>
                                                </a>
                                                    </h5>
                                                </div>
                                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                                    <div class="card-body">
                                                        <s:iterator value="shopProductVariation.shopProductAttributeTextList" var="attribute" status="rowStatus">
                                                            <s:if test="%{#rowStatus.index == 0}">
                                                                <sj:div cssClass="row">
                                                                    <sj:div cssClass="col-4">
                                                                        <s:label name="colourHeadSpace" value=" " theme="xhtml"></s:label>
                                                                    </sj:div>
                                                                    <sj:div cssClass="col-3">
                                                                        <strong>
                                                                    <s:label name="actualTextHead" value="Actual Text" theme="xhtml"></s:label>
                                                                </strong>
                                                                    </sj:div>
                                                                    <sj:div cssClass="col-3">
                                                                        <strong>
                                                                    <s:label name="colourHead" value="Colour" theme="xhtml"></s:label>
                                                                </strong>
                                                                    </sj:div>
                                                                </sj:div>
                                                            </s:if>
                                                            <sj:div cssClass="row">
                                                                <sj:div cssClass="form-group col-4">
                                                                    <div class="form-check form-check-inline">
                                                                        <s:checkbox cssClass="form-check-input custom-checkbox__left" name="%{#attribute.productAttribute.baseAttribute.code}" value="#attribute.allowedFlag" label="%{#attribute.productAttribute.baseAttribute.description + ' - ' + site.getCurrencySymbol() + (#attribute.productAttribute.baseAttribute.getChargeIncTax(site) )}"
                                                                            labelposition="right" theme="xhtml" />
                                                                    </div>
                                                                </sj:div>
                                                                <sj:div cssClass="form-group col-3">
                                                                    <s:textfield cssClass="form-control" name="%{#attribute.productAttribute.baseAttribute.code +'attributeText'}" value="%{#attribute.attributeText}" size="%{#attribute.attributeTypeLimit}" theme="xhtml" />
                                                                </sj:div>
                                                                <sj:div cssClass="form-group col-3">
                                                                    <s:select cssClass="form-control" name="%{#attribute.productAttribute.baseAttribute.code + 'attributeTextColour'}" value="%{#attribute.attributeTextColour}" list="kukriColoursList" listKey="simpleColour" listValue="simpleColour" theme="xhtml" />
                                                                </sj:div>
                                                            </sj:div>
                                                        </s:iterator>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>




                                        <sj:accordion id="accordionSPV" collapsible="true">

                                            <s:if test="%{shop.shopTypeString.equalsIgnoreCase('U')}">
                                                <sj:accordionItem title="SELECT">
                                                    <s:iterator value="shopProductVariation.shopProductAttributeSelectList" var="attribute" status="rowStatus">
                                                        <sj:div cssClass="shopAdminAccordionCheckBox">
                                                            <s:checkbox cssClass="commerceItem" name="%{#attribute.productAttribute.baseAttribute.code}" value="#attribute.allowedFlag" label="%{#attribute.productAttribute.baseAttribute.description + ' - ' + site.getCurrencySymbol() + (#attribute.productAttribute.baseAttribute.getChargeIncTax(site) )}"
                                                                labelposition="right" theme="xhtml" />
                                                        </sj:div>
                                                        <sj:div cssClass="shopAdminAccordionSelect">
                                                            <s:select cssClass="commerceItem" name="%{#attribute.productAttribute.baseAttribute.code+ 'attributeSelectValue'}" value="%{#attribute.attributeText}" list="shop.shopActiveSelectTypeSet" listKey="selectType" listValue="selectType" headerKey="None" headerValue="None"
                                                                theme="xhtml" />
                                                        </sj:div>
                                                        <sj:div cssClass="shopAdminAccordionSelect">
                                                            <s:select name="%{#attribute.productAttribute.baseAttribute.code + 'attributeTextColour'}" value="%{#attribute.attributeTextColour}" list="kukriColoursList" listKey="simpleColour" listValue="simpleColour" theme="xhtml" />
                                                        </sj:div>
                                                    </s:iterator>
                                                </sj:accordionItem>
                                            </s:if>



                                        </sj:accordion>
                                    </s:if>
                                </s:if>


                                <s:else>
                                    <div>
                                        <p>No attributes have been setup for this product yet, contact Kukri administrators if required.</p>
                                    </div>
                                </s:else>

                                <div class="supplementAmount row mt-4 mb-2">
                                    <s:if test="%{extendedUser.hasRole('ROLE_KUKRIADMIN')}">

                                        <div class="form-group col-4">
                                            <s:textfield cssClass="form-control" name="shopProductVariation.supplementChargeAmount" value="%{shopProductVariation.supplementChargeAmount}" label="%{'Supplement Charge Amount (excl VAT) ' + site.getCurrencySymbol()}" labelposition="left" theme="xhtml"
                                            />
                                        </div>

                                        <sj:div cssClass="form-group col-2">
                                            <div class="form-check form-check-inline">
                                                <s:checkbox cssClass="form-check-input custom-checkbox" label="DynamicNanImageFlag" labelposition="left" requiredposition="left" key="shopProductVariation.dynamicNanImageFlag" value="shopProductVariation.dynamicNanImageFlag" theme="xhtml" />
                                            </div>
                                        </sj:div>

                                        <sj:div cssClass="form-group col-2">
                                            <div class="form-check form-check-inline">
                                                <s:checkbox cssClass="form-check-input custom-checkbox" label="Generate Tcode Flag" labelposition="left" requiredposition="left" key="shopProductVariation.generateTcodeFlag" value="shopProductVariation.generateTcodeFlag" theme="xhtml" />
                                            </div>
                                        </sj:div>

                                        <div class="col-2">
                                            <a href="<s:property value=" %{ 'http://' +shop.site.domainName + '/' + contextPath + '/' + shop.url + '/retrieveProdNg?prodId=' +shopProductVariation.product.id + '&shopProdVariationId='+shopProductVariation.id} "/>" target="_blank"><strong> [Preview Image...] </strong></a>
                                        </div>
                                    </s:if>

                                    <s:else>
                                        <s:textfield disabled="true" cssClass="commerceItem" name="shopProductVariation.supplementChargeAmount" value="%{shopProductVariation.supplementChargeAmount}" label="%{'
                                            Supplement Charge Amount (excl VAT)
                                            ' + site.getCurrencySymbol()}" labelposition="left" theme="xhtml" />
                                        <s:hidden name="shopProductVariation.supplementChargeAmount" value="%{shopProductVariation.supplementChargeAmount}" />
                                    </s:else>

                                </div>



                                <div class="odd">
                                    <div id="pageStatus">
                                        <h1>
                                            Total:
                                            <strong id="totalCharge" class="enhancePrice">
                            <s:if test="shopProductVariation.product.stockItems.size()>0">		
                              <s:property value="%{shopProductVariation.product.stockItems.toArray()[0].variationChargeInclTax}" />
                            </s:if>
                            <s:else>FREE</s:else>
                            </strong>
                                        </h1>
                                    </div>
                                </div>


                                <div class="buttons">
                                    <s:submit value="Cancel" cssClass="btn btn-primary" action="retrieveShopProductVariationList" />
                                    <s:submit value="Refresh" cssClass="btn btn-primary" action="refreshShopProductVariation" />
                                    <s:submit value="Save" cssClass="btn btn-primary" />
                                </div>
                            </s:form>
                        </div>


                        <div class="tab-pane fade" id="productAdditionalDetails" role="tabpanel" aria-labelledby="productAdditionalDetails-tab">

                            <s:set name="product" value="shopProductVariation.product" />
                            <p><strong><s:property value="#product.description"/></strong></p>

                            <s:form id="saveShopProductCustomisation" action="saveShopProductCustomisation" theme="simple">
                                <input type="hidden" name="${_csrf.parameterName}" value="${_csrf.token}" />
                                <s:hidden name="shopProductVariation.id" value="%{shopProductVariation.id}" />
                                <s:hidden name="prodId" value="%{shopProductVariation.product.id}" />
                                <s:hidden name="prodGroupId" value="%{#parameters['prodGroupId']}" />

                                <div class="row mb-3">
                                    <div class="form-group col-6">
                                        <s:label name="prodName" value="What would you like to call this product in your store" />
                                        <s:textfield name="shopProductVariation.name" maxlength="100" size="50" value="%{shopProductVariation.name}" class="form-control" />
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="form-group col-6">
                                        <s:label name="prodDesc" value="What additional description details would you like to display?" />
                                        <s:textarea rows="4" maxlength="200" name="shopProductVariation.description" value="%{shopProductVariation.description}" class="form-control" />
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <s:submit value="Cancel" cssClass="btn btn-primary" action="retrieveShopProductVariationList" />
                                        <s:submit value="Save" cssClass="btn btn-primary" />
                                    </div>
                                </div>

                            </s:form>



                        </div>






                        <div class="tab-pane fade" id="productCustomImages" role="tabpanel" aria-labelledby="productCustomImages-tab">

                            <%@taglib uri="/struts-tags" prefix="s"%>
                                <%@taglib uri="/struts-jquery-tags" prefix="sj"%>

                                    <style>
                                        .button {
                                            display: block;
                                            width: 115px;
                                            height: 65px;
                                            background: #4E9CAF;
                                            padding: 1px;
                                            text-align: center;
                                            border-radius: 5px;
                                            color: white;
                                            font-weight: bold;
                                        }
                                    </style>
                                    <s:form id="customiseWithKitdesigner" action="customiseWithKitdesigner" theme="css_xhtml">
                                        <input type="hidden" name="${_csrf.parameterName}" value="${_csrf.token}" />
                                        <h1>Shop Product Variation Images </h1>

                                        <s:if test="%{shopProductVariation.imageFront!=null}">
                                            <p> Front Image :</p> <img src="${shopProductVariation.filePath}${shopProductVariation.imageFront}" width="100" height="100" />
                                        </s:if>

                                        <s:if test="%{shopProductVariation.imageBack!=null}">
                                            <img src="${shopProductVariation.filePath}${shopProductVariation.imageBack}" width="100" height="100" />
                                        </s:if>

                                        <s:if test="%{shopProductVariation.imageLeft!=null}">
                                            <img src="${shopProductVariation.filePath}${shopProductVariation.imageLeft}" width="100" height="100" />
                                        </s:if>
                                        <s:if test="%{shopProductVariation.imageRight!=null}">
                                            <img src="${shopProductVariation.filePath}${shopProductVariation.imageRight}" width="100" height="100" />
                                        </s:if>

                                        <div class="">
                                            <s:if test="%{designTemplateId!=null}">
                                                <a class="button" href="http://${handShakeUrl}&fromShopProduct=true&shopProductVariationId=${shopProductVariation.id}&designTemplateId=${designTemplateId}" />Customise this Product With KitDesigner</a>
                                            </s:if>

                                        </div>

                                    </s:form>





                        </div>






                    </div>

                </div>
            </div>
        </div>

        <jsp:include page="../includes/footerStruts.jsp"></jsp:include>

      -->