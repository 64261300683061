import {Component, enableProdMode, EventEmitter, Input, OnInit, Output,} from "@angular/core";
import {HttpClientService} from "../../shared/service/impl/http-client.service";
import {Observable} from "rxjs";
import {AuthService} from "angularx-social-login";
import {SocialSignInUser} from "../models/social-signin-user.interface";
import {Customer} from "../models/customer";
import {CustomerLoginServiceImpl} from "../services/customer-login.service-impl";

declare var $: any;

enableProdMode();

@Component({
    selector: "customer-login-modal",
    templateUrl: "./customer-login-modal.component.html",
})
export class CustomerLoginModalComponent implements OnInit {
    @Input() public modalTitle?: string = "Login or Register";

    // Customer Login Details
    @Input() public j_email?: string;
    @Input() public j_username?: string;
    @Input() public j_password?: string;
    @Input() public accountType?: string;
    @Input() public redirectUrl?: string;
    @Input() public customers?: Array<Customer> = new Array<Customer>();
    @Input() public fullUserName?: boolean;
    @Input() public selectedCustomer?: any;
    @Input() public socialSignInUser?: SocialSignInUser;
    @Input() public site: any;
    @Input() public shop: any;
    @Input() public kukriKitDesignProduct: any;
    @Input() public kdFlag: boolean;
    @Input() public productFlag?: boolean;

    @Input() public reloadPageOnSuccess?: boolean = true;

    @Input() public errors: Array<String> = new Array<String>();
    @Input() public registrationErrors: Array<String> = new Array<String>();
    @Input() public infoMessages: Array<String> = new Array<String>();

    // Customer Registeration Detail
    @Input() public customer: Customer;
    @Input() public confPassword?: string;
    @Input() public newsLetterSignUp?: boolean = false;
    @Input() public partnerMarketingFlag?: boolean = false;
    @Input() public countries?: any;

    public newsLetterInterests: Array<any> = [
        { name: "Rugby", checked: false, category: "sport" },
        { name: "Cricket", checked: false, category: "sport" },
        { name: "Hockey", checked: false, category: "sport" },
        { name: "Netball", checked: false, category: "sport" },
        { name: "Football", checked: false, category: "sport" },
        { name: "Athletics", checked: false, category: "sport" },
        { name: "Basketball", checked: false, category: "sport" },
        { name: "Schools", checked: false, category: "businessSector" },
        { name: "University", checked: false, category: "businessSector" },
        { name: "Club/Team", checked: false, category: "businessSector" },
    ];

    // General Information
    @Output() public readonly silentLoginResult? = new EventEmitter<
        Observable<Response>
    >();
    @Output()
    public readonly silentRegistrationAndLoginResult? = new EventEmitter<
        Observable<Response>
    >();
    @Output() public readonly socialMediaSignInResult? = new EventEmitter<
        Promise<void>
    >();

    constructor(
        private httpClientService: HttpClientService,
        private authService: AuthService,
        private customerLoginService: CustomerLoginServiceImpl
    ) {}

    ngOnInit() {
        this.authService.authState.subscribe(
            userData => (this.socialSignInUser = userData)
        );
    }

    public login() {
        let submitData = {
            j_username: this.j_username,
            j_email: this.j_email,
            j_password: this.j_password,
            accountType: this.accountType,
            fullUserName: this.fullUserName ? this.fullUserName : false,
        };
        this.customerLoginService.login(
            submitData,
            this.customers,
            this.errors,
            this.infoMessages
        );
    }

    public silentLogin(isFormValid: boolean) {
        if (isFormValid) {
            let uri: string = "customerSigninConfirmNg.action";
            let submitData = {
                j_username: this.j_username,
                j_email: this.j_email,
                j_password: this.j_password,
                accountType: this.accountType,
                fullUserName: this.fullUserName ? this.fullUserName : false,
                kdFlag: true,
            };

            this.silentLoginResult.emit(
                this.httpClientService.postAjaxForm(uri, submitData)
            );
        }
    }

    public register() {
        const submitRegistrationData = {
            customer: this.customer,
            confPassword: this.confPassword,
            accountType: this.accountType,
            newsLetterSignUp: this.newsLetterSignUp,
            newsLetterInterests: this.newsLetterInterests,
            partnerMarketingFlag: this.partnerMarketingFlag,
        };
        const submitLoginData = this.customerLoginService.register(
            submitRegistrationData,
            this.j_email,
            this.j_password,
            this.infoMessages,
            this.registrationErrors
        );

        this.j_username = this.customer.user.username;
        this.j_password = this.confPassword;
        this.fullUserName = true;

        if (submitLoginData) {
            this.customerLoginService.login(
                submitLoginData,
                this.customers,
                this.infoMessages,
                this.errors
            );
        }
    }

    public silentRegisterationAndSignIn(isFormValid: boolean) {
        if (isFormValid) {
            let submitData = {
                customer: this.customer,
                confPassword: this.confPassword,
                accountType: this.accountType,
                newsLetterSignUp: this.newsLetterSignUp,
                newsLetterInterests: this.newsLetterInterests,
                partnerMarketingFlag: this.partnerMarketingFlag,
            };

            this.silentRegistrationAndLoginResult.emit(
                this.customerLoginService.silentRegisterationAndSignIn(
                    submitData
                )
            );
        }
    }

    public chooseUser(customerArg: any) {
        this.j_username = customerArg.user.username;
        this.fullUserName = true;
        this.selectedCustomer = customerArg;
    }

    public signInWithFb() {
        this.socialMediaSignInResult.emit(
            this.customerLoginService.signInWithFb(
                this.socialSignInUser,
                this.customer,
                this.customers,
                this.kukriKitDesignProduct,
                this.errors,
                this.kdFlag,
                this.productFlag,
                this.reloadPageOnSuccess
            )
        );
    }

    public signInWithGoogle() {
        this.socialMediaSignInResult.emit(
            this.customerLoginService.signInWithGoogle(
                this.socialSignInUser,
                this.customer,
                this.customers,
                this.kukriKitDesignProduct,
                this.errors,
                this.kdFlag,
                this.productFlag,
                this.reloadPageOnSuccess
            )
        );
    }
}
