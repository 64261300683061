export class KukriProduct {
	public id: number;

	// Stock, CustomisedStock,Design, Bundle
	public productType: string;

	public reorderType: string;

	public preferredSupplierCode: string;

	public statusCode: boolean;
	public isFeatured: boolean;

	public sortCode: number;

	public sortOrder: number;

	public shopProductVariationId: number; // Set for CustomisedStock only
	public baseProductId: number; // Set for all Product.id, CustomerDesignProduct.id
	public bundleProductId: number; // shopBundleProduct.id

	// Design Product Fields Only - START
	public code: string;
	public styleCode: string;
	public sleeveType: string;
	public designTemplateId: string;
	public baseTemplateId: number;
	public designName: string;
	// Design Product Fields Only - END

	public description: string;
	public longDescription: string;
	public itemCode: string;
	public itemMasterCode: string;
	public minOrderQuantity: number = 10;
	public perLineMinOrderQuantity: number = 1;
	public vatCode: string;
	public fit: string;

	public imageType: string;
	public imageFront: string;
	public imageBack: string;
	public imageRight: string;
	public imageLeft: string;
	public imageAdditional1: string;
	public imageAdditional2: string;

	public currencySymbol: string;

	public baseUnitChargeInclTax: number;
	public baseUnitChargeExclTax: number;

	public unitChargeInclTax: number;
	public unitChargeExclTax: number;
	public wasUnitChargeExclTax: number;  // only used for Managed shops for display purposes for was/now display
	public wasUnitChargeInclTax: number;  // only used for Managed shops for display purposes for was/now display

	public saleTag: string = null;
	public personalisableTag: string = null;
	public newTag: string = null;
	public selectedTag: string; // only for bundle products

	public estimatedDeliveryDate: string;
	public leadTimeSummary: string;
	public leadTimeDays: number;
	public leadTimeWeeks: number;

	public productGroupDescription: string;
	public productRangeDescription: string;

	public bpaKdDesign: string;
	public bpaProductType: string;  // Garment Type

	public specificationHeader: string ;
	public specificationBody: string;
	public specificationFeatures: Array<string>;
	public specificationIcons: Array<string>;

	public chargeApproved: boolean = false;
	public k2Source: boolean = true;
	public displayEnabledFlag: boolean = false;
	public deprecatedDesignFlag: boolean = false;
	public dynamicLogoExist: boolean = false;
	public selectedProduct: boolean = false;
	public kdLiteFlag: boolean = false;

	public supplierId: number;
	public supplierRouteId: number;

	public show: boolean = false;

	public selected: boolean = false;
	public completedStatus: boolean = false;

	public allowApplicationTypeChangeFlag: boolean  = false;

	public applicationTypeFilter: string = "OFF";

	public dynamicNanImageFlag: boolean = false;

	public showStockProductAlongside: boolean = false;

	public product: any;

	public shopProductAttributeLogoList: Array<any>;
	public shopProductAttributeTextList: Array<any>;
	public shopProductAttributeFlagList: Array<any>;
	public shopProductAttributeSelectList: Array<any>;

	public outofstockLit: string;
	// Add these lists/objects where required
	//
	// public KukriTeam kukriTeam;
	//
	// // For Stock and Customised Stock
	// public List<KukriStock> stockItems;
	//
	// // For Bundle Products
	// public List<KukriProduct> bundleProducts;
	//
	// // Alternative products, same core product but different colour ranges
	// public List<KukriProductColourAlternate> productColourAlternates;
	//
	// public List<KukriProduct> suggestedProducts;
	//
	// public List<KukriBundleTaggedProduct> kukriBundleTaggedProducts;
}