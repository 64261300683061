<div class="modal fade" id="signInModal" tabindex="-1" role="dialog" aria-labelledby="signInModal" aria-hidden="true">
	<div class="modal-dialog modal-md mt-4" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="signInModalLabel">{{ modalTitle }}</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true" class="white">&times;</span>
				</button>
			</div>
			<div class="modal-body px-0 pt-0">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
					<li class="nav-item w-50 text-center font--light">
						<a class="nav-link active" id="login-tab" data-toggle="tab" href="#login" role="tab" aria-controls="login" aria-selected="true">Log in</a>
					</li>
					<li class="nav-item w-50 text-center font--light">
						<a class="nav-link" id="signup-tab" data-toggle="tab" href="#signup" role="tab" aria-controls="signup" aria-selected="false">New to Kukri?</a>
					</li>
				</ul>
				<div class="tab-content pt-3 px-3" id="login-tab-content">
					<div class="tab-pane fade show active" id="login" role="tabpanel" aria-labelledby="login-tab">

						<ng-container *ngIf="errors != undefined && errors != null">
							<div *ngFor="let error of errors">
								<div id="errors" class="alert alert-danger mb-3 text-center" *ngIf="!error.includes('required')">
									<span>
										{{error}}
										<ng-container *ngIf="error.includes('your account has been locked')">
											<br/><br/>
											<a style="color:#00a695" href="forgottenMyPasswordNg.action">Click here</a> to reset your password.
										</ng-container>
									</span>
								</div>
							</div>
						</ng-container>

						<ng-container *ngIf="customers != null && customers.length > 1">
							<select #chosenCustomer style="margin-bottom: 7px;" (change)="chooseUser(customers[chosenCustomer.selectedOptions.item(0).index -1])">
								<option selected="selected" disabled value=null>Choose Preferred Account</option>
								<option *ngFor="let selectCustomer of customers" [value]=selectCustomer>
									{{selectCustomer.user.username.includes('.1') ? 'Company' : 'Personal '}}
									-  {{selectCustomer.user.accountName}}
									{{selectCustomer.user.username.includes('.1') ? ' : (' + selectCustomer.companyName + ')' : '' }}
								</option>
							</select>
						</ng-container>

						<form id="login-form" (ngSubmit)="silentLogin(!formLogin.invalid)" name="Login-form" #formLogin="ngForm" novalidate>
							<input type="hidden" name="accountType" value="CUSTOMER" [(ngModel)]="accountType"/>
							<input type="hidden" name="j_username" [(ngModel)]="j_username"/>
<!--							<div class="form-group" *ngIf="selectedCustomer != null">-->
<!--								<ng-container *ngIf="!selectedCustomer.user.username.endsWith('.1')">-->
<!--									<label for="login-accountname">Selected Account</label>-->
<!--									<input type="text" disabled="true" class="form-control " id="login-accountname" placeholder="Selected Account"-->
<!--									       name="accountname" value="{{selectedCustomer.user.accountName}}">-->
<!--								</ng-container>-->
<!--								<ng-container *ngIf="selectedCustomer.user.username.endsWith('.1')">-->
<!--									<label for="login-accountname">Selected Account</label>-->
<!--									<input type="text" disabled="true" class="form-control " id="login-accountname" placeholder="Selected Account"-->
<!--									       name="accountname" value="{{selectedCustomer.user.accountName}}   :   ({{selectedCustomer.companyName}})">-->
<!--								</ng-container>-->
<!--							</div>-->

							<div class="form-group">
								<label for="login-email" class="font--light">Email address</label>
								<input type="text" class="form-control" id="login-email" placeholder="Email address" name="j_email" required maxlength="50"
								       [class.is-error]="j_emailRef.errors?.required && (j_emailRef.dirty || formLogin.submitted)"
								       [(ngModel)]="j_email" #j_emailRef="ngModel">
								<p *ngIf="j_emailRef.errors?.required && (j_emailRef.dirty || formLogin.submitted)" class="text_colour_error mt-1">
									Email Address is required
								</p>
							</div>
							<div class="form-group">
								<label for="login-password" class="font--light">Password</label>
								<input type="password" class="form-control" id="login-password" placeholder="Password" name="j_password" required maxlength="50"
								       [class.is-error]="j_passwordRef.errors?.required && (j_passwordRef.dirty || formLogin.submitted)"
								       [(ngModel)]="j_password" #j_passwordRef="ngModel">
								<p *ngIf="j_passwordRef.errors?.required && (j_passwordRef.dirty || formLogin.submitted)" class="text_colour_error mt-1">
									Password is required
								</p>
								<small class="form-control--desc"><a href="#">Forgotten your password?</a></small>
							</div>
							<button class="btn btn__social w-100" id="loginBtn">Log in</button>
						</form>
						<div *ngIf="shop.showGoogleSignInFlag || shop.showFacebookSignInFlag" class="text-muted text-center mt-3">
							&mdash; or &mdash;
						</div>
						<div id="login_social" class="mt-3">
							<button *ngIf="shop.showGoogleSignInFlag" id="signInWithGoogleBtn" class="btn btn__social btn__social--google w-100 mb-3" (click)="signInWithGoogle()">Log in with Google</button>
							<button *ngIf="shop.showFacebookSignInFlag" id="signInWithFacebookBtn" class="btn btn__social btn__social--facebook w-100" (click)="signInWithFb()">Log in with Facebook</button>
						</div>
					</div>
					<div class="tab-pane fade" id="signup" role="tabpanel" aria-labelledby="signup-tab">

						<ng-container *ngIf="registrationErrors != undefined && registrationErrors != null">
							<div *ngFor="let error of registrationErrors">
								<div id="registrationErrors" class="alert alert-danger mb-1" *ngIf="error != 'Authentication failed.' && !error.includes('required')">
									<span>{{error}}</span>
								</div>
							</div>
						</ng-container>

						<form id="register-form" name="register-form" (ngSubmit)="silentRegisterationAndSignIn(!formRegister.invalid)" #formRegister="ngForm" novalidate>
							<input type="hidden" name="accountType" value="CUSTOMER" [(ngModel)]="accountType"/>
							<div class="row gutter-sm">
								<div class="col-sm-3">
									<div class="form-group 1">
										<label for="login-email" class="font--light">Title</label>
										<select id="title" class="form-control" name="customer.title" required
										        [class.is-error]="titleRef.errors?.required && (titleRef.dirty || formRegister.submitted)"
										        [(ngModel)]="customer.title" #titleRef="ngModel">
											<option value="Mr">Mr</option>
											<option value="Mrs">Mrs</option>
											<option value="Miss">Miss</option>
											<option value="Ms">Ms</option>
										</select>
										<p *ngIf="titleRef.errors?.required && (titleRef.dirty || formRegister.submitted)" class="text_colour_error mt-1">
											Title is required
										</p>
									</div>
								</div>
								<div class="col-sm-4">
									<div class="form-group 1">
										<label for="login-email" class="font--light">First name</label>
										<input type="text" class="form-control" id="register-fname" name="customer.firstname" placeholder="First name"
											   maxlength="20" required
											   [class.is-error]="firstNameRef.errors?.required && (firstNameRef.dirty || formRegister.submitted)"
										       [(ngModel)]="customer.firstname" #firstNameRef="ngModel">
										<p *ngIf="firstNameRef.errors?.required && (firstNameRef.dirty || formRegister.submitted)" class="text_colour_error mt-1">
											First Name is required
										</p>
									</div>
								</div>
								<div class="col-sm-5">
									<div class="form-group 1">
										<label for="login-email" class="font--light">Last name</label>
										<input type="text" class="form-control" id="register-lname" placeholder="Last name" name="customer.surname"
										       maxlength="20" required
										       [class.is-error]="lastNameRef.errors?.required && (lastNameRef.dirty  || formRegister.submitted)"
										       [(ngModel)]="customer.surname" #lastNameRef="ngModel">
										<p *ngIf="lastNameRef.errors?.required && (lastNameRef.dirty  || formRegister.submitted)" class="text_colour_error mt-1">
											Last Name is required
										</p>
									</div>
								</div>
							</div>
							<div class="form-group 1">
								<label for="login-email" class="font--light">Email address</label>
								<input type="text" class="form-control" id="register-email" placeholder="Email address" name="customer.email"
								       autocomplete="off" required maxlength="50"
								       [class.is-error]="usernameRef.errors?.required && (usernameRef.dirty  || formRegister.submitted)"
								       [(ngModel)]="customer.email" #usernameRef="ngModel">
								<p *ngIf="usernameRef.errors?.required && (usernameRef.dirty  || formRegister.submitted)" class="text_colour_error mt-1">
									Email Address is required
								</p>
							</div>
							<div class="form-group 1">
								<label for="login-password-register" class="font--light">Password</label>
								<input type="password" class="form-control" id="login-password-register" name="customer.password"
								       placeholder="Password (must be greater than 5 characters)" autocomplete="off" required maxlength="50"
								       [class.is-error]="passwordRef.errors?.required && (passwordRef.dirty  || formRegister.submitted)"
								       [(ngModel)]="customer.password" #passwordRef="ngModel">
								<p *ngIf="passwordRef.errors?.required && (passwordRef.dirty  || formRegister.submitted)" class="text_colour_error mt-1">
									Password is required
								</p>
							</div>
							<div class="form-group 1">
								<label for="login-password-confirm" class="font--light">Confirm password</label>
								<input type="password" class="form-control" id="login-password-confirm" placeholder="Confirm password"
								       name="confPassword" autocomplete="off" required maxlength="50"
								       [class.is-error]="confPasswordRef.errors?.required && (confPasswordRef.dirty  || formRegister.submitted)"
								       [(ngModel)]="confPassword" #confPasswordRef="ngModel">
								<p *ngIf="confPasswordRef.errors?.required && (confPasswordRef.dirty  || formRegister.submitted)" class="text_colour_error mt-1">
									Confirm Password is required
								</p>
							</div>
							<ng-container *ngIf="site.newsletterEnabledFlag && site.mailingList != null">
								<div class="form-group 1">
									<input id="sign-up-newsletter" name="newsLetterSignUp" type="checkbox" value="Sign up to our newsletter"
									       [(ngModel)]="newsLetterSignUp">
									<label for="sign-up-newsletter">Sign up to our newsletter</label>
								</div>
								<div class="row">
									<div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
										<ng-container *ngIf="newsLetterSignUp">
											<div class="form-group 1">
												<label class="font-weight-bold">Sports?</label>
												<ng-container *ngFor="let option of newsLetterInterests;let myindex = index;">
													<ng-container *ngIf="option.category == 'sport'">
														<input id="newsLetterSports-{{myindex}}" type="checkbox" name="newsLetterSports-{{myindex}}" value="{{option.name}}"
														       [(ngModel)]="option.checked"/>
														<label for="newsLetterSports-{{myindex}}">{{option.name}}</label>
													</ng-container>
												</ng-container>
											</div> 
										</ng-container>
									</div>
									<div *ngIf="newsLetterSignUp" class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
										<div class="form-group 1">
											<label class="font-weight-bold">Business Sector?</label>
											<ng-container *ngFor="let option of newsLetterInterests;let myindex = index;">
												<ng-container *ngIf="option.category == 'businessSector'">
													<input id="newsLetterBusinessSector-{{myindex}}" type="checkbox" name="newsLetterBusinessSector-{{myindex}}"
													       value="{{option.name}}" [(ngModel)]="option.checked"/>
													<label for="newsLetterBusinessSector-{{myindex}}">{{option.name}}</label>
												</ng-container>
											</ng-container>
										</div>
									</div>
								</div>
							</ng-container>
							<button id="registerBtn" class="btn w-100">Register</button>
						</form>
					</div>
				</div>

			</div>
		</div>
	</div>
</div>