import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BsNavBaseComponent } from "./bs-nav-base.component";

@NgModule({
    declarations: [BsNavBaseComponent],
    imports: [CommonModule],
    exports: [BsNavBaseComponent],
})
export class BsNavBaseModule {}
