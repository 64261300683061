import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { LoaderModule } from "../../loader/loader.module";

import { LoaderComponent } from "../../loader/loader.component";
import { ShopAdminProductVariationEditComponent } from "./shopadmin-product-variation-edit.component";

import { RouterModule } from "@angular/router";
import { AlertService } from "../../components/alert/services/alert.service";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";


@NgModule({
    imports: [LoaderModule, BrowserModule, RouterModule, BrowserAnimationsModule, ToastrModule.forRoot()],
    declarations: [ShopAdminProductVariationEditComponent],
    exports: [ShopAdminProductVariationEditComponent],
    providers: [AlertService],
    bootstrap: [LoaderComponent, ShopAdminProductVariationEditComponent],
})
export class ShopAdminProductVariationEditModule {}
