import { Component, Input } from "@angular/core";

@Component({
    selector: "product-item-image",
    templateUrl: "./product-item-image.component.html",
})
export class ProductItemImageComponent {
    @Input() public productImage: string;
    @Input() public lazyLoad?: boolean = true;

    constructor() {}
}
