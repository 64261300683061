import { NavLink } from "./nav-link";

export class NavLinkList {
	public name: string;
	public icon: string;
	public links: Array<NavLink>;
	public headerLink: NavLink;

	constructor(name: string, icon: string, links?: Array<NavLink>, shopUrl?: string, link?: NavLink) {
		this.name = name;
		this.icon = icon;
		this.links = links != null ? links : new Array<NavLink>();
		this.headerLink = link;

		if(shopUrl) {
			return this.applyShopUrlToAllLinks(shopUrl);
		}
	}

	public applyShopUrlToAllLinks(shopUrl: string): NavLinkList {
		for(let link of this.links) {
			link.shopUrl = shopUrl;
		}
		return this;
	}
}