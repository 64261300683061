<ng-container
    *ngFor="let cell of tableRow.cells | keyvalue: defaultSortOrder; index as i"
>
    <!-- TODO: Also add and optional input to allow use to use 1st column as header for that row -->
    <th *ngIf="i == 0; else data" scope="row">
        <editable
            [id]="'group-list-cell-' + i"
            [editableType]="inputTypes.TEXT"
            [inputValue]="cell.value.content"
            [classes]="'form-control text-center'"
            (submit)="onSubmitEditableValue($event)"
        >
            {{ cell.value.content }}
        </editable>
    </th>
    <ng-template #data>
        <td>
            <editable
                [id]="'group-list-cell-' + i"
                [editableType]="inputTypes.TEXT"
                [inputValue]="cell.value.content"
                [classes]="'form-control text-center'"
                (submit)="onSubmitEditableValue($event)"
            >
                {{ cell.value.content }}
            </editable>
        </td>
    </ng-template>
</ng-container>

<!-- Admin Button Group Inline... -->
<ng-content></ng-content>
