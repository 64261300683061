import {NavLinkList} from "../../shared/nav/nav-link-list";
import {NavLink} from "../../shared/nav/nav-link";
import {BaseNavLinks} from "../../shared/nav/base-nav-links";

export class ShopAdminNavLinks extends BaseNavLinks {
	public static ACCOUNT_CONTACT_INFO = new NavLink("viewContactInfoNg", "Contact Info");
	public static ACCOUNT_ADDRESSES = new NavLink("myAddresses", "Addresses");
	public static ACCOUNT_CUSTOMER_ORDERS = new NavLink("customerOrders", "Orders");
	public static ACCOUNT_CUSTOMER_QUOTES = new NavLink("customerQuotes", "Quotes");
	public static ACCOUNT_CUSTOMER_DESIGNS = new NavLink("customerDesigns", "Designs");
	public static ACCOUNT_MANAGER_SHOPS_LIST_NG = new NavLink("managerShopListNg", "Shops");
	public static ACCOUNT_VIEW_CUSTOMER_KITBAGS = new NavLink("viewCustomerKitbags", "Kit-bags");
	public static ACCOUNT_LOGOS = new NavLink("myLogosNg", "Logos");
	public static ACCOUNT_VIEW_CUSTOMER_TEAMS = new NavLink("viewCustomerTeams", "Teams");
	public static ACCOUNT_VIEW_CUSTOMER_BROCHURES = new NavLink("viewCustomerBrochures", "Brochures", true);

	public static SHOP_EDIT_SHOP_NG = new NavLink("editShopNg", "Edit Shop", true);
	public static SHOP_MY_LOGOS_NG = new NavLink("myLogosNg.action?shopManagerFlag=true", "Product-Logos", true);
	public static SHOP_RETRIEVE_SHOP_PRODUCT_GROUPS = new NavLink("retrieveShopProductGroups.action", "Product Groups", true);
	public static SHOP_RETRIEVE_SHOP_PRODUCT_GROUPS_NG = new NavLink("retrieveShopProductGroupsNg.action", "Product Groups (NEW)", true);
	public static SHOP_RETRIEVE_PRODUCTS = new NavLink("retrieveProducts.action", "Product Customisations", true);
	public static SHOP_RETRIEVE_PRODUCTS_NG = new NavLink("retrieveProductsNg.action", "Product Customisations (NEW)", true);
	public static SHOP_EDIT_HOMEPAGE = new NavLink("editShopHomepage", "Featured Products", true);
	public static SHOP_PROMOTIONS_LIST = new NavLink("shopPromotionsList", "Edit Promotions", true);

	public static SHOP_DASHBOARD = new NavLink("shopManagerDashboard", "Shop Dashboard", true);

	public static SHOPORDER_ORDERS = new NavLink("shopOrders", "Shop Orders");
	public static SHOPORDER_QUOTES = new NavLink("shopQuotes", "Shop Quotes");
	public static SHOPORDER_COMPANY_ORDERS = new NavLink("shopCompanyOrders", "Admin Orders");

	public static SHOPREPORT_GENERATE_SALES_REPORT = new NavLink("generateShopSalesReport", "Sales Report");
	public static SHOPREPORT_GENERATE_CUSTOMERS_REPORT = new NavLink("generateShopCustomersReport", "Customer Report");
	public static SHOPREPORT_GENERATE_PROMOTIONS_REPORT = new NavLink("generateShopPromotionsReport", "Report Promotions");

	public static DEPRECATE_BASE_PRODUCT = new NavLink("updateBaseProductStatusCodeNgAjax", "Deprecate", true);
	public static DEPRECATE_PRODUCT_VARIATION = new NavLink("updateProductVariationStatusCodeNgAjax", "Deprecate", true);
	public static ADD_PRODUCT_VARIATION_TO_FEATURED = new NavLink("addProductVariationToFeaturedNgAjax", "Add to Featured Products", true);
	public static REMOVE_PRODUCT_FROM_FEATURED = new NavLink("removeProductFromFeaturedNgAjax", "Remove From Featured Products", true);
	public static UPDATE_BASE_PRODUCT_SORT_ORDER = new NavLink("updateProductSortOrderAjax", "Update Sort Order", true);
	public static ADD_BASE_PRODUCT_TO_FEATURED = new NavLink("addBaseProductToFeaturedNgAjax", "Add to Featured Products", true);

	public static CREATE_PRODUCT_VARIATION  = new NavLink("addNewShopProductVariationNg", "Add New Variation", true);
	public static VIEW_PRODUCT_VARIATION_LIST_OLD = new NavLink("retrieveShopProductVariationList", "Product Variation List", true);
	public static VIEW_PRODUCT_VARIATION_LIST = new NavLink("retrieveShopProductVariationListNg", "Product Variation List", true);
	public static UPDATE_PRODUCT_VARIATION_SORT_ORDER = new NavLink("updateVariationSortOrderAjax", "Update Sort Order", true);
	public static ADD_PRODUCT_VARIATION_OLD = new NavLink("addNewShopProductVariationNg.action", "Add Product Variation", true);
	public static EDIT_PRODUCT_VARIATION_OLD = new NavLink("editShopProductVariation", "Edit", true);
	public static EDIT_PRODUCT_VARIATION = new NavLink("editShopProductVariationNg", "Edit", true);
	public static DELETE_PRODUCT_VARIATION = new NavLink("deleteShopProductVariationNewNg", "Delete", true);

	public static EDIT_PRODUCT_GROUP = new NavLink("editShopProductGroupNg", "Edit", true);
	public static UPDATE_PRODUCT_GROUP = new NavLink("saveShopProductGroupNgAjax", "Save", true);
	public static ADD_PRODUCT_TO_GROUP = new NavLink("saveOrUpdateAssignedProductsNgAjax", "Add Product", true);
	public static REMOVE_PRODUCT_FROM_GROUP = new NavLink("removeProductFromGroupNgAjax", "Remove Product From Group", true);
	public static DELETE_PRODUCT_GROUP = new NavLink("deleteShopProductGroupNg", "Delete", true);

	public static SEARCH_BASE_PRODUCTS = new NavLink("retrieveUnassignedProductsNgAjax", "Base Products", true);
	public static SEARCH_VARIATION_PRODUCTS = new NavLink("retrieveUnassignedVariationProductsNgAjax", "Product Variations", true);
	public static SEARCH_DESIGN_PRODUCTS = new NavLink("retrieveUnassignedDesignsNgAjax", "Design Products", true);
	public static MODIFY_NEW_TAG_PRODUCT_VARITION = new NavLink("updateProductVariationNewTagNgAjax", "newProductTag", true);
	public static MODIFY_NEW_TAG_STOCK_PRODUCT = new NavLink("updateStockProductNewTagNgAjax", "newProductTag", true);
	public static UPDATE_DESIGN_PRODUCT_SORT_ORDER = new NavLink("updateDesignSortOrderAjax", "Update Sort Order", true);


	public static getAccountsMenuLinks(shopUrl?: string): NavLinkList {
		const links = [
			this.ACCOUNT_CONTACT_INFO,
			this.ACCOUNT_ADDRESSES,
			this.ACCOUNT_CUSTOMER_ORDERS,
			this.ACCOUNT_CUSTOMER_QUOTES,
			this.ACCOUNT_CUSTOMER_DESIGNS,
			this.ACCOUNT_MANAGER_SHOPS_LIST_NG,
			this.ACCOUNT_VIEW_CUSTOMER_KITBAGS,
			this.ACCOUNT_LOGOS,
			this.ACCOUNT_VIEW_CUSTOMER_TEAMS,
			this.ACCOUNT_VIEW_CUSTOMER_BROCHURES
		];
		return new NavLinkList("Account", "icon-person", links, shopUrl);
	}

	public static getAdminShopMenuLinks(): NavLinkList {
		const links = [
			this.SHOP_EDIT_SHOP_NG,
			this.SHOP_MY_LOGOS_NG,
			this.SHOP_RETRIEVE_SHOP_PRODUCT_GROUPS,
			this.SHOP_RETRIEVE_SHOP_PRODUCT_GROUPS_NG,
			this.SHOP_RETRIEVE_PRODUCTS,
			this.SHOP_RETRIEVE_PRODUCTS_NG,
			this.SHOP_EDIT_HOMEPAGE,
			this.SHOP_PROMOTIONS_LIST
		];
		return new NavLinkList("Shop", "icon-settings", links);
	}

	public static getShopDashboardMenuLinks(): NavLinkList {
		return new NavLinkList("Shop Dashboard", "icon-home", null, null, this.SHOP_DASHBOARD);
	}

	public static getShopOrderQuotesMenuLinks(): NavLinkList {
		const links = [
			this.SHOPORDER_ORDERS,
			this.SHOPORDER_QUOTES,
			this.SHOPORDER_COMPANY_ORDERS
		];
		return new NavLinkList("Orders & Quotes", "icon-local_shipping", links);
	}

	public static getShopReportsMenuLinks(): NavLinkList {
		const links = [
			this.SHOPREPORT_GENERATE_SALES_REPORT,
			this.SHOPREPORT_GENERATE_CUSTOMERS_REPORT,
			this.SHOPREPORT_GENERATE_PROMOTIONS_REPORT
		];
		return new NavLinkList("Reports", "icon-equalizer", links);
	}
}