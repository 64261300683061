import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsInputType } from "../bs-input/models/bs-input-type";

@Component({
	selector:    'editable',
	templateUrl: './editable.component.html'
})
export class EditableComponent implements OnInit {

	public selected: boolean;

	@Input() public id: string = 'editable-' + Math.random() * 1000;
	@Input() public classes: string = 'form-control';
	@Input() public inputValue: string;
	@Input() public editableType: BsInputType;

	@Output() readonly clicked: EventEmitter<any> = new EventEmitter<any>();
	@Output() readonly submit: EventEmitter<any> = new EventEmitter<any>();

	constructor() {}

	ngOnInit() {}

	public onClick() {
		console.log('editable clicked');
		this.selected = !this.selected;
		this.clicked.emit(this);
	}

	public onKeyPress(values: any) {
		if(values.keyPressed === 13) {
			this.onSubmit(values);
		}
	}

	public onSubmit(values: any) {
		console.log('editable submitted');
		this.selected = !this.selected;
		this.submit.emit({id: this.id, value: values.value});
	}

}
