<input
	[(ngModel)]="value"
    [id]="id"
	[name]="name"
    [type]="type"
    [class]="classes"
    [placeholder]="placeholder"
	[pattern]="pattern"
	[min]="minLength"
	[max]="maxLength"
	[checked]="isChecked"
    [readOnly]="isReadOnly"
    [disabled]="isDisabled"
    [required]="isRequired"
	[autofocus]="isAutoFocus"
	(click)="onClick()"
	(focusout)="onFocusOut()"
	(keypress)="onKeyPress($event)"
/>
<label *ngIf="labelText != null" [for]="id" [class]="labelClasses">{{ labelText }}</label>
<span *ngIf="showCharacterLimit && value != null && maxLength != null" class="input-displaylist">
	<span class="character-count">{{ value.toString().length }}</span>
	<span class="character-limit">{{ maxLength }}</span>
</span>