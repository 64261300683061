import { Component, Input } from "@angular/core";

@Component({
    selector: "product-item-image-svg",
    templateUrl: "./product-item-image-svg.component.html",
})
export class ProductItemImageSvgComponent {
    @Input() public productImage: any;

    constructor() {}
}
