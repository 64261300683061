import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TableRow } from "../models/table-row";
import { BsInputType } from "../../bs-input/models/bs-input-type";

@Component({
    selector: "[bs-table-row]",
    templateUrl: "./bs-table-row.component.html"
})
export class BsTableRowComponent implements OnInit {
    public inputTypes = BsInputType;

    @Input() public tableRow: TableRow;

    @Output() readonly submitEditableValue: EventEmitter<string> = new EventEmitter<string>();

    constructor() {}

    ngOnInit() {}

    public defaultSortOrder() {
        return 0;
    }

    public onSubmitEditableValue(value?: any) {
        value.rowId = this.tableRow.id;
        this.submitEditableValue.emit(value);
    }

}
