import {ColourTypeEnum, PositionMovementEnum} from "../../models/app-enums";
import {Injectable} from "@angular/core";
import {KitDesignLiteService} from "../KitDesignLiteService";


declare var $: any;

@Injectable()
export class KitDesignLiteServiceImpl implements KitDesignLiteService {
    public PositionMovement = PositionMovementEnum;
    public ColourType = ColourTypeEnum;

    constructor() {}

    public findColourFromColourList(hexValue: any, typeColours: Array<any>): any {

        const colourRequired = (foundColours: any) => {
            return $.isArray(hexValue) && hexValue.length > foundColours.length
        }

        if (hexValue && typeColours !== undefined && typeColours !== null && typeColours.length > 0) {
            let foundColours = typeColours.filter(kukriColour => {
                kukriColour.idx = hexValue.indexOf(kukriColour.hexValue)
                return kukriColour && kukriColour.hexValue && hexValue && (this.isColorMatched(hexValue, kukriColour.hexValue) ||
                    (kukriColour.hexValue === null && hexValue === null))
            })

            if (colourRequired(foundColours)) {
                hexValue.forEach((hex: any, idx: number) => {

                    const duplicatedColour = foundColours.filter((foundColour:any, thisIdx: number) =>
                        foundColour.hexValue === hex && thisIdx !== idx)

                    if (duplicatedColour.length !== 0 && colourRequired(foundColours)) {
                        const dummy = this.createDefaultColourClone(duplicatedColour[0], hex)
                        dummy.idx = idx
                        foundColours.push(dummy)
                    }
                })
            }

            if (colourRequired(foundColours)) {
                hexValue.forEach((hex: any, idx: number) => {

                    const missingColour = foundColours.filter(foundColour => foundColour.hexValue === hex)

                    if (missingColour.length === 0 && colourRequired(foundColours)) {
                        const dummy = this.dummyKukriColor(hex)
                        dummy['idx'] = idx
                        foundColours.push(dummy)
                    }
                })
            }

            foundColours = foundColours.sort((a: any, b: any) => a.idx - b.idx)
            return foundColours.length > 1 ? foundColours : foundColours[0]
        }

        return null;
    }

    public applyColourToDesign(hexValue: any, selectedColourTypeEnum: ColourTypeEnum, kukriKitDesignProduct: any): void {
        // console.log("Calling method: applyColourToDesign");

        if (kukriKitDesignProduct.imageFront != undefined && kukriKitDesignProduct.imageFront != null) {
            let svg: any = kukriKitDesignProduct.imageFront;
            kukriKitDesignProduct.imageFront = this.fillSVGWithColour(svg, hexValue, selectedColourTypeEnum);
        }

        if (kukriKitDesignProduct.imageBack != undefined && kukriKitDesignProduct.imageBack != null) {
            let svg: any = kukriKitDesignProduct.imageBack;
            kukriKitDesignProduct.imageBack = this.fillSVGWithColour(svg, hexValue, selectedColourTypeEnum);
        }

        if (kukriKitDesignProduct.imageLeft != undefined && kukriKitDesignProduct.imageLeft != null) {
            let svg: any = kukriKitDesignProduct.imageLeft;
            kukriKitDesignProduct.imageLeft = this.fillSVGWithColour(svg, hexValue, selectedColourTypeEnum);
        }

        if (kukriKitDesignProduct.imageRight != undefined && kukriKitDesignProduct.imageRight != null) {
            let svg: any = kukriKitDesignProduct.imageRight;
            kukriKitDesignProduct.imageRight = this.fillSVGWithColour(svg, hexValue, selectedColourTypeEnum);
        }
        //   console.log("Completed method: applyColourToDesign");
    }

    public applyColourToDesign2(hexValue: any, selectedColourTypeEnum: ColourTypeEnum, svg: string): string {
        // console.log("Calling method: applyColourToDesign");

        if (svg != undefined && svg != null) {
            svg = this.fillSVGWithColour(svg, hexValue, selectedColourTypeEnum);
        }
        //   console.log("Completed method: applyColourToDesign");
        return svg;
    }

    public applyColourToAttributeType(hexValue: string, kukriKitDesignProduct: any, attributeType: string): void {
        // console.log("Calling method: applyBrandingColourToDesign");

        if (kukriKitDesignProduct.imageFront != undefined && kukriKitDesignProduct.imageFront != null) {
            let svg: string = kukriKitDesignProduct.imageFront;
            kukriKitDesignProduct.imageFront = this.fillSvgAttributeTypeWithColour(svg, attributeType, hexValue);
        }

        if (kukriKitDesignProduct.imageBack != undefined && kukriKitDesignProduct.imageBack != null) {
            let svg: string = kukriKitDesignProduct.imageBack;
            kukriKitDesignProduct.imageBack = this.fillSvgAttributeTypeWithColour(svg, attributeType, hexValue);
        }

        if (kukriKitDesignProduct.imageLeft != undefined && kukriKitDesignProduct.imageLeft != null) {
            let svg: string = kukriKitDesignProduct.imageLeft;
            kukriKitDesignProduct.imageLeft = this.fillSvgAttributeTypeWithColour(svg, attributeType, hexValue);
        }

        if (kukriKitDesignProduct.imageRight != undefined && kukriKitDesignProduct.imageRight != null) {
            let svg: string = kukriKitDesignProduct.imageRight;
            kukriKitDesignProduct.imageRight = this.fillSvgAttributeTypeWithColour(svg, attributeType, hexValue);
        }
        //   console.log("Completed method: applyBrandingColourToDesign");
    }

    private fillSVGWithColour(svg: any, hexValue: any, selectedColourTypeEnum: ColourTypeEnum): any {
        //   console.log("Calling method: fillSVGWithColour");

        const isGradientElement = (element: any) => {
            return this.isGradientType(element)
        }

        const fillGradientStops = (element: any, hexValue: any) => {
            this.fillGradient(element, hexValue)
        }


        let branding = selectedColourTypeEnum == ColourTypeEnum.BrandingColour;
        let firstHexValue: any = null;

        svg = $("<div/>").html(svg);
        svg.find("[data-p='" + (selectedColourTypeEnum + 1) + "']").each(
            function() {
                const element = $(this)
                let closestSvgId = element.closest("svg").attr("id");

                if (closestSvgId != undefined && closestSvgId != null && (branding && closestSvgId.indexOf("BRANDING") !== -1 ||
                        closestSvgId.indexOf("_NAME_") == -1 && closestSvgId.indexOf("_NUMBER_") == -1)) {

                    // console.log(closestSvgId, )
                    if (isGradientElement(element)) {
                        fillGradientStops(element, hexValue)
                    } else {

                        let fillHexValue = element.attr("fill")

                        if (!firstHexValue) {
                            firstHexValue = fillHexValue;
                        }

                        if (firstHexValue == fillHexValue) {
                            element.attr("fill", hexValue);
                        }
                    }
                }
            }
        );

        svg = svg.html();
        //  console.log("Completed method: fillSVGWithColour");
        return svg;
    }

    private findExtraBrandingDataPElements(parentElement: any) {
        // console.log('path[data-p="' + (ColourTypeEnum.BrandingColour + 1) + '"]',
        //     $('path[data-p="' + (ColourTypeEnum.BrandingColour + 1) + '"]', parentElement))
        return $('path[data-p="' + (ColourTypeEnum.BrandingColour + 1) + '"]', parentElement)
            .filter(function() {
                let id = $(this).attr("id")
                return id.indexOf("_NAME_") == -1 && id.indexOf("_NUMBER_") == -1
            })
    }

    public fillSvgAttributeTypeWithColour(parentSvgXml: string, attributeType: string, hexValue: string): string {
          // console.log("Calling method: fillSvgAttributeTypeWithColour");
        let branding = attributeType.indexOf("BRANDING") !== -1;
        let parentDiv = $("<div/>").html(parentSvgXml);

        function processDataP(elementDataP: any) {
            if (elementDataP.attr("fill") != null && elementDataP.attr("fill") != "null" &&
                elementDataP.attr("fill") != "undefined" && elementDataP.attr("fill") != "none") {
                if (elementDataP.attr("id") != null && elementDataP.attr("id") != "null" &&
                    elementDataP.attr("id") != "undefined" &&
                    (!branding || ((elementDataP.attr("data-p") &&
                        elementDataP.attr("data-p") == ColourTypeEnum.BrandingColour +1) ||
                        elementDataP.attr("id").indexOf("_BRANDING_") !== -1))) {
                    elementDataP.attr("fill", hexValue);
                }
            }
        }

        if (hexValue != undefined && hexValue != null) {
            parentDiv.find('[id*="_' + attributeType + '_"]').find("*")
                .each((idx: number, elem: any) => processDataP($(elem)));

            if (branding) {
                this.findExtraBrandingDataPElements(parentDiv)
                    .each((idx: number, elem: any) => processDataP($(elem)))
            }
        }
        //   console.log("Completed method: fillSvgAttributeTypeWithColour");
        return parentDiv.html();
    }

    public fillSvgWithColour(parentSvgXml: string, svgId: string, hexValue: string): string {
          // console.log("Calling method: fillSvgWithColour");
        let branding = svgId.indexOf("BRANDING") !== -1;
        let parentDiv = $("<div/>").html(parentSvgXml);
        let firstHexValue: any = null;

        function processDataP(elementDataP: any) {
            if (elementDataP.attr("fill") != null && elementDataP.attr("fill") != "null" &&
                elementDataP.attr("fill") != "undefined" && elementDataP.attr("fill") != "none" &&
                (!branding || elementDataP.attr("data-p") == ColourTypeEnum.BrandingColour +1)) {
                if (elementDataP.attr("id") != null && elementDataP.attr("id") != "null" &&
                    elementDataP.attr("id") != "undefined") {

                    let fillHexValue = elementDataP.attr("fill")

                    if (!firstHexValue) {
                        firstHexValue = fillHexValue;
                    }

                    if (firstHexValue == fillHexValue) {
                        elementDataP.attr("fill", hexValue);
                    }
                }
            }
        }

        if (hexValue != undefined && hexValue != null) {
            parentDiv.find('svg[id="' + svgId + '"]').find("*")
                .each((idx: number, elem: any) => processDataP($(elem)));

            if (branding) {
                this.findExtraBrandingDataPElements(parentDiv)
                    .each((idx: number, elem: any) => processDataP($(elem)))
            }
        }
        //   console.log("Completed method: fillSvgWithColour");
        return parentDiv.html();
    }

    public attachPositionToDesign(kukriKitDesignProduct: any, positionSvgXml: string, newPositionSvgId: string,
                                  selectedAttribute: any, oldPositionSvgId?: string): void {
        // console.log("Calling method: attachPositionToDesign");

        let newParentSvgXml: string = null;
        let oldParentSvgXml: string = null;

        let newPositionView: string = null;
        let oldPositionView: string = null;

        if (newPositionSvgId.indexOf("FRONT") >= 0) {
            newParentSvgXml = kukriKitDesignProduct.imageFront;
            newParentSvgXml = this.attachChildSvgToParentSvg(newParentSvgXml, positionSvgXml);
            kukriKitDesignProduct.imageFront = newParentSvgXml;
            newPositionView = "FRONT";
        } else if (newPositionSvgId.indexOf("BACK") >= 0) {
            newParentSvgXml = kukriKitDesignProduct.imageBack;
            newParentSvgXml = this.attachChildSvgToParentSvg(newParentSvgXml, positionSvgXml);
            kukriKitDesignProduct.imageBack = newParentSvgXml;
            newPositionView = "BACK";
        } else if (newPositionSvgId.indexOf("LEFT") >= 0) {
            newParentSvgXml = kukriKitDesignProduct.imageLeft;
            newParentSvgXml = this.attachChildSvgToParentSvg(newParentSvgXml, positionSvgXml);
            kukriKitDesignProduct.imageLeft = newParentSvgXml;
            newPositionView = "LEFT";
        } else if (newPositionSvgId.indexOf("RIGHT") >= 0) {
            newParentSvgXml = kukriKitDesignProduct.imageRight;
            newParentSvgXml = this.attachChildSvgToParentSvg(newParentSvgXml, positionSvgXml);
            kukriKitDesignProduct.imageRight = newParentSvgXml;
            newPositionView = "RIGHT";
        }

        if (oldPositionSvgId != undefined && oldPositionSvgId != null) {
            if (oldPositionSvgId.indexOf("FRONT") >= 0) {
                oldParentSvgXml = kukriKitDesignProduct.imageFront;
                oldParentSvgXml = this.removeChildSvgFromParentSvg(oldParentSvgXml, oldPositionSvgId);
                kukriKitDesignProduct.imageFront = oldParentSvgXml;
                oldPositionView = "FRONT";
            } else if (oldPositionSvgId.indexOf("BACK") >= 0) {
                oldParentSvgXml = kukriKitDesignProduct.imageBack;
                oldParentSvgXml = this.removeChildSvgFromParentSvg(oldParentSvgXml, oldPositionSvgId);
                kukriKitDesignProduct.imageBack = oldParentSvgXml;
                oldPositionView = "BACK";
            } else if (oldPositionSvgId.indexOf("LEFT") >= 0) {
                oldParentSvgXml = kukriKitDesignProduct.imageLeft;
                oldParentSvgXml = this.removeChildSvgFromParentSvg(oldParentSvgXml, oldPositionSvgId);
                kukriKitDesignProduct.imageLeft = oldParentSvgXml;
                oldPositionView = "LEFT";
            } else if (oldPositionSvgId.indexOf("RIGHT") >= 0) {
                oldParentSvgXml = kukriKitDesignProduct.imageRight;
                oldParentSvgXml = this.removeChildSvgFromParentSvg(oldParentSvgXml, oldPositionSvgId);
                kukriKitDesignProduct.imageRight = oldParentSvgXml;
                oldPositionView = "RIGHT";
            }
        }

        if (newPositionView != oldPositionView && newPositionView != undefined && newPositionView != null && oldPositionView != undefined && oldPositionView != null) {
            // position view is different so reset the colour and font, and ask customer to choose again.
            selectedAttribute.selectedColour = null;
            selectedAttribute.selectedFont = null;
            selectedAttribute.hexValue = null;
            selectedAttribute.fontSvgId = null;
            //      if (selectedAttribute.selectedLogoUrl != undefined
            //          && selectedAttribute.selectedLogoUrl != null) {
            //          alert("Please choose logo.");
            //      } else {
            //          alert("Please choose font and colour.");
            //      }

            if (oldPositionSvgId.indexOf("_NAME_") !== -1 || oldPositionSvgId.indexOf("_NUMBER_") !== -1) {
                selectedAttribute.selectedFontDescription = null;
                //    alert("Please choose font and colour.");
            }

            // selectedAttribute.selectedLogoUrl = null;
        }

        // console.log("Completed method: attachPositionToDesign");
    }

    public attachChildSvgToParentSvg(parentSvgXml: string, childSvgXml: string): string {
        // console.log("Calling method: attachChildSvgToParentSvg");
        // attach child to parent element
        childSvgXml = this.stripSvgXMLTag(childSvgXml);

        let parentDiv = $("<div/>").html(parentSvgXml);
        parentDiv.find("svg").first().append(childSvgXml);
        parentSvgXml = parentDiv.html();

        parentSvgXml = this.removeIEcolons(parentSvgXml);
        // console.log("Completed method: attachChildSvgToParentSvg");
        return parentSvgXml;
    }

    public removeChildSvgFromParentSvg(parentSvgXml: string, removeSvgId: string): string {
        // console.log("Calling method: removeChildSvgFromParentSvg");
        let parentSvgDiv = $("<div/>").html(parentSvgXml);
        parentSvgDiv.find('svg[id="' + removeSvgId + '"]').remove();
        // console.log("Completed method: removeChildSvgFromParentSvg");
        return parentSvgDiv.html();
    }

    public attachFontToDesign(parentSvgXml: string, positionSvgTitle: string, positionSvgId: string,
                              positionSvgXml: string, fontSvgId: string, fontSvgXml: string): string {
        // console.log("Calling method: attachFontToDesign");
        parentSvgXml = this.removeChildSvgFromParentSvg(parentSvgXml, positionSvgId);

        fontSvgXml = this.stripSvgXMLTag(fontSvgXml);
        positionSvgXml = this.stripSvgXMLTag(positionSvgXml);

        let parentSvgDiv = $("<div/>").html(parentSvgXml);
        let fontSvgDiv = $("<div/>").html(fontSvgXml);
        let positionSvgDiv = $("<div/>").html(positionSvgXml);

        // new logic start here

        positionSvgDiv.find("svg").first().attr("style", "overflow:visible");
        let viewBoxFontSvg: string = fontSvgDiv.find("svg").first().attr("viewBox");
        positionSvgDiv.find("svg").first().attr("viewBox", viewBoxFontSvg);
        positionSvgDiv.find("svg").first().find("g").first().empty();
        positionSvgDiv.find("svg").first().find("g").first().removeAttr("viewBox");
        positionSvgDiv.find("svg").first().find("g").first().append(fontSvgDiv.find("svg").first().find("g").first().html());
        positionSvgDiv.find("svg").first().attr("title", positionSvgTitle);

        // rotate Font SVG
        if (positionSvgDiv.find("svg").first().find("g")) {
            // console.log("Rotating SVG...");
            let rotateDegree: string = positionSvgDiv.find("svg").first().find("g").first().attr("transform");
            // console.log("rotateDegree: ", rotateDegree);
            this.rotateSvg(rotateDegree, positionSvgDiv.find("svg").first());
        }

        parentSvgDiv.find("svg").first().append(positionSvgDiv.html());

        // end new logic
        /*
    fontSvgDiv.find('svg').first().attr("style", "overflow:visible");
     
    let viewBoxFontSvg : string = fontSvgDiv.find('svg').first().attr("viewBox");
    let splitViewBox : Array<string> =  viewBoxFontSvg.split(' ');
    
    let positionSvgWidth : number = parseFloat(positionSvgDiv.find('svg').first().attr("width"));
    let positionSvgHeight : number = parseFloat(positionSvgDiv.find('svg').first().attr("height"));
    let fontSvgWidth : number = parseFloat(splitViewBox[2]);
    let fontSvgHeight : number = parseFloat(splitViewBox[3]);
    let positionSvgX : number = parseFloat(positionSvgDiv.find('svg').first().attr("x"));
    let positionSvgY : number = parseFloat(positionSvgDiv.find('svg').first().attr("y"));
    let newX : number = positionSvgX + (( positionSvgWidth -  fontSvgWidth) / 2);
    let newY : number = positionSvgY + (( positionSvgHeight -  fontSvgHeight) / 2);
    
    fontSvgDiv.find('svg').first().attr("id", positionSvgId);
    fontSvgDiv.find('svg').first().attr("height", fontSvgHeight);
    fontSvgDiv.find('svg').first().attr("width", fontSvgWidth);
    fontSvgDiv.find('svg').first().attr("x", newX);
    fontSvgDiv.find('svg').first().attr("y", newY);
    fontSvgDiv.find('svg').first().attr("title", positionSvgTitle);
      
    // rotate Font SVG
    if (positionSvgDiv.find('svg').first().find('g')) {
        let rotateDegree : string = positionSvgDiv.find('svg').first().find('g').first().attr('transform');
        this.rotateSvg(rotateDegree, fontSvgDiv.find('svg').first());
    }
      
    parentSvgDiv.find('svg').first().append(fontSvgDiv.html());
     */
        // console.log("Completed method: attachFontToDesign");

        return parentSvgDiv.html();
    }

    public attachLogoToDesign(parentSvgXml: string, positionSvgTitle: string, positionSvgId: string,
                              positionSvgXml: string, imageUrl: string): string {
        //   console.log("Calling method: attachLogoToDesign");
        parentSvgXml = this.removeChildSvgFromParentSvg(parentSvgXml, positionSvgId);

        let logoSvgXml: string;
        let styling = 'style="overflow:visible;'
        let opacity = imageUrl.toLowerCase().indexOf('watermark') !== -1 || positionSvgTitle.toLowerCase().indexOf('watermark') !== -1 ||
            positionSvgId.toLowerCase().indexOf('watermark') !== -1 ? ' opacity: '+ this.defaultSliderLogoOpacityScaleValue() + ';' : ''

        if ($.support.msie || !!navigator.userAgent.match(/Trident\/7\./)) {
            logoSvgXml = '<svg version="1.1" '+styling+'" preserveAspectRatio="none" title="' + positionSvgTitle + '"><g><image '+styling+opacity+'"></image></g></svg>';
        } else {
            logoSvgXml = '<svg version="1.1" '+styling+'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" title="' +
                positionSvgTitle + '"><g><image '+styling+opacity+'"></image></g></svg>';
        }

        positionSvgXml = this.stripSvgXMLTag(positionSvgXml);

        let parentSvgDiv = $("<div/>").html(parentSvgXml);
        let logoSvgDiv = $("<div/>").html(logoSvgXml);
        let positionSvgDiv = $("<div/>").html(positionSvgXml);
        let positionSvgRectangle = positionSvgDiv.find("svg").first().find("rect").first();

        // console.log("positionSvgRectangle: ");
        // console.log(positionSvgRectangle);

        // TODO: Take all of the values from the original SVG in the svgXml property
        // TODO: Then pass all of the values to the new parentSVG set at logoSvgXml...
        logoSvgDiv.find("svg").first().attr("viewBox", positionSvgDiv.find("svg").first().attr("viewBox"));
        logoSvgDiv.find("svg").first().attr("x", positionSvgDiv.find("svg").first().attr("x"));
        logoSvgDiv.find("svg").first().attr("y", positionSvgDiv.find("svg").first().attr("y"));
        logoSvgDiv.find("svg").first().attr("width", positionSvgDiv.find("svg").first().attr("width"));
        logoSvgDiv.find("svg").first().attr("height", positionSvgDiv.find("svg").first().attr("height"));
        logoSvgDiv.find("svg").first().attr("id", positionSvgDiv.find("svg").first().attr("id"));

        // rotate Font SVG
        // TODO: This is where the transform and rotate are being set
        // So if it is part of a nested SVG Group (g);
        // console.log("Rotate SVG...");
        if (positionSvgDiv.find("svg").first().find("g")) {
            // Adds the transform attribute to the first element in the SVG Position
            // TODO: This is undefined, so not coming through from the back end...
            // jQuery is just showing it as defined...
            // This does actually rotate on the sleeves, definitely comes down to config/settings saved in k2-admin
            // http://localhost:8080/teamshop/kukri/kdSelectFit?designTemplateId=34804

            // TODO: this could be expanded to check if the group has a transform, if so, use the existing approach, rotateSVG

            // If this is not set, then we can just have a fallback option, that simply rotates all SVG's -90?
            let rotateDegree: string = positionSvgDiv.find("svg").first().find("g").first().attr("transform");

            // TODO: Add the further check here if not found...
            // TODO: This will look at the rectangle shape

            // Passes in
            // <rect x="-3998.21" y="-4140.9"
            // transform="translate(-3996.8 3998.96) rotate(90)"

            // TODO: Add a condtional check? Only call this function if rotateDegree is true...
            // TODO: SvgDiv is a bad name, it is not a div elem
            // console.log("positionSvgDiv");
            // console.log(positionSvgDiv);

            if (rotateDegree) {
                this.rotateSvg(rotateDegree, logoSvgDiv.find("svg").first());
            } else {
                const rotateDegreeDefault: string = positionSvgRectangle.attr("transform");
                const imgWidth: string = logoSvgDiv.find("svg").first().find("image").attr("width");
                // console.log("imgWidth: ", imgWidth);
                const imgHeight: string = logoSvgDiv.find("svg").first().find("image").attr("height");
                // console.log("imgHeight: ", imgHeight);

                this.rotateSvgTempFix(rotateDegreeDefault, logoSvgDiv.find("svg").first(), positionSvgRectangle.attr("height"), positionSvgRectangle.attr("width"));
            }
        }

        // TODO: This is where logo attributes are set, based on rectangle svg shape attributes...
        // TODO: Add another conditional check here,
        // If rotateDegree is undefined
        logoSvgDiv.find("svg").first().find("image").attr("x", positionSvgRectangle.attr("x"));
        logoSvgDiv.find("svg").first().find("image").attr("y", positionSvgRectangle.attr("y"));
        logoSvgDiv.find("svg").first().find("image").attr("width", positionSvgRectangle.attr("width"));
        logoSvgDiv.find("svg").first().find("image").attr("height", positionSvgRectangle.attr("height"));

        let rotateDegree: string = positionSvgDiv.find("svg").first().find("g").first().attr("transform");

        // if(!rotateDegree) {
        //     logoSvgDiv
        //         .find("svg")
        //         .first()
        //         .find("image")
        //         .attr("x", 0);
        //     logoSvgDiv
        //         .find("svg")
        //         .first()
        //         .find("image")
        //         .attr("y", 0);
        //     logoSvgDiv
        //         .find("svg")
        //         .first()
        //         .find("image")
        //         .attr("width", positionSvgRectangle.attr("height"));
        //     logoSvgDiv
        //         .find("svg")
        //         .first()
        //         .find("image")
        //         .attr("height", positionSvgRectangle.attr("width"));
        // }

        if ($.support.msie || !!navigator.userAgent.match(/Trident\/7\./)) {
            logoSvgDiv.find("svg").first().find("image").attr("href", imageUrl);
        } else {
            logoSvgDiv.find("svg").first().find("image").attr("xlink:href", imageUrl);
        }

        if (positionSvgTitle.toLowerCase().startsWith("full")) {
            parentSvgDiv.find("svg").first().prepend(logoSvgDiv.html());
        } else {
            parentSvgDiv.find("svg").first().append(logoSvgDiv.html());
        }
        //   console.log("Completed method: attachLogoToDesign");

        return parentSvgDiv.html();
    }

    public rotateSvg(rotateDegree: string, svg: any): void {
        // console.log("Calling method: rotateSvg");
        // console.log("rotate value passed in:", rotateDegree);
        // debugger;
        // translate(-3996.8 3998.96) rotate(90)
        // Can be undefined where transform is not present in svgXml
        if (rotateDegree != undefined && rotateDegree != null && rotateDegree != "") {
            let transformMain = rotateDegree;

            // GET THE X VALUE FROM PARENT SVG ARRAY
            // <rect x="-3998.21" y="-4140.9"
            // transform="translate(-3996.8 3998.96) rotate(90)"
            let transformArr1 = transformMain.split(" ");
            // [
            //     "translate(-3996.8",
            //     "3998.96)",
            //     "rotate(90)"
            // ]

            let sX = transformArr1[1];
            // "3998.96)"

            let sY = transformArr1[2];
            // "rotate(90)

            let transformArr2 = transformArr1[0].split("(");
            // [
            //     "translate",
            //     "-3996.8"
            // ]

            let tranmLen = transformArr2.length;
            // 2

            // Get the last value in the array which is expected to be the translate X coordinate
            let angle = parseFloat(transformArr2[tranmLen - 1]);
            // -3996.8

            let modeAngle = angle % 360;
            // -36.80000000000018

            if (modeAngle < 0) {
                modeAngle = 360 + modeAngle;
                // 323.1999999999998
            }

            if (modeAngle > 90 && modeAngle < 270) {
                angle = modeAngle - 180;
            }

            // Set the rotate string
            let strRot = "rotate(" + angle + " " + sX + " " + sY;

            // console.log("strRot: ", strRot);
            // This should be taken from the existing Rect and added the same values back,
            // Then transform itself it missing

            // On top of that, the new rotate value would look something like...
            // Which perhaps answer why it is not being added...
            // rotate(-3996.8, "3998.96)", "rotate(90));
            if (strRot.indexOf("undefined") == -1 && strRot.indexOf("null") == -1) {
                svg.find("g").attr("transform", strRot);
            }
        }
        // console.log("Completed method: rotateSvg");
    }

    public rotateSvgTempFix(rectTransformAttributeValue: string, svg: any, imgWidth: string, imgHeight: string): void {
        // console.log("Calling method: rotateSvgTemp");
        // console.log("rotate value passed in:", rectTransformAttributeValue);
        // console.log("logoSvgDiv");
        // console.log(svg);
        // console.log("imgWidth: ", imgWidth);
        // console.log("imgHeight: ", imgHeight);

        // debugger;
        // translate(-3996.8 3998.96) rotate(90)
        // Can be undefined where transform is not present in svgXml
        if (rectTransformAttributeValue) {
            let transformArr1 = rectTransformAttributeValue.split(" ");
            // console.log("transformArr1: ", transformArr1);

            let rotationString = transformArr1[2];
            rotationString = rotationString.slice(0, rotationString.length -1);
            // console.log("rotationString: ", rotationString);

            // TODO: also add on the rotation to the center, looking at the image width and height...
            const halfImgWidth = (parseInt(imgWidth) / 2);
            const halfImgHeight = (parseInt(imgHeight) / 2);

            if (rotationString) {
                let rotationStringCenter = `${rotationString} ${halfImgWidth} ${halfImgHeight} )`;
                svg.find("g").attr("transform", rotationStringCenter);
            }
        }
    }

    public stripSvgXMLTag(svgXml: string): string {
        //   console.log("Calling method: stripSvgXMLTag");
        try {
            if (svgXml != null) {
                let startingIndex = svgXml.indexOf("<svg");
                svgXml = svgXml.substring(startingIndex);

                if ($.support.msie || !!navigator.userAgent.match(/Trident\/7\./)) {
                    svgXml = svgXml.replace('xml:space="preserve"', "");
                    svgXml = svgXml.replace('xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"', "");
                }

                //added functionality to make the dummy images invisible
                if (svgXml != "" && $(svgXml).find("svg").length == 0) {
                    let fill = $(svgXml).find("rect").attr("fill");

                    if (fill != undefined && fill.toUpperCase() == "#FFFF00") {
                        let startIndex = svgXml.indexOf("<g>"), endingIndex = svgXml.indexOf("</g>");
                        svgXml = svgXml.substring(0, startIndex) + "<g>" + svgXml.substring(endingIndex);
                    }
                }

                return svgXml;
            }
        } finally {
            //  console.log("Completed method: stripSvgXMLTag");
        }
        return "";
    }

    public removeIEcolons(svgXml: any): string {
        //  console.log("Calling method: removeIEcolons");

        svgXml = $("<div/>").html(svgXml);
        let viewBox: string = svgXml.find("svg").first().attr("viewBox");
        svgXml.find("svg").first().attr("viewBox", "0 0 400 500");
        svgXml.find("svg").first().attr("viewBox", viewBox);
        svgXml = svgXml.html();

        //  console.log("Completed method: removeIEcolons");
        return svgXml;
    }

    public removeUnneccassarySlotsFromWholeDesign(kukriKitDesignProduct: any, selectedAttributeList: Array<any>,
                                                  positions: Array<any>): void {
        // console.log("Calling method: removeUnneccassarySlotsFromWholeDesign");
        if (kukriKitDesignProduct.imageFront != undefined && kukriKitDesignProduct.imageFront != null) {
            kukriKitDesignProduct.imageFront = this.removeUnneccassarySlots(kukriKitDesignProduct.imageFront);
        }

        if (kukriKitDesignProduct.imageBack != undefined && kukriKitDesignProduct.imageBack != null) {
            kukriKitDesignProduct.imageBack = this.removeUnneccassarySlots(kukriKitDesignProduct.imageBack);
        }

        if (kukriKitDesignProduct.imageLeft != undefined && kukriKitDesignProduct.imageLeft != null) {
            kukriKitDesignProduct.imageLeft = this.removeUnneccassarySlots(kukriKitDesignProduct.imageLeft);
        }

        if (kukriKitDesignProduct.imageRight != undefined && kukriKitDesignProduct.imageRight != null) {
            kukriKitDesignProduct.imageRight = this.removeUnneccassarySlots(kukriKitDesignProduct.imageRight);
        }
        // reset selected positions which does not have any fontSvgId or logourl
        this.resetSelectedAttributes(selectedAttributeList, positions);
        // console.log("Completed method: removeUnneccassarySlotsFromWholeDesign");
    }

    public resetAllSelectedAttributes(kukriKitDesignProduct: any): void {
        this.resetSelectedAttributes(kukriKitDesignProduct.selectedNames, kukriKitDesignProduct.namePositions);
        this.resetSelectedAttributes(kukriKitDesignProduct.selectedNumbers, kukriKitDesignProduct.numberPositions);
        this.resetSelectedAttributes(kukriKitDesignProduct.selectedLogos, kukriKitDesignProduct.logoPositions);
    }

    private resetSelectedAttributes(selectedAttributeList: Array<any>, positions: Array<any>): void {
        // reset selected positions which does not have any fontSvgId or logourl
        if (selectedAttributeList != undefined && selectedAttributeList != null && selectedAttributeList.length > 0 && positions != undefined && positions != null && positions.length > 0) {
            for (let selectedAttribute of selectedAttributeList) {
                if (selectedAttribute.positionSvgId != undefined && selectedAttribute.positionSvgId != null && (selectedAttribute.fontSvgId == undefined ||
                     selectedAttribute.fontSvgId == null) && (selectedAttribute.selectedLogoUrl == undefined || selectedAttribute.selectedLogoUrl == null)) {
                    this.resetKukrikitDesignSelectedAttribute(selectedAttribute, positions);
                }
            }
        }
    }

    public removeUnneccassarySlots(svgXml: string): string {
        //  console.log("Calling method: removeUnneccassarySlots");

        let parentSvgDiv = $("<div/>").html(svgXml);

        parentSvgDiv.find("rect").each(function() {
            if ($(this).attr("id") != undefined && $(this).attr("id") != null && $(this).attr("id") != "null") {
                if ($(this).attr("id").indexOf("_LOGO_") !== -1 || $(this).attr("id").indexOf("_NAME_") !== -1 || $(this).attr("id").indexOf("_NUMBER_") !== -1 ||
                    $(this).attr("id").indexOf("_TEXT_") !== -1 || $(this).attr("id").indexOf("_BRANDING_") !== -1 || $(this).attr("id").indexOf("_LABEL_") !== -1) {
                    $(this).closest("svg").remove();
                }
            }
        });

        //TODO: If the ID is not on the rect, check the G element
        parentSvgDiv.find("g").each(function() {
            if ($(this).attr("id") != undefined && $(this).attr("id") != null && $(this).attr("id") != "null") {
                if ($(this).attr("id").indexOf("_LOGO_") !== -1 || $(this).attr("id").indexOf("_NAME_") !== -1 || $(this).attr("id").indexOf("_NUMBER_") !== -1 ||
                    $(this).attr("id").indexOf("_TEXT_") !== -1 || $(this).attr("id").indexOf("_BRANDING_") !== -1 || $(this).attr("id").indexOf("_LABEL_") !== -1) {
                    $(this).closest("svg").remove();
                }
            }
        });

        //  console.log("Completed method: removeUnneccassarySlots");
        return parentSvgDiv.html();
    }

    public findSelectedPosition(attributePositions: Array<any>, positionSvgId: string): any {
        //   console.log("Calling method: findSelectedPosition");
        try {
            if (attributePositions != undefined && attributePositions != null && attributePositions.length > 0) {
                for (let position of attributePositions) {
                    if (position.svgId == positionSvgId) {
                        return position;
                    }
                }
            }
        } finally {
            //  console.log("Completed method: findSelectedPosition");
        }
        return null;
    }

    public resetKukrikitDesignSelectedAttribute(selectedAttribute: any, positions: Array<any>): void {
        //   console.log("Calling method: resetKukrikitDesignSelectedAttribute");
        try {
            if (selectedAttribute != undefined && selectedAttribute != null) {
                if (selectedAttribute.positionSvgId != undefined && selectedAttribute.positionSvgId != null) {
                    let position = this.findSelectedPosition(positions, selectedAttribute.positionSvgId);
                    position.selected = false;
                }

                selectedAttribute.positionSvgId = null;
                selectedAttribute.fontSvgId = null;
                selectedAttribute.hexValue = null;
                selectedAttribute.selectedColour = null;
                selectedAttribute.selectedPosition = null;
                selectedAttribute.selectedFont = null;
                //   selectedAttribute.attributeType = null; - default value is not null
                selectedAttribute.selectedLogoUrl = null;

                selectedAttribute.charge = null;
                selectedAttribute.selectedFontDescription = null;
                selectedAttribute.description = null;
            }
        } finally {
            //   console.log("Completed method: resetKukrikitDesignSelectedAttribute");
        }

        return selectedAttribute;
    }

    public sortAttributePositions(kukriKitDesignProduct: any): void {
        // console.log("Calling method: sortAttributePositions");
        if (kukriKitDesignProduct != undefined && kukriKitDesignProduct != null) {
            if (kukriKitDesignProduct.namePositions != undefined && kukriKitDesignProduct.namePositions != null && kukriKitDesignProduct.namePositions.length > 0) {
                kukriKitDesignProduct.namePositions = kukriKitDesignProduct.namePositions.sort((pos1: any, pos2: any) => {
                    return this.compareSvgForSorting(pos1.svgId, pos2.svgId);
                });
            }

            if (kukriKitDesignProduct.numberPositions != undefined && kukriKitDesignProduct.numberPositions != null && kukriKitDesignProduct.numberPositions.length > 0) {
                kukriKitDesignProduct.numberPositions = kukriKitDesignProduct.numberPositions.sort((pos1: any, pos2: any) => {
                    return this.compareSvgForSorting(pos1.svgId, pos2.svgId);
                });
            }

            if (kukriKitDesignProduct.logoPositions != undefined && kukriKitDesignProduct.logoPositions != null && kukriKitDesignProduct.logoPositions.length > 0) {
                kukriKitDesignProduct.logoPositions = kukriKitDesignProduct.logoPositions.sort((pos1: any, pos2: any) => {
                    return this.compareSvgForSorting(pos1.svgId, pos2.svgId);
                });
            }
        }
        // console.log("Completed method: sortAttributePositions");
    }

    private compareSvgForSorting(svgPos1: string, svgPos2: string): number {
        /*
         * FRONT = 0
         * BACK = 1
         * LEFT = 2
         * RIGHT = 3
         */
        let svgPos1Num: number = 0;
        let svgPos2Num: number = 0;

        if (svgPos1.indexOf("_FRONT") >= 0) {
            svgPos1Num = 0;
        } else if (svgPos1.indexOf("_BACK") >= 0) {
            svgPos1Num = 1;
        } else if (svgPos1.indexOf("_LEFT") >= 0) {
            svgPos1Num = 2;
        } else if (svgPos1.indexOf("_RIGHT") >= 0) {
            svgPos1Num = 3;
        }

        if (svgPos2.indexOf("_FRONT") >= 0) {
            svgPos2Num = 0;
        } else if (svgPos2.indexOf("_BACK") >= 0) {
            svgPos2Num = 1;
        } else if (svgPos2.indexOf("_LEFT") >= 0) {
            svgPos2Num = 2;
        } else if (svgPos2.indexOf("_RIGHT") >= 0) {
            svgPos2Num = 3;
        }

        if (svgPos1Num > svgPos2Num) {
            return 1;
        }

        if (svgPos1Num < svgPos2Num) {
            return -1;
        }

        return 0; // both are equal
    }

    public selectAttributePosition(kukriKitDesignProduct: any, selectedAttributes: Array<any>,
                                   attributePositions: Array<any>, selectedAttributeIndex: number,
                                   attributePositionIndex: number): void {
        // remove un-nessacary slots from design
        //   console.log("Calling method: selectAttributePosition");
        this.removeUnneccassarySlotsFromWholeDesign(kukriKitDesignProduct, selectedAttributes, attributePositions);

        // debugger;
        if (selectedAttributes != undefined && selectedAttributes != null && selectedAttributes.length > 0) {
            if (attributePositions != undefined && attributePositions != null && attributePositions.length > 0) {
                let selectedAttribute = selectedAttributes[selectedAttributeIndex];
                let selectedAttributePosition = attributePositions[attributePositionIndex];
                let oldPositionSvgId = selectedAttribute.positionSvgId;

                selectedAttribute.positionSvgId = selectedAttributePosition.svgId;
                selectedAttribute.selectedPosition = selectedAttributePosition.description;
                selectedAttribute.charge = selectedAttributePosition.charge;
                selectedAttributePosition.selected = true;
                if (oldPositionSvgId != undefined || oldPositionSvgId != null) {
                    // de-select the previous selected position so that it could be used.
                    for (let position of attributePositions) {
                        if (position.svgId == oldPositionSvgId) {
                            position.selected = false;
                            break;
                        }
                    }
                }
                // Add position to Design
                this.attachPositionToDesign(kukriKitDesignProduct, selectedAttributePosition.svgXml, selectedAttribute.positionSvgId, selectedAttribute, oldPositionSvgId);
                // add font and colour to Design if available
                let parentSvgXml = null;
                if (selectedAttribute.positionSvgId.indexOf("FRONT") >= 0) {
                    parentSvgXml = kukriKitDesignProduct.imageFront;
                    parentSvgXml = this.attachFontAndColourToPosition(parentSvgXml, selectedAttribute, selectedAttributePosition);
                    parentSvgXml = this.attachLogoToPosition(parentSvgXml, selectedAttribute, selectedAttributePosition);
                    kukriKitDesignProduct.imageFront = parentSvgXml;
                    this.changeView("FRONT");
                } else if (selectedAttribute.positionSvgId.indexOf("BACK") >= 0) {
                    parentSvgXml = kukriKitDesignProduct.imageBack;
                    parentSvgXml = this.attachFontAndColourToPosition(parentSvgXml, selectedAttribute, selectedAttributePosition);
                    parentSvgXml = this.attachLogoToPosition(parentSvgXml, selectedAttribute, selectedAttributePosition);
                    kukriKitDesignProduct.imageBack = parentSvgXml;
                    this.changeView("BACK");
                } else if (selectedAttribute.positionSvgId.indexOf("LEFT") >= 0) {
                    parentSvgXml = kukriKitDesignProduct.imageLeft;
                    parentSvgXml = this.attachFontAndColourToPosition(parentSvgXml, selectedAttribute, selectedAttributePosition);
                    parentSvgXml = this.attachLogoToPosition(parentSvgXml, selectedAttribute, selectedAttributePosition);
                    kukriKitDesignProduct.imageLeft = parentSvgXml;
                    this.changeView("LEFT");
                } else if (selectedAttribute.positionSvgId.indexOf("RIGHT") >= 0) {
                    parentSvgXml = kukriKitDesignProduct.imageRight;
                    parentSvgXml = this.attachFontAndColourToPosition(parentSvgXml, selectedAttribute, selectedAttributePosition);
                    parentSvgXml = this.attachLogoToPosition(parentSvgXml, selectedAttribute, selectedAttributePosition);
                    kukriKitDesignProduct.imageRight = parentSvgXml;
                    this.changeView("RIGHT");
                }
            }
        }
        //  console.log("Completed method: selectAttributePosition");
    }

    private attachLogoToPosition(parentSvgXml: string, selectedAttribute: any,
                                 selectedAttributePosition: any): string {
        //    console.log("Calling method: attachLogoToPosition");
        try {
            if (selectedAttribute.selectedLogoUrl != undefined && selectedAttribute.selectedLogoUrl != null) {
                parentSvgXml = this.attachLogoToDesign(parentSvgXml, selectedAttributePosition.description, selectedAttribute.positionSvgId, selectedAttributePosition.svgXml,
                    selectedAttribute.selectedLogoUrl);
            }
        } finally {
            //  console.log("Completed method: attachLogoToPosition");
        }
        return parentSvgXml;
    }

    private attachFontAndColourToPosition(parentSvgXml: string, selectedAttribute: any,
                                          selectedAttributePosition: any): string {
        // console.log("Calling method: attachFontAndColourToPosition");
        try {
            if (selectedAttribute.fontSvgId != undefined && selectedAttribute.fontSvgId != null) {
                // apply font to position
                parentSvgXml = this.attachFontToDesign(parentSvgXml, selectedAttributePosition.description, selectedAttribute.positionSvgId, selectedAttributePosition.svgXml,
                    selectedAttribute.fontSvgId, selectedAttribute.selectedFont);
            }

            if (selectedAttribute.hexValue != undefined && selectedAttribute.hexValue != null) {
                parentSvgXml = this.fillSvgWithColour(parentSvgXml, selectedAttribute.positionSvgId, selectedAttribute.hexValue);
            }
        } finally {
            //   console.log("Completed method: attachFontAndColourToPosition");
        }
        return parentSvgXml;
    }

    private changeView(view: string) {
        if (view == "FRONT") {
            $("#frontViewThumbnailId").trigger("click");
        } else if (view == "BACK") {
            $("#backViewThumbnailId").trigger("click");
        } else if (view == "LEFT") {
            $("#leftViewThumbnailId").trigger("click");
        } else if (view == "RIGHT") {
            $("#rightViewThumbnailId").trigger("click");
        }
    }

    public selectCustomerLogo(kukriKitDesignProduct: any, selectedLogoIndex: number, customerLogoIndex: number,
                              selectedLogos: Array<any>, customerLogos: Array<any>,
                              customerLogoPositions: Array<any>): void {
        //  console.log("Calling method: selectCustomerLogo");
        try {
            if (selectedLogos != undefined && selectedLogos != null && selectedLogos.length > 0) {
                if (customerLogos != undefined && customerLogos != null && customerLogos.length > 0) {
                    let selectedLogo = selectedLogos[selectedLogoIndex];
                    let selectedCustomerLogo = customerLogos[customerLogoIndex];

                    if (selectedLogo.positionSvgId == undefined || selectedLogo.positionSvgId == null) {
                        alert("Please select the position.");
                    } else {
                        selectedLogo.selectedLogoUrl = selectedCustomerLogo.logoUrl;
                        selectedLogo.selectedFontDescription = selectedCustomerLogo.description;

                        let selectedLogoPosition = this.findSelectedPosition(customerLogoPositions, selectedLogo.positionSvgId);

                        // Change font on SVG
                        let parentSvgXml: string;
                        if (selectedLogo.positionSvgId.indexOf("FRONT") >= 0) {
                            parentSvgXml = kukriKitDesignProduct.imageFront;
                            parentSvgXml = this.attachLogoToDesign(parentSvgXml, selectedLogoPosition.description, selectedLogo.positionSvgId, selectedLogoPosition.svgXml,
                                selectedLogo.selectedLogoUrl);
                            kukriKitDesignProduct.imageFront = parentSvgXml;
                        } else if (selectedLogo.positionSvgId.indexOf("BACK") >= 0) {
                            parentSvgXml = kukriKitDesignProduct.imageBack;
                            parentSvgXml = this.attachLogoToDesign(parentSvgXml, selectedLogoPosition.description, selectedLogo.positionSvgId, selectedLogoPosition.svgXml,
                                selectedLogo.selectedLogoUrl);
                            kukriKitDesignProduct.imageBack = parentSvgXml;
                        } else if (selectedLogo.positionSvgId.indexOf("LEFT") >= 0) {
                            parentSvgXml = kukriKitDesignProduct.imageLeft;
                            parentSvgXml = this.attachLogoToDesign(parentSvgXml, selectedLogoPosition.description, selectedLogo.positionSvgId, selectedLogoPosition.svgXml,
                                selectedLogo.selectedLogoUrl);
                            kukriKitDesignProduct.imageLeft = parentSvgXml;
                        } else if (selectedLogo.positionSvgId.indexOf("RIGHT") >= 0) {
                            parentSvgXml = kukriKitDesignProduct.imageRight;
                            parentSvgXml = this.attachLogoToDesign(parentSvgXml, selectedLogoPosition.description, selectedLogo.positionSvgId, selectedLogoPosition.svgXml,
                                selectedLogo.selectedLogoUrl);
                            kukriKitDesignProduct.imageRight = parentSvgXml;
                        }
                    }
                }
            }
        } finally {
            // console.log("Completed method: selectCustomerLogo");
        }
    }

    public selectNameNumberFont(kukriKitDesignProduct: any, selectedAttributeIndex: number, attributeFontIndex: number,
                                selectedAttributes: Array<any>, selectedAttributeFonts: Array<any>,
                                selectedAttributePositions: Array<any>): void {
        //  console.log("Calling method: selectNameNumberFont");
        try {
            if (selectedAttributes != undefined && selectedAttributes != null && selectedAttributes.length > 0) {
                if (selectedAttributeFonts != undefined && selectedAttributeFonts != null && selectedAttributeFonts.length > 0) {
                    let selectedAttribute = selectedAttributes[selectedAttributeIndex];
                    let selectedAttributeFont = selectedAttributeFonts[attributeFontIndex];

                    if (selectedAttribute.positionSvgId == undefined || selectedAttribute.positionSvgId == null) {
                        alert("Please select the position.");
                    } else {
                        selectedAttribute.fontSvgId = selectedAttributeFont.svgId;
                        selectedAttribute.selectedFont = selectedAttributeFont.svgXml;
                        selectedAttribute.selectedFontDescription = selectedAttributeFont.description;
                        let selectedAttributePosition = this.findSelectedPosition(selectedAttributePositions, selectedAttribute.positionSvgId);
                        // Change font on SVG
                        let parentSvgXml: string;

                        if (selectedAttribute.positionSvgId.indexOf("FRONT") >= 0) {
                            parentSvgXml = kukriKitDesignProduct.imageFront;
                            parentSvgXml = this.attachFontToDesign(
                                parentSvgXml, selectedAttributePosition.description, selectedAttribute.positionSvgId, selectedAttributePosition.svgXml, selectedAttribute.fontSvgId,
                                selectedAttribute.selectedFont);
                            kukriKitDesignProduct.imageFront = parentSvgXml;
                        } else if (selectedAttribute.positionSvgId.indexOf("BACK") >= 0) {
                            parentSvgXml = kukriKitDesignProduct.imageBack;
                            parentSvgXml = this.attachFontToDesign(
                                parentSvgXml, selectedAttributePosition.description, selectedAttribute.positionSvgId, selectedAttributePosition.svgXml, selectedAttribute.fontSvgId,
                                selectedAttribute.selectedFont);
                            kukriKitDesignProduct.imageBack = parentSvgXml;
                        } else if (selectedAttribute.positionSvgId.indexOf("LEFT") >= 0) {
                            parentSvgXml = kukriKitDesignProduct.imageLeft;
                            parentSvgXml = this.attachFontToDesign(parentSvgXml, selectedAttributePosition.description, selectedAttribute.positionSvgId, selectedAttributePosition.svgXml,
                                selectedAttribute.fontSvgId, selectedAttribute.selectedFont);
                            kukriKitDesignProduct.imageLeft = parentSvgXml;
                        } else if (selectedAttribute.positionSvgId.indexOf("RIGHT") >= 0) {
                            parentSvgXml = kukriKitDesignProduct.imageRight;
                            parentSvgXml = this.attachFontToDesign(parentSvgXml, selectedAttributePosition.description, selectedAttribute.positionSvgId, selectedAttributePosition.svgXml,
                                selectedAttribute.fontSvgId, selectedAttribute.selectedFont);
                            kukriKitDesignProduct.imageRight = parentSvgXml;
                        }
                    }
                }
            }
        } finally {
            // console.log("Completed method: attachFontAndColourToPosition");
        }
    }

    public selectNameNumberColour(kukriKitDesignProduct: any, selectedAttributeIndex: number, colourIndex: number,
                                  selectedAttributes: Array<any>, selectedAttributeFonts: Array<any>): void {
        // console.log("Calling method: selectNameNumberColour");
        try {
            if (selectedAttributes != undefined && selectedAttributes != null && selectedAttributes.length > 0) {
                if (selectedAttributeFonts != undefined && selectedAttributeFonts != null && selectedAttributeFonts.length > 0) {
                    let selectedAttribute = selectedAttributes[selectedAttributeIndex];
                    let selectedAttributeColour = kukriKitDesignProduct.slotColours[colourIndex];

                    if (selectedAttribute.positionSvgId == undefined || selectedAttribute.positionSvgId == null) {
                        alert("Please select the position.");
                    } else {
                        selectedAttribute.hexValue = selectedAttributeColour.hexValue;
                        selectedAttribute.selectedColour = selectedAttributeColour.description;
                        // Change Colour of SVG
                        let parentSvgXml: string;

                        if (selectedAttribute.positionSvgId.indexOf("FRONT") >= 0) {
                            parentSvgXml = kukriKitDesignProduct.imageFront;
                            parentSvgXml = this.fillSvgWithColour(parentSvgXml, selectedAttribute.positionSvgId, selectedAttribute.hexValue);
                            kukriKitDesignProduct.imageFront = parentSvgXml;
                        } else if (selectedAttribute.positionSvgId.indexOf("BACK") >= 0) {
                            parentSvgXml = kukriKitDesignProduct.imageBack;
                            parentSvgXml = this.fillSvgWithColour(parentSvgXml, selectedAttribute.positionSvgId, selectedAttribute.hexValue);
                            kukriKitDesignProduct.imageBack = parentSvgXml;
                        } else if (selectedAttribute.positionSvgId.indexOf("LEFT") >= 0) {
                            parentSvgXml = kukriKitDesignProduct.imageLeft;
                            parentSvgXml = this.fillSvgWithColour(parentSvgXml, selectedAttribute.positionSvgId, selectedAttribute.hexValue);
                            kukriKitDesignProduct.imageLeft = parentSvgXml;
                        } else if (selectedAttribute.positionSvgId.indexOf("RIGHT") >= 0) {
                            parentSvgXml = kukriKitDesignProduct.imageRight;
                            parentSvgXml = this.fillSvgWithColour(parentSvgXml, selectedAttribute.positionSvgId, selectedAttribute.hexValue);
                            kukriKitDesignProduct.imageRight = parentSvgXml;
                        }
                    }
                }
            }
        } finally {
            // console.log("Completed method: selectNameNumberColour");
        }
    }

    public removeAttributeFromDesign(kukriKitDesignProduct: any, selectedAttributeIndex: number,
                                     selectedAttributes: Array<any>, selectedAttributePositions: Array<any>): void {
        //   console.log("Calling method: removeAttributeFromDesign");
        if (selectedAttributes != undefined && selectedAttributes != null && selectedAttributes.length > 0) {
            let selectedAttribute = selectedAttributes[selectedAttributeIndex];
            let positionSvgId = selectedAttribute.positionSvgId;

            this.resetKukrikitDesignSelectedAttribute(selectedAttribute, selectedAttributePositions);

            if (positionSvgId != undefined && positionSvgId != null) {
                // now remove attribute from Design/SVG
                let parentSvgXml: string;

                if (positionSvgId.indexOf("FRONT") >= 0) {
                    parentSvgXml = kukriKitDesignProduct.imageFront;
                    parentSvgXml = this.removeChildSvgFromParentSvg(parentSvgXml, positionSvgId);
                    kukriKitDesignProduct.imageFront = parentSvgXml;
                } else if (positionSvgId.indexOf("BACK") >= 0) {
                    parentSvgXml = kukriKitDesignProduct.imageBack;
                    parentSvgXml = this.removeChildSvgFromParentSvg(parentSvgXml, positionSvgId);
                    kukriKitDesignProduct.imageBack = parentSvgXml;
                } else if (positionSvgId.indexOf("LEFT") >= 0) {
                    parentSvgXml = kukriKitDesignProduct.imageLeft;
                    parentSvgXml = this.removeChildSvgFromParentSvg(parentSvgXml, positionSvgId);
                    kukriKitDesignProduct.imageLeft = parentSvgXml;
                } else if (positionSvgId.indexOf("RIGHT") >= 0) {
                    parentSvgXml = kukriKitDesignProduct.imageRight;
                    parentSvgXml = this.removeChildSvgFromParentSvg(parentSvgXml, positionSvgId);
                    kukriKitDesignProduct.imageRight = parentSvgXml;
                }
            }
        }
        //  console.log("Completed method: removeAttributeFromDesign");
    }

    public hideLogoSlots(kukriKitDesignProduct: any): void {
        // console.log("Calling method: hideLogoSlots");
        if (kukriKitDesignProduct.imageFront != undefined && kukriKitDesignProduct.imageFront != null) {
            kukriKitDesignProduct.imageFront = this.hideLogoTrimSlots(kukriKitDesignProduct.imageFront, kukriKitDesignProduct);
        }

        if (kukriKitDesignProduct.imageBack != undefined && kukriKitDesignProduct.imageBack != null) {
            kukriKitDesignProduct.imageBack = this.hideLogoTrimSlots(kukriKitDesignProduct.imageBack, kukriKitDesignProduct);
        }

        if (kukriKitDesignProduct.imageLeft != undefined && kukriKitDesignProduct.imageLeft != null) {
            kukriKitDesignProduct.imageLeft = this.hideLogoTrimSlots(kukriKitDesignProduct.imageLeft, kukriKitDesignProduct);
        }

        if (kukriKitDesignProduct.imageRight != undefined && kukriKitDesignProduct.imageRight != null) {
            kukriKitDesignProduct.imageRight = this.hideLogoTrimSlots(kukriKitDesignProduct.imageRight, kukriKitDesignProduct);
        }
        // console.log("Completed method: hideLogoSlots");
    }

    private hideLogoTrimSlots(svgXml: string, kukriKitDesignProduct: any): string {
        //    console.log("Calling method: hideLogoTrimSlots");
        let parentSvgDiv = $("<div/>").html(svgXml);

        parentSvgDiv.find('svg[id*="LOGO"]').each(function() {
            let logoSvgId = $(this).attr("id");
            let currentLogoElement = $(this);

            if (kukriKitDesignProduct.logoPositions != undefined && kukriKitDesignProduct.logoPositions != null && kukriKitDesignProduct.logoPositions.length > 0) {
                for (let logoPosition of kukriKitDesignProduct.logoPositions) {
                    if (logoPosition.description != undefined && logoPosition.description != null && logoPosition.svgId == logoSvgId &&
                        logoPosition.description.toUpperCase().indexOf("TRIM") !== -1) {
                        currentLogoElement.hide();
                    }
                }
            }
        });
        //   console.log("Completed method: hideLogoTrimSlots");
        return parentSvgDiv.html();
    }

    public filterKukriKitDesignProduct(kukriKitDesignProduct: any): void {
        // console.log("Calling method: filterKukriKitDesignProduct");

        // prepare/filter data before sending to server side.
        this.removeUnneccassarySlotsFromWholeDesign(kukriKitDesignProduct, null, null);
        this.resetAllSelectedAttributes(kukriKitDesignProduct);
        this.hideLogoSlots(kukriKitDesignProduct);
        // remove unnessacary namespaces
        kukriKitDesignProduct.imageFront = this.filterNameSpaces(kukriKitDesignProduct.imageFront);
        kukriKitDesignProduct.imageBack = this.filterNameSpaces(kukriKitDesignProduct.imageBack);
        kukriKitDesignProduct.imageLeft = this.filterNameSpaces(kukriKitDesignProduct.imageLeft);
        kukriKitDesignProduct.imageRight = this.filterNameSpaces(kukriKitDesignProduct.imageRight);
        // adjust Logo HRef
        kukriKitDesignProduct.imageFront = this.adjustLogoHref(kukriKitDesignProduct.imageFront);
        kukriKitDesignProduct.imageBack = this.adjustLogoHref(kukriKitDesignProduct.imageBack);
        kukriKitDesignProduct.imageLeft = this.adjustLogoHref(kukriKitDesignProduct.imageLeft);
        kukriKitDesignProduct.imageRight = this.adjustLogoHref(kukriKitDesignProduct.imageRight);
        // remove duplicate attributes from logo image
        kukriKitDesignProduct.imageFront = this.removeDuplicateAttributeInLogoImage(kukriKitDesignProduct.imageFront);
        kukriKitDesignProduct.imageBack = this.removeDuplicateAttributeInLogoImage(kukriKitDesignProduct.imageBack);
        kukriKitDesignProduct.imageLeft = this.removeDuplicateAttributeInLogoImage(kukriKitDesignProduct.imageLeft);
        kukriKitDesignProduct.imageRight = this.removeDuplicateAttributeInLogoImage(kukriKitDesignProduct.imageRight);

        this.makeRecentUploadedLogoUrlCompatible(kukriKitDesignProduct);
        // adjust SVG height and Width
        kukriKitDesignProduct.imageFront = this.setSvgWidthHeight(kukriKitDesignProduct.imageFront, 500, 500);
        kukriKitDesignProduct.imageBack = this.setSvgWidthHeight(kukriKitDesignProduct.imageBack, 500, 500);

        // console.log("Completed method: filterKukriKitDesignProduct");
    }

    private makeRecentUploadedLogoUrlCompatible(kukriKitDesignProduct: any): void {
        if (kukriKitDesignProduct.imageFront != undefined && kukriKitDesignProduct.imageFront != null) {
            kukriKitDesignProduct.imageFront = kukriKitDesignProduct.imageFront.replace("/teamshop", "");
        }

        if (kukriKitDesignProduct.imageBack != undefined && kukriKitDesignProduct.imageBack != null) {
            kukriKitDesignProduct.imageBack = kukriKitDesignProduct.imageBack.replace("/teamshop", "");
        }

        if (kukriKitDesignProduct.imageLeft != undefined && kukriKitDesignProduct.imageLeft != null) {
            kukriKitDesignProduct.imageLeft = kukriKitDesignProduct.imageLeft.replace("/teamshop", "");
        }

        if (kukriKitDesignProduct.imageRight != undefined && kukriKitDesignProduct.imageRight != null) {
            kukriKitDesignProduct.imageRight = kukriKitDesignProduct.imageRight.replace("/teamshop", "");
        }

        if (kukriKitDesignProduct.selectedLogos != undefined && kukriKitDesignProduct.selectedLogos != null && kukriKitDesignProduct.selectedLogos.length > 0) {
            for (let selectedLogo of kukriKitDesignProduct.selectedLogos) {
                if (selectedLogo.selectedLogoUrl != undefined && selectedLogo.selectedLogoUrl != null && selectedLogo.selectedLogoUrl.indexOf("/teamshop") !== -1) {
                    selectedLogo.selectedLogoUrl = selectedLogo.selectedLogoUrl.replace("/teamshop", "");
                }
            }
        }
    }

    public setSvgWidthHeight(svgXml: string, width: number, height: number): string {
        // console.log("Calling method: setSvgWidthHeight");

        let parentSvgDiv = $("<div/>").html(svgXml);

        parentSvgDiv.find("svg").first().attr("width", width);
        parentSvgDiv.find("svg").first().attr("height", height);

        // console.log("Completed method: setSvgWidthHeight");
        return parentSvgDiv.html();
    }

    private filterNameSpaces(svgXml: string): string {
        // console.log("Calling method: filterNameSpaces");

        if ($.support.msie || !!navigator.userAgent.match(/Trident\/7\./)) {
            if (svgXml != null && svgXml != undefined) {
                svgXml = svgXml.replace(/NS\d+:xmlns:xlink/g, "xmlns:xlink");
                svgXml = svgXml.replace(/NS\d+:xlink:/g, "xlink:");
                svgXml = svgXml.replace(/NS\d+:/g, "xlink:");
                svgXml = svgXml.replace(/\S*NS\d+="\S*/g, "");
            }
        }

        // console.log("Completed method: filterNameSpaces");
        return svgXml;
    }

    private adjustLogoHref(svgXml: string): string {
        //   console.log("Calling method: adjustLogoHref");

        try {
            if (svgXml != undefined && svgXml != null) {
                let parentSvgDiv = $("<div/>").html(svgXml);

                parentSvgDiv.find("svg").find("*").each(function() {
                    if ($(this).attr("onclick")) {
                        $(this).removeAttr("onclick");
                    }

                    if ($(this).attr("onload")) {
                        $(this).removeAttr("onload");
                    }
                });

                if ($.support.msie || !!navigator.userAgent.match(/Trident\/7\./)) {
                    parentSvgDiv.find("image").each(function() {
                        if (this.attributes.getNamedItem("href")) {
                            $(this).attr("xlink:href", $(this).attr("href"));
                            this.attributes.removeNamedItem("href");
                        }
                    });
                } else if ($.support.safari) {
                    parentSvgDiv.find("image").each(function() {
                        if ($(this).attr("href")) {
                            $(this).attr("xlink:href", $(this).attr("href"));
                            $(this).removeAttr("href");
                        } else {
                            var a = $(this).attr("xlink:href");
                            $(this).removeAttr("xlink:href");
                            $(this).attr("xlink:href", a);
                        }
                    });
                }
                return parentSvgDiv.html();
            }
        } finally {
            //  console.log("Completed method: adjustLogoHref");
        }

        return null;
    }

    public initialiseSelectedSlots(kukriKitDesignProduct: any): void {
        // console.log("Calling method: initialiseSelectedSlots");

        if (kukriKitDesignProduct != undefined && kukriKitDesignProduct != null) {
            initialiseProductSelectedSlots(kukriKitDesignProduct, this.findSelectedPosition);
        }

        // console.log("Completed method: initialiseSelectedSlots");
        function initialiseProductSelectedSlots(kukriKitDesignProduct: any,findSelectedPosition: any) {
            const product = kukriKitDesignProduct;
            let selectTypes = ['NAME', 'NUMBER', 'LOGO'];
            let positions = ['namePositions', 'numberPositions', 'logoPositions'];
            let selectedTypes = ['selectedNames', 'selectedNumbers', 'selectedLogos'];
            let fonts = ['nameFonts', 'numberFonts', ''];

            // @ts-ignore
            $.each(selectedTypes, (i, selectType) => {
                let elementsFound: any[] = [];
                const positionType = product[positions[i]];
                const selectedType = product[selectedTypes[i]];

                if (selectedType && selectedType.length > 0) {
                    buildMissingSelected(product, positionType, selectTypes[i], elementsFound, product[fonts[i]]);

                    // @ts-ignore
                    let alreadySelected = selectedType.filter(item => {return item.positionSvgId ? true : false;})

                    if (selectTypes[i] != "LOGO" && elementsFound.length > 0) {
                        const selectedFonts = product[fonts[i]] ? product[fonts[i]] : product[fonts[i]] = [];
                        const emptySelectedFonts = selectedFonts.length == 0;

                        // @ts-ignore
                        $.each(elementsFound, (ii, value) => {
                            if (value.selectedLogoUrl) {
                                selectedFonts.push(value.selectedLogoUrl);
                            }
                        });
                    }

                    // check which have been done
                    if (alreadySelected.length != elementsFound.length) {
                        // @ts-ignore
                        $.each(elementsFound, (iii, value) => {

                            const selectedTypeEntry = selectedType[iii];
                            selectedTypeEntry.positionSvgId = value.positionSvgId;
                            selectedTypeEntry.selectedPosition = value.description;
                            selectedTypeEntry.displayOnPage = true;
                            selectedTypeEntry.charge = value.charge;

                            if (selectTypes[i] == "LOGO") {
                                selectedTypeEntry.selectedLogoUrl = value.selectedLogoUrl;
                                selectedTypeEntry.selectedFontDescription = 'Missing Customer Logo';
                            } else {
                                selectedTypeEntry.selectedFontDescription = value.description;
                                selectedTypeEntry.nameType = value.nameType;
                            }
                        });
                    }

                    for (const selected of selectedType) {
                        if (selected.positionSvgId) {
                            const productImageSide = selected.positionSvgId.indexOf("FRONT") >= 0 ? product.imageFront :
                                selected.positionSvgId.indexOf("BACK") >= 0 ? product.imageBack :
                                    selected.positionSvgId.indexOf("LEFT") >= 0 ? product.imageLeft : product.imageRight;
                            const positionSvgId = selected.positionSvgId;

                            // callBacks[i](productImageSide, selected.positionSvgId, product, selected, findSelectedPosition);

                            if (productImageSide) {

                                const selectedFonts = product[fonts[i]] ? product[fonts[i]] : product[fonts[i]] = [];
                                const parentSvgDiv = $("<div/>").html(productImageSide);
                                let positionSvgIdParts = positionSvgId.split('_');
                                let positionSvgs: any, idForPositionSvg, selectedPosition;

                                if (selectTypes[i] == "LOGO") {
                                    if (product.customerLogos && product.customerLogos.length > 0) {
                                        let imageHref = parentSvgDiv.find("svg[id='" + positionSvgId + "']").find("image").first().attr("href");

                                        if (imageHref === undefined) {

                                            // @ts-ignore
                                            positionSvgs = $($(parentSvgDiv.children("svg")).children("svg").filter((i, childSvg) => {
                                                let myID = $(childSvg).attr('id');
                                                return myID.indexOf(positionSvgIdParts[0]) !== -1 && myID.indexOf(positionSvgIdParts[2]) !== -1 && myID.indexOf(positionSvgIdParts[3]) !== -1;
                                            }));

                                            // @ts-ignore
                                            $.each(positionSvgs.find("image").first()[0].attributes, (ii, attr) => {
                                                if (attr.nodeName.toLowerCase().indexOf('href') !== -1) {
                                                    imageHref = attr.nodeValue;
                                                    idForPositionSvg = positionSvgs.attr("id");
                                                }
                                            });
                                        }

                                        for (let customerLogo of product.customerLogos) {

                                            if (imageHref && customerLogo.logoUrl == imageHref) {
                                                selected.selectedLogoUrl = customerLogo.logoUrl;
                                                selected.selectedFontDescription = customerLogo.description;
                                                selectedPosition = findSelectedPosition(positionType, positionSvgId);

                                                if (selectedPosition != null) {
                                                    selected.selectedPosition = selectedPosition.description;
                                                    selected.charge = selectedPosition.charge;
                                                }
                                                break;
                                            }
                                        }
                                    }
                                } else {
                                    // select appropriate font along with svg xml
                                    if (selectedFonts && selectedFonts.length > 0) {
                                        let firstPathId = parentSvgDiv.find("svg[id='" + positionSvgId + "']").find("path").first().attr("id");

                                        if (firstPathId == undefined) {

                                            // @ts-ignore
                                            positionSvgs = $($(parentSvgDiv.children("svg")).children("svg").filter((i, childSvg) => {
                                                let myID = $(childSvg).attr('id');
                                                return myID.indexOf(positionSvgIdParts[0]) !== -1 && myID.indexOf(positionSvgIdParts[2]) !== -1 && myID.indexOf(positionSvgIdParts[3]) !== -1;
                                            }));

                                            idForPositionSvg = positionSvgs.attr("id");
                                            firstPathId = positionSvgs.find("path").first().attr("id");
                                        }

                                        let fontAttributeId: number = parseInt(firstPathId.split("_")[0]);

                                        for (const font of selectedFonts) {
                                            if (font.attributeId == fontAttributeId) {
                                                selected.fontSvgId = font.svgId;
                                                selected.selectedFont = font.svgXml;
                                                selected.selectedFontDescription = font.description;
                                                break;
                                            }
                                        }
                                    }

                                    selectedPosition = findSelectedPosition(positionType, positionSvgId);

                                    if (selectedPosition != null) {
                                        selected.selectedPosition = selectedPosition.description;
                                        selected.charge = selectedPosition.charge;
                                        selected.nameType = selectedPosition.nameType;
                                    }

                                    // select the Font colour
                                    // parentSvgDiv = $("<div/>").html(productImageSide);
                                    let firstColourHexValue = parentSvgDiv.find("svg[id='" + positionSvgId + "']").find("*[fill]").first().attr("fill");

                                    if (firstColourHexValue) {
                                        if (product.slotColours && product.slotColours.length > 0) {
                                            for (let slotColour of product.slotColours) {
                                                if (slotColour.hexValue == firstColourHexValue) {
                                                    selected.hexValue = slotColour.hexValue;
                                                    selected.selectedColour = slotColour.description;
                                                    break;
                                                }
                                            }
                                        }
                                    }

                                    if (firstColourHexValue && !selected.hexValue) {
                                        selected.hexValue = firstColourHexValue
                                        selected.selectedColour = ' Deprecated ' + firstColourHexValue
                                    }
                                }

                                let isMissing = true;
                                // @ts-ignore
                                alreadySelected.forEach(item => {
                                    if (item.positionSvgId == selected.positionSvgId) {
                                        isMissing = false;
                                    }
                                })

                                if (alreadySelected == 0 || isMissing) {

                                    if (idForPositionSvg && selected.positionSvgId !== idForPositionSvg) {
                                        parentSvgDiv.html(parentSvgDiv.html().replace(new RegExp(idForPositionSvg, "g"), selected.positionSvgId));
                                    }

                                    if (selected.positionSvgId.indexOf("FRONT") >= 0) {
                                        product.imageFront = parentSvgDiv.html();
                                    } else if (selected.positionSvgId.indexOf("BACK") >= 0) {
                                        product.imageBack = parentSvgDiv.html();
                                    } else if (selected.positionSvgId.indexOf("LEFT") >= 0) {
                                        product.imageLeft = parentSvgDiv.html();
                                    } else if (selected.positionSvgId.indexOf("RIGHT") >= 0) {
                                        product.imageRight = parentSvgDiv.html();
                                    }
                                }
                                // console.log(selectType, selected);
                            }
                            //  console.log("Completed method: initialiseSelectedName");
                        }
                    }
                }
            });
        }

        function buildMissingSelected(kukriKitDesignProduct: any,  positions: any[], selectType: string, selects: any[], fonts: any[]) {
            ['imageFront', 'imageBack'].forEach(imageSide => {

                const backFront = imageSide == 'imageFront' ? 'FRONT' : 'BACK';
                const productSide = $("<div/>").html(kukriKitDesignProduct[imageSide]);

                // @ts-ignore
                productSide.find('svg[id*="'+selectType+'"]').each((i, item) => {
                    let svgFont = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" contentscripttype="text/ecmascript" width="100%" zoomAndPan="magnify" ' +
                        'contentstyletype="text/css" id="'
                    let svgFontEnd = '" viewBox="0 0 118.44 85.65" height="100%" preserveAspectRatio="xMidYMid meet" version="1.1"><defs></defs><g id="Layer_1" data-name="Layer 1">';
                    let itemHref: any = null;
                    let itemTitle: string | number = $(item).attr('title');
                    let itemCharge = 0;
                    let attributes = selectType == "LOGO" ? $(item).find("image").first()[0].attributes :
                        $(item).find('[id*="'+selectType+'"]').first();

                    // @ts-ignore
                    $.each(attributes, (ii, attr) => {
                        if (attr) {
                            if (attr.nodeName && attr.nodeName.toLowerCase().indexOf('href') !== -1) {
                                itemHref = attr.nodeValue;
                            } else {

                                if (attr.id && typeof attr.id == 'string' && attr.id !== 'Layer_1') {
                                    let foundAttributeId = Number(attr.id.substring(0, attr.id.indexOf('_')));
                                    let missingFont = fonts ? false : true;

                                    if (fonts) {
                                        missingFont = true;
                                        fonts.forEach(font => {
                                            if (font.attributeId == foundAttributeId) {
                                                missingFont = false;
                                            }
                                        });
                                    }

                                    if (missingFont) {
                                        itemHref = {
                                            attributeApplicationType: null,
                                            attributeId: foundAttributeId,
                                            charge: null,
                                            class: "com.kukri.core.domain.value.KukriKitDesign" + (selectType == "NUMBER" ? "Number" : "Name"),
                                            description: "Missing " + itemTitle + " Font",
                                            kukriKitDesignAttributeType: "FONT",
                                            selected: false,
                                            svgId: attr.id.substring(0, attr.id.indexOf(backFront) + backFront.length),
                                        };
                                    }
                                }

                                if (itemHref && typeof itemHref != 'string') {
                                    svgFont += itemHref.svgId + svgFontEnd + $(item).find('g').html();
                                    itemHref.svgXml = svgFont + '</g></svg>';
                                    positions.forEach(position => {
                                        if (attr.id.startsWith(position.attributeId.toString())) {
                                                itemHref.nameType = position.nameType;
                                        }
                                    });
                                }
                            }
                        }
                    });

                    // @ts-ignore
                    positions.forEach(position => {
                        if (item.id.startsWith(position.attributeId.toString())) {
                            itemTitle = position.description + (itemTitle  ? '  Missing ' +
                                (position.description != itemTitle ? itemTitle  + ' Logo' : '') : ' :  Missing Logo');
                            itemCharge += position.charge;
                        }
                        return true;
                    });

                    selects.push({positionSvgId: item.id, description: itemTitle, selectedLogoUrl: itemHref, charge: itemCharge});
                });
            });
            // console.log(selectType, selects)
        }
    }


    public removeDuplicateAttributeInLogoImage(svgXml: string): string {
        //  console.log("Calling method: removeDuplicateAttributeInLogoImage");
        let parentSvgDiv = $("<div/>").html(svgXml);
        $(parentSvgDiv).find("image").each(function() {
            let imageObj = $(this);
            let attributeMap = {};
            $.each(this.attributes, function() {
                if (this.specified) {
                    let key = this.name;
                    if (attributeMap != undefined && attributeMap != null && attributeMap[key] == undefined) {
                        attributeMap[key] = true;
                    } else {
                        //delete duplicate
                        $(imageObj).removeAttr(key);
                    }
                }
            });

            /*if(this.attributes.getNamedItem('xmlns:xlink')){
             this.attributes.removeNamedItem('xmlns:xlink');
            }*/
        });

        //  console.log("Completed method: removeDuplicateAttributeInLogoImage");
        return parentSvgDiv.html();
    }

    public getStartingXYPosition(kitDesignProduct: any, logoSvgXml: string, type: string, selectedPosition?: string) {
        let position = {x: "", y: ""};

        if (logoSvgXml != "") {
            let x: string = logoSvgXml.match(/x=\"\d*\.\d*\"/)[0]
            let y: string = logoSvgXml.match(/y=\"\d*\.\d*\"/)[0]
            position["x"] = x.replace(/[\"x=]/g, "");
            position["y"] = y.replace(/[\"y=]/g, "");
        }

        if (selectedPosition) {
            let positions = type === 'logos' ? kitDesignProduct.logoPositions :
                type === 'names' ? kitDesignProduct.namePositions : kitDesignProduct.numberPositions;

            // @ts-ignore
            positions.forEach(logoPosition => {
                if (logoPosition.svgId === selectedPosition ||
                    logoPosition.svgId.split('_')[0] === selectedPosition.split('_')[0]) {
                    let x: string = logoPosition.svgXml.match(/x=\"\d*\.\d*\"/)[0]
                    let y: string = logoPosition.svgXml.match(/y=\"\d*\.\d*\"/)[0]

                    position["x"] = x.replace(/[\"x=]/g, "");
                    position["y"] = y.replace(/[\"y=]/g, "");
                }
            });
        }

        return position;
    }

    private noMovementValidationRequired(movementDirection: any) {
        return [PositionMovementEnum.CLOCK_WISE, PositionMovementEnum.ANTI_CLOCK_WISE,
            PositionMovementEnum.RESET].indexOf(movementDirection) !== -1;
    }

    public setInitialMovementMap(startingPosition: any, currentYCoord: any, currentXCoord: any, positionSvgId: any,
                                 movementMap: any) {

        if (isMatched(currentYCoord, startingPosition.y,20, false)) {
            updateMovementMap(PositionMovementEnum.UP, PositionMovementEnum.DOWN, 4, false);
        } else if (isMatched(currentYCoord, startingPosition.y,17.5, false)) {
            updateMovementMap(PositionMovementEnum.UP, PositionMovementEnum.DOWN, 3.5, false);
        } else if (isMatched(currentYCoord, startingPosition.y,15, false)) {
            updateMovementMap(PositionMovementEnum.UP, PositionMovementEnum.DOWN, 3, false);
        } else if (isMatched(currentYCoord, startingPosition.y,12.5, false)) {
            updateMovementMap(PositionMovementEnum.UP, PositionMovementEnum.DOWN, 2.5, false);
        } else if (isMatched(currentYCoord, startingPosition.y,10, false)) {
            updateMovementMap(PositionMovementEnum.UP, PositionMovementEnum.DOWN, 2, false);
        } else if (isMatched(currentYCoord, startingPosition.y,7.5, false)) {
            updateMovementMap(PositionMovementEnum.UP, PositionMovementEnum.DOWN, 1.5, false);
        } else if (isMatched(currentYCoord, startingPosition.y,5, false)) {
            updateMovementMap(PositionMovementEnum.UP, PositionMovementEnum.DOWN, 1, false);
        } else if (isMatched(currentYCoord, startingPosition.y,2.5, false)) {
            updateMovementMap(PositionMovementEnum.UP, PositionMovementEnum.DOWN, 0.5, false);
        } else if (isMatched(currentYCoord, startingPosition.y,2.5, true)) {
            updateMovementMap(PositionMovementEnum.UP, PositionMovementEnum.DOWN, 0.5, true);
        } else if (isMatched(currentYCoord, startingPosition.y,5, true)) {
            updateMovementMap(PositionMovementEnum.UP, PositionMovementEnum.DOWN, 1, true);
        } else if (isMatched(currentYCoord, startingPosition.y,7.5, true)) {
            updateMovementMap(PositionMovementEnum.UP, PositionMovementEnum.DOWN, 1.5, true);
        } else if (isMatched(currentYCoord, startingPosition.y,10, true)) {
            updateMovementMap(PositionMovementEnum.UP, PositionMovementEnum.DOWN, 2, true);
        } else if (isMatched(currentYCoord, startingPosition.y,12.5, true)) {
            updateMovementMap(PositionMovementEnum.UP, PositionMovementEnum.DOWN, 2.5, true);
        } else if (isMatched(currentYCoord, startingPosition.y,15, true)) {
            updateMovementMap(PositionMovementEnum.UP, PositionMovementEnum.DOWN, 3, true);
        } else if (isMatched(currentYCoord, startingPosition.y,17.5, true)) {
            updateMovementMap(PositionMovementEnum.UP, PositionMovementEnum.DOWN, 3.5, true);
        } else if (isMatched(currentYCoord, startingPosition.y,20, true)) {
            updateMovementMap(PositionMovementEnum.UP, PositionMovementEnum.DOWN, 4, true);
        } else {
            updateMovementMap(PositionMovementEnum.UP, PositionMovementEnum.DOWN, 0, true);
        }

        if (isMatched(currentXCoord,startingPosition.x,20, false)) {
            updateMovementMap(PositionMovementEnum.LEFT, PositionMovementEnum.RIGHT, 4, false);
        } else if (isMatched(currentXCoord, startingPosition.x,17.5, false)) {
            updateMovementMap(PositionMovementEnum.LEFT, PositionMovementEnum.RIGHT, 3.5, false);
        } else if (isMatched(currentXCoord, startingPosition.x,15, false)) {
            updateMovementMap(PositionMovementEnum.LEFT, PositionMovementEnum.RIGHT, 3, false);
        } else if (isMatched(currentXCoord, startingPosition.x,12.5, false)) {
            updateMovementMap(PositionMovementEnum.LEFT, PositionMovementEnum.RIGHT, 2.5, false);
        } else if (isMatched(currentXCoord, startingPosition.x,10, false)) {
            updateMovementMap(PositionMovementEnum.LEFT, PositionMovementEnum.RIGHT, 2, false);
        } else if (isMatched(currentXCoord, startingPosition.x,7.5, false)) {
            updateMovementMap(PositionMovementEnum.LEFT, PositionMovementEnum.RIGHT, 1.5, false);
        } else if (isMatched(currentXCoord, startingPosition.x,5, false)) {
            updateMovementMap(PositionMovementEnum.LEFT, PositionMovementEnum.RIGHT, 1, false);
        } else if (isMatched(currentXCoord, startingPosition.x,2.5, false)) {
            updateMovementMap(PositionMovementEnum.LEFT, PositionMovementEnum.RIGHT, 0.5, false);
        } else if (isMatched(currentXCoord, startingPosition.x,2.5, true)) {
            updateMovementMap(PositionMovementEnum.LEFT, PositionMovementEnum.RIGHT, 0.5, true);
        } else if (isMatched(currentXCoord, startingPosition.x,5, true)) {
            updateMovementMap(PositionMovementEnum.LEFT, PositionMovementEnum.RIGHT, 1, true);
        } else if (isMatched(currentXCoord, startingPosition.x,7.5, true)) {
            updateMovementMap(PositionMovementEnum.LEFT, PositionMovementEnum.RIGHT, 1.5, true);
        } else if (isMatched(currentXCoord, startingPosition.x,10, true)) {
            updateMovementMap(PositionMovementEnum.LEFT, PositionMovementEnum.RIGHT, 2, true);
        } else if (isMatched(currentXCoord, startingPosition.x,12.5, true)) {
            updateMovementMap(PositionMovementEnum.LEFT, PositionMovementEnum.RIGHT, 2.5, true);
        } else if (isMatched(currentXCoord, startingPosition.x,15, true)) {
            updateMovementMap(PositionMovementEnum.LEFT, PositionMovementEnum.RIGHT, 3, true);
        } else if (isMatched(currentXCoord, startingPosition.x,17.5, true)) {
            updateMovementMap(PositionMovementEnum.LEFT, PositionMovementEnum.RIGHT, 3.5, true);
        } else if (isMatched(currentXCoord, startingPosition.x,20, true)) {
            updateMovementMap(PositionMovementEnum.LEFT, PositionMovementEnum.RIGHT, 4, true);
        } else {
            updateMovementMap(PositionMovementEnum.LEFT, PositionMovementEnum.RIGHT, 0, true);
        }

        function isMatched(currentXY: number, xy: string, limit: number, addition: boolean) {
            return (addition ?
                    parseInt(currentXY.toString()) === (parseInt(xy) + limit) || parseFloat(currentXY.toString()) === (parseFloat(xy) + limit) :
                    parseInt(currentXY.toString()) === (parseInt(xy) - limit) || parseFloat(currentXY.toString())  === (parseFloat(xy) - limit)
            );
        }

        function updateMovementMap(direction1: PositionMovementEnum, direction2: PositionMovementEnum, increment: number, reverse: boolean) {
            if (increment === 0) {
                movementMap.set(positionSvgId + PositionMovementEnum[direction1], 0);
                movementMap.set(positionSvgId + PositionMovementEnum[direction2], 0);
            } else {

                if (reverse) {
                    movementMap.set(positionSvgId + PositionMovementEnum[direction1], -increment);
                    movementMap.set(positionSvgId + PositionMovementEnum[direction2], increment);
                } else {
                    movementMap.set(positionSvgId + PositionMovementEnum[direction1], increment);
                    movementMap.set(positionSvgId + PositionMovementEnum[direction2], -increment);
                }
            }
        }
    }

    public attributePositionMovement(kukriKitDesignProduct: any, positionSvgId: string, displacementAdjuster: number,
                                     movementMap: Map<string, number>,
                                     positionMovement: PositionMovementEnum, type: string,
                                     alternative: boolean): void {
        let parentSvgXml: string;

        if (positionSvgId.indexOf("FRONT") >= 0) {
            parentSvgXml = kukriKitDesignProduct.imageFront;
        } else if (positionSvgId.indexOf("BACK") >= 0) {
            parentSvgXml = kukriKitDesignProduct.imageBack;
        } else if (positionSvgId.indexOf("LEFT") >= 0) {
            parentSvgXml = kukriKitDesignProduct.imageLeft;
        } else if (positionSvgId.indexOf("RIGHT") >= 0) {
            parentSvgXml = kukriKitDesignProduct.imageRight;
        }

        let parentSvgDiv = $("<div/>").html(parentSvgXml);
        let mapKey: string = positionSvgId + PositionMovementEnum[positionMovement];

        if (!movementMap.has(mapKey) && !this.noMovementValidationRequired(positionMovement)) {
            movementMap.set(mapKey, 0);
        }

        let adjuster = 0.5, displacement = displacementAdjuster/2;
        let currentDisplacement = movementMap.get(mapKey);
        let intValue = !currentDisplacement || currentDisplacement === parseInt(currentDisplacement.toString());
        let startingPosition = this.getStartingXYPosition(kukriKitDesignProduct, "", type, positionSvgId);
        let attrY = parentSvgDiv.find("svg[id=" + positionSvgId + "]").first().attr("y");
        let attrX = parentSvgDiv.find("svg[id=" + positionSvgId + "]").first().attr("x");
        let currentYCoord =  intValue ? parseInt(attrY) : parseFloat(attrY);
        let currentXCoord = intValue ? parseInt(attrX) : parseFloat(attrX);

        if (!movementMap.has(positionSvgId + PositionMovementEnum[PositionMovementEnum.UP])) {
            this.setInitialMovementMap(startingPosition, currentYCoord, currentXCoord, positionSvgId, movementMap);
        }

        if (this.noMovementValidationRequired(positionMovement) || currentDisplacement < 4) {
            let direction = [PositionMovementEnum.UP, PositionMovementEnum.DOWN].indexOf(positionMovement) !== -1 ? "y" : "x";
            let adjustment = intValue ? parseInt(parentSvgDiv.find("svg[id=" + positionSvgId + "]").first().attr(direction)) :
                parseFloat(parentSvgDiv.find("svg[id=" + positionSvgId + "]").first().attr(direction));

            switch (positionMovement) {
                case PositionMovementEnum.UP:
                {
                    parentSvgDiv.find("svg[id=" + positionSvgId + "]").first().attr("y", adjustment - displacement);
                    movementMap.set(mapKey, movementMap.get(mapKey) + adjuster); // increment the counter
                    let oppositeMapKey: string = positionSvgId + PositionMovementEnum[PositionMovementEnum.DOWN];
                    if (!movementMap.has(oppositeMapKey)) {
                        movementMap.set(oppositeMapKey, 0);
                    }
                    movementMap.set(oppositeMapKey, movementMap.get(oppositeMapKey) - adjuster); // decrement opposite counter
                }
                    break;
                case PositionMovementEnum.DOWN:
                {
                    parentSvgDiv.find("svg[id=" + positionSvgId + "]").first().attr("y", adjustment + displacement);
                    movementMap.set(mapKey, movementMap.get(mapKey) + adjuster); // increment the counter
                    let oppositeMapKey: string = positionSvgId + PositionMovementEnum[PositionMovementEnum.UP];
                    if (!movementMap.has(oppositeMapKey)) {
                        movementMap.set(oppositeMapKey, 0);
                    }
                    movementMap.set(oppositeMapKey, movementMap.get(oppositeMapKey) - adjuster); // decrement opposite counter
                }
                    break;
                case PositionMovementEnum.RIGHT:
                {
                    parentSvgDiv.find("svg[id=" + positionSvgId + "]").first().attr("x", adjustment + displacement);
                    movementMap.set(mapKey, movementMap.get(mapKey) + adjuster); // increment the counter
                    let oppositeMapKey: string = positionSvgId + PositionMovementEnum[PositionMovementEnum.LEFT];
                    if (!movementMap.has(oppositeMapKey)) {
                        movementMap.set(oppositeMapKey, 0);
                    }
                    movementMap.set(oppositeMapKey, movementMap.get(oppositeMapKey) - adjuster); // decrement opposite counter
                }
                    break;
                case PositionMovementEnum.LEFT:
                {
                    parentSvgDiv.find("svg[id=" + positionSvgId + "]").first().attr("x", adjustment - displacement);
                    movementMap.set(mapKey, movementMap.get(mapKey) + adjuster); // increment the counter
                    let oppositeMapKey: string = positionSvgId + PositionMovementEnum[PositionMovementEnum.RIGHT];
                    if (!movementMap.has(oppositeMapKey)) {
                        movementMap.set(oppositeMapKey, 0);
                    }
                    movementMap.set(oppositeMapKey, movementMap.get(oppositeMapKey) - adjuster); // decrement opposite counter
                }
                    break;
                case PositionMovementEnum.CLOCK_WISE:
                case PositionMovementEnum.ANTI_CLOCK_WISE:
                    let increment = positionMovement == PositionMovementEnum.CLOCK_WISE
                    this.rotateLogoNameNumber(parentSvgDiv, positionSvgId, increment);
                    break;
                case PositionMovementEnum.RESET:
                    parentSvgDiv.find("svg[id=" + positionSvgId + "]").first().attr("y", startingPosition.y);
                    parentSvgDiv.find("svg[id=" + positionSvgId + "]").first().attr("x", startingPosition.x);
                    parentSvgDiv.find("svg[id=" + positionSvgId + "]").first().find('g').removeAttr('transform')
                    movementMap.set(positionSvgId + PositionMovementEnum[PositionMovementEnum.UP], 0);
                    movementMap.set(positionSvgId + PositionMovementEnum[PositionMovementEnum.DOWN], 0);
                    movementMap.set(positionSvgId + PositionMovementEnum[PositionMovementEnum.LEFT], 0);
                    movementMap.set(positionSvgId + PositionMovementEnum[PositionMovementEnum.RIGHT], 0);

                    if (type == "logos") {
                        let viewBoxArgs = parentSvgDiv.find("svg[id=" + positionSvgId + "]").first().attr('viewBox').split(" ");

                        if (parseInt(parentSvgDiv.find("svg[id=" + positionSvgId + "]").first().attr("width")) !== parseInt(viewBoxArgs[2])) {
                            parentSvgDiv.find("svg[id=" + positionSvgId + "]").first().attr("width", parseInt(viewBoxArgs[2]));
                        }

                        if (parseInt(parentSvgDiv.find("svg[id=" + positionSvgId + "]").first().attr("height")) !== parseInt(viewBoxArgs[3])) {
                            parentSvgDiv.find("svg[id=" + positionSvgId + "]").first().attr("height",parseInt(viewBoxArgs[3]));
                        }

                        $('#vol-' + positionSvgId).val(this.defaultSliderLogoScaleValue(kukriKitDesignProduct, positionSvgId));

                        if ($('#opac-' + positionSvgId).length !== 0) {
                            $('#opac-' + positionSvgId).val(this.defaultSliderLogoOpacityScaleValue());
                            parentSvgDiv.find("svg[id=" + positionSvgId + "]").first().css('opacity', this.defaultSliderLogoOpacityScaleValue())
                        }
                    }

                    break;
            }

            if (positionSvgId.indexOf("FRONT") >= 0) {
                kukriKitDesignProduct.imageFront = parentSvgDiv.html();
            } else if (positionSvgId.indexOf("BACK") >= 0) {
                kukriKitDesignProduct.imageBack = parentSvgDiv.html();
            } else if (positionSvgId.indexOf("LEFT") >= 0) {
                kukriKitDesignProduct.imageLeft = parentSvgDiv.html();
            } else if (positionSvgId.indexOf("RIGHT") >= 0) {
                kukriKitDesignProduct.imageRight = parentSvgDiv.html();
            }
        } else if (!alternative) {
            alert("Limit to move in the chosen direction has reached.");
        }
    }

    public defaultSliderLogoScaleValue(product: any, positionSvgId: any) {

        let oParser = new DOMParser();
        let oSerializer = new XMLSerializer();
        let imageSide = positionSvgId.split('_').pop().toLowerCase();
        let firstLetter = imageSide.charAt(0).toUpperCase();
        let remaningLetters = imageSide.slice(1);

        imageSide = `image${firstLetter}${remaningLetters}`

        let imageString = product[imageSide];
        let imageXML = oParser.parseFromString(imageString, "application/xml");
        let svgLogoPositionIdToUpdate = imageXML.getElementById(positionSvgId);

        if (svgLogoPositionIdToUpdate) {
            let childImageLogo = svgLogoPositionIdToUpdate.querySelector('image');
            let originalWidth: number = Number(childImageLogo.getAttribute('width'));
            let originalHeight: number = Number(childImageLogo.getAttribute('height'));
            let returnVal = (Math.round((originalHeight / originalWidth /10) * 100) / 100).toFixed(1);

            // @ts-ignore
            return Math.round((originalHeight / originalWidth /10) * 100) / 100 > 0.05 ? 100 * returnVal : 0;
        }

        return 0
    }

    public defaultSliderLogoOpacityScaleValue() {
        return .5;
    }

    public rotateLogoNameNumber(parentSvgDiv: any, positionSvgId: string, increment: boolean) : void {
        let adjustment = increment ? 5 : -5
        let logoSvg = parentSvgDiv.find("svg[id=" + positionSvgId + "]").first();
        let height = parseInt(logoSvg.attr('height'));
        let width = parseInt(logoSvg.attr('width'));

        if (logoSvg.attr('viewBox')) {

            let viewBoxArgs = logoSvg.attr('viewBox').split(" ");

            if (width !== parseInt(viewBoxArgs[2])) {
                width = parseInt(viewBoxArgs[2]);
            }

            if (height !== parseInt(viewBoxArgs[3])) {
                height = parseInt(viewBoxArgs[3]);
            }
        }

        let sY = (height/2);
        let sX = (width/2);
        let rotateAngle;

        logoSvg.css('overflow','visible');

        let gElement = logoSvg.find('g');
        let gElementFound = gElement.length > 0

        if (!gElementFound) {
            gElement = $('<g/>');
            gElement.append(logoSvg.find(":first-child"));
        }

        let transform = $(gElement).attr('transform');

        if (transform) {
            // console.log('transform', transform);
            let strRot = transform.split("(");
            let angle = strRot[1].split(" ");
            rotateAngle = parseInt(angle[0]) + adjustment;
        } else {
            rotateAngle = adjustment;
        }

        if ([-360, 360].indexOf(rotateAngle) !== -1) {
            rotateAngle = 0;
        }

        let prepareString = "rotate("+rotateAngle +" "+sX+" "+sY +")";

        // console.log('prepareString', prepareString);
        $(gElement).attr('transform',prepareString);

        if (!gElementFound) {
            logoSvg.append(gElement);
        }
    }

    public removeDummyImages(kukriKitDesignProduct: any): void {
        if (kukriKitDesignProduct.imageFront != undefined && kukriKitDesignProduct.imageFront != null) {
            let parentSvgDiv = $("<div/>").html(kukriKitDesignProduct.imageFront);
            parentSvgDiv.find(".dummy").find("g").empty();
            kukriKitDesignProduct.imageFront = parentSvgDiv.html();
        }

        if (kukriKitDesignProduct.imageBack != undefined && kukriKitDesignProduct.imageBack != null) {
            let parentSvgDiv = $("<div/>").html(kukriKitDesignProduct.imageBack);
            parentSvgDiv.find(".dummy").find("g").empty();
            kukriKitDesignProduct.imageBack = parentSvgDiv.html();
        }

        if (kukriKitDesignProduct.imageLeft != undefined && kukriKitDesignProduct.imageLeft != null) {
            let parentSvgDiv = $("<div/>").html(kukriKitDesignProduct.imageLeft);
            parentSvgDiv.find(".dummy").find("g").empty();
            kukriKitDesignProduct.imageLeft = parentSvgDiv.html();
        }

        if (kukriKitDesignProduct.imageRight != undefined && kukriKitDesignProduct.imageRight != null) {
            let parentSvgDiv = $("<div/>").html(kukriKitDesignProduct.imageRight);
            parentSvgDiv.find(".dummy").find("g").empty();
            kukriKitDesignProduct.imageRight = parentSvgDiv.html();
        }
    }

    public initialiseColours(kukriKitDesignProduct: any, selectedMainColour?: string, selectedSecondaryColour?: string,
                             selectedDetailColour?: string, selectedBrandingColour?: string,
                             selectedFeatureColour?: string, featureColours?: any): void {
        let mainColour = this.findColourForSelectColourType(kukriKitDesignProduct, ColourTypeEnum.MainColour);
        let secondaryColour = this.findColourForSelectColourType(kukriKitDesignProduct, ColourTypeEnum.SecondaryColour);
        let detailColour = this.findColourForSelectColourType(kukriKitDesignProduct, ColourTypeEnum.DetailColour);
        let brandingColour = this.findColourForSelectColourType(kukriKitDesignProduct, ColourTypeEnum.BrandingColour);
        let featureColour = this.findColourForSelectColourType(kukriKitDesignProduct, ColourTypeEnum.FeatureColour);

        if (selectedMainColour != undefined && selectedMainColour != "") {
            // TODO: Product Colour Palette is not set...
            let kukriColour: any = this.findColourFromColourList(selectedMainColour, kukriKitDesignProduct.productColours);

            if (kukriColour != undefined) {
                mainColour = selectedMainColour;
            }
        }

        if (selectedSecondaryColour != undefined && selectedSecondaryColour != "") {
            let kukriColour: any = null;

            if (kukriKitDesignProduct.secondaryColours != null) {
                kukriColour = this.findColourFromColourList(selectedSecondaryColour,
                    kukriKitDesignProduct.secondaryColours);
            } else {
                kukriColour = this.findColourFromColourList(selectedSecondaryColour,
                    kukriKitDesignProduct.productColours);
            }

            if (kukriColour != undefined) {
                secondaryColour = selectedSecondaryColour;
            }
        }

        if (selectedDetailColour != undefined && selectedDetailColour != "") {
            let kukriColour: any = this.findColourFromColourList(selectedDetailColour,
                kukriKitDesignProduct.detailColours);

            if (kukriColour != undefined) {
                detailColour = selectedDetailColour;
            }
        }

        if (selectedBrandingColour != undefined && selectedBrandingColour != "") {
            let kukriColour: any = this.findColourFromColourList(selectedBrandingColour,
                kukriKitDesignProduct.brandingColours);

            if (kukriColour != undefined) {
                brandingColour = selectedBrandingColour;
            }
        }

        if (selectedFeatureColour != undefined && selectedFeatureColour != "") {
            let kukriColour: any = this.findColourFromColourList(selectedFeatureColour, featureColours);

            if (kukriColour != undefined) {
                featureColour = selectedFeatureColour;
            }
        }

        // initialise colours
        if (brandingColour != null) {
            let brandingKukriColour: any = this.findColourFromColourList(brandingColour,
                kukriKitDesignProduct.brandingColours);
            kukriKitDesignProduct.selectedBrandingColour = brandingKukriColour;
            this.applyColourToAttributeType(brandingColour, kukriKitDesignProduct, "BRANDING");
        }

        if (mainColour != null) {
            this.initialiseGarmentColours(kukriKitDesignProduct, mainColour, ColourTypeEnum.MainColour);
        }

        if (secondaryColour != null) {
            this.initialiseGarmentColours(kukriKitDesignProduct, secondaryColour, ColourTypeEnum.SecondaryColour);
        }

        if (detailColour != null) {
            this.initialiseGarmentColours(kukriKitDesignProduct, detailColour, ColourTypeEnum.DetailColour);
        }

        if(brandingColour != null) {
            this.initialiseGarmentColours(kukriKitDesignProduct, brandingColour, ColourTypeEnum.BrandingColour);
        }

        if (featureColour != null) {
            this.initialiseGarmentColours(kukriKitDesignProduct, featureColour, ColourTypeEnum.FeatureColour,
                featureColours);
        }
    }

    private initialiseGarmentColours(kukriKitDesignProduct: any, hexValue: any, selectedColourTypeEnum: ColourTypeEnum,
                                     featureColours?: any): void {
        if (selectedColourTypeEnum == ColourTypeEnum.MainColour) {
            let kukriColour: any = this.findColourFromColourList(hexValue, kukriKitDesignProduct.productColours);
            let cloneKukriColour = this.createDefaultColourClone(kukriColour, hexValue);
            kukriKitDesignProduct.selectedMainColour = cloneKukriColour;
        }

        if (selectedColourTypeEnum == ColourTypeEnum.SecondaryColour) {
            if (kukriKitDesignProduct.secondaryColours == null) {
                let kukriColour: any = this.findColourFromColourList(hexValue, kukriKitDesignProduct.productColours);
                let cloneKukriColour = this.createDefaultColourClone(kukriColour, hexValue);
                kukriKitDesignProduct.selectedSecondaryColour = cloneKukriColour;
            } else {
                let kukriColourSecondary: any =
                    this.findColourFromColourList(hexValue, kukriKitDesignProduct.secondaryColours);
                let cloneKukriColourSecondary = this.createDefaultColourClone(kukriColourSecondary, hexValue);
                kukriKitDesignProduct.selectedSecondaryColour = cloneKukriColourSecondary;
            }
        }

        if (selectedColourTypeEnum == ColourTypeEnum.DetailColour) {
            let kukriDetailColour: any = this.findColourFromColourList(hexValue, kukriKitDesignProduct.detailColours);
            let cloneKukriDetailColour = this.createDefaultColourClone(kukriDetailColour, hexValue);
            kukriKitDesignProduct.selectedDetailColour = cloneKukriDetailColour;
        }

        // TODO - should all colours be
        if (selectedColourTypeEnum == ColourTypeEnum.BrandingColour) {
            let kukriBrandingColour: any = this.findColourFromColourList(hexValue, kukriKitDesignProduct.brandingColours);
            kukriKitDesignProduct.selectedBrandingColour = kukriBrandingColour;
        }

        if (selectedColourTypeEnum == ColourTypeEnum.FeatureColour && featureColours && featureColours.length > 1) {
            let kukriFeatureColour: any = this.findColourFromColourList(hexValue, featureColours);
            let cloneKukriFeatureColour = this.createDefaultColourClone(kukriFeatureColour, hexValue);
            kukriKitDesignProduct.selectedFeatureColour = cloneKukriFeatureColour;
        }

        this.applyColourToDesign(hexValue, selectedColourTypeEnum, kukriKitDesignProduct);
    }

    dummyKukriColor(hexValue: any) {
        this.missingColourExists = true
        // @ts-ignore
        return {attributeType: null, class: "com.kukri.core.domain.value.KukriColour", dataP: null, description: ' Deprecated '+hexValue, hexValue: hexValue, id: null, pantoneRef: null, simpleColour: "", sortOrder: null, oldColourDescription: null}
    }

    public createDefaultColourClone(kukriTypeColour: any, hexValue: any) {
        return kukriTypeColour ? ($.isArray(kukriTypeColour) ? [].concat(kukriTypeColour) : Object.create(kukriTypeColour)) :
            $.isArray(hexValue) ? hexValue.map((hex: any) => this.dummyKukriColor(hex)) : this.dummyKukriColor(hexValue)
    }


    public useHexValue: any = null;

    public gradientStops = ['1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th']
    public numGradientStops: number
    public gradientCallBack: any
    public gradientHexValuePos: number = 0
    public gradientInitialOnly: boolean = false
    public gradientExists: boolean = false
    public missingColourExists: boolean = false

    public setGradientHexValuePos(pos: number) {
        this.gradientHexValuePos = pos
    }

    public getGradientHexValuePos() {
        return this.gradientHexValuePos
    }

    public showSelectedColour(selectedColours: any, currentIdx?:  number) {
        const useIdx = currentIdx !== undefined ? currentIdx : this.gradientHexValuePos
        return $.isArray(selectedColours) && useIdx <= selectedColours.length -1
    }

    public setGradientInitialOnly(event: any) {
        this.gradientInitialOnly = event.target.checked
    }

    public getGradientInitialOnly() {
        return this.gradientInitialOnly
    }

    public setUseHexValue(hexValue: any) {
        this.useHexValue = hexValue
    }

    public getUseHexValue() {
        return this.useHexValue
    }


    public colorUpdatable(myIndex: number, kukriAlternateDesigns: any, kukriKitDesignProduct: any, selectedColourTypeEnum?: any) {

        let selectedExists = typeof this.useHexValue === 'string' || this.gradientHexValuePos === 0  ||
            (myIndex !== null && kukriAlternateDesigns &&
                kukriAlternateDesigns[myIndex].designName === kukriKitDesignProduct.designName)

        if (selectedExists && myIndex !== null && kukriAlternateDesigns) {
            if (!this.findColourForSelectColourType(kukriAlternateDesigns[myIndex], selectedColourTypeEnum)) {
                selectedExists = false;
            }
        }

        return selectedExists
    }


    public compatibleColourSourceArray(sourceArray?: any) {
        const colourArray = sourceArray ? sourceArray : this.useHexValue

        return !this.gradientInitialOnly && $.isArray(this.useHexValue) && $.isArray(colourArray)
            && colourArray.length -1 >= this.gradientHexValuePos
    }

    public isColorMatched(chosenHexSource: any, compareHexValue: any) {

        const isString = (hexValue: any) => {
            return typeof hexValue === 'string'
        }

        const colorMatched = (chosenHexValue: any) => {
            if (isString(chosenHexValue)) {
                return compareHexValue.toUpperCase() === chosenHexValue.toUpperCase()
            }

            return chosenHexValue.filter((hex: any) => {
                if (!isString(hex)) {
                    console.log('isColorMatched error', compareHexValue, hex)
                }
                return hex && compareHexValue.toUpperCase() === hex.toUpperCase()
            }).length > 0

        }

        return chosenHexSource && (
            isString(chosenHexSource) ? colorMatched(chosenHexSource) :
            $.isArray(chosenHexSource) ? isString(chosenHexSource[0]) ? colorMatched(chosenHexSource) :
                chosenHexSource.filter((entry: any) => entry.hexValue &&
                colorMatched(entry.hexValue)).length > 0 :
                chosenHexSource.hexValue && colorMatched(chosenHexSource.hexValue)
        )
    }

    public fillAttribute(element: any) {
        return $(element).attr("fill")
    }

    public isGradientType(element: any): boolean {
        const gradientElement = this.fillAttribute(element)
        return gradientElement && gradientElement.indexOf('url') !== -1
    }

    public findGradient(element: any) {
        this.gradientExists = true
        const gradientTypeUsage = this.fillAttribute(element)
        return $(element).parent().parent().find("[id='" + gradientTypeUsage.substring(gradientTypeUsage.indexOf('(') +2, gradientTypeUsage.indexOf(')')) + "']")
    }

    public fillGradient(element: any, hexValue: any) {
        const hexValueIsArray = $.isArray(hexValue)
        let nextHexValue = 0
        let nextStop = 0

        $.each(this.findGradient(element).children('stop'), (i: number, stop: any) => {
            const availableHexValue = nextHexValue < (hexValueIsArray ? hexValue.length : 1)
            const useHexValue = hexValueIsArray && availableHexValue ? hexValue[nextHexValue] : hexValue

            if (nextStop === 0 || availableHexValue) {
                $(stop).attr('stop-color', useHexValue)
            }

            nextHexValue++
            nextStop++
        });
    }

    public selectedColourDisplayDefault(selectedColours: any, defaultString: string): any {

        const type = defaultString.indexOf("...") > 0 ? 'colourName' : defaultString.indexOf("Choose") > -1 ? 'description' : 'hexValue'

        const standardiseDash = (str: any) => {
            return str.replace(' -', ' - ').replace('- ', ' - ').replace('-', ' - ')
        }

        const processArrayColours = (colourArray: any) => {
            const returnType = colourArray.map((selectedColour: any) => {
                if (selectedColour[type]) {
                    return standardiseDash(selectedColour[type])
                } else {
                    return ' Deprecated Colour '
                }
            })

            const isHexValueRtn = type === 'hexValue'
            const returnValue = defaultString === 'raw' ? returnType :
                isHexValueRtn ? 'linear-gradient('+returnType.reverse().join(', ') +')' :
                    returnType.join(' & ')

            return returnValue
        }

        if ($.isArray(selectedColours.hexValue)) {
            return processArrayColours(selectedColours.hexValue)
        } else if (!$.isArray(selectedColours) && selectedColours[type]) {
            return standardiseDash(selectedColours[type])
        } else if ($.isArray(selectedColours)) {
            return processArrayColours(selectedColours)
        }

        return defaultString
    }

    public setBackgroundImage(selectedBackground: any) {
        return $.isArray(selectedBackground) || $.isArray(selectedBackground.hexValue)
    }

    public isLightHexValue(hexValue: any, test?: boolean): any {
        let c = hexValue.substring(1);
        let rgb = parseInt(c, 16);
        let r = (rgb >> 16) & 0xff;
        let g = (rgb >>  8) & 0xff;
        let b = (rgb >>  0) & 0xff;

        let luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
        let isLight = luma > 128


        if (luma < 40) {
            // pick a different colour
        }

        return test ? isLight : isLight ? 'darkFont' : 'lightFont'
    }

    private correctForGradients(parentSvgDiv: any, selectedColourTypeFilter: string) {

        const element = parentSvgDiv.find(selectedColourTypeFilter).first()

        if (this.isGradientType(element)) {

            const hexValues: any[] = []

            $.each(this.findGradient(element).children('stop'), (i: number, stop: any) => {
                hexValues.push($(stop).attr('stop-color').toUpperCase());
            });

            return hexValues
        } else {
            return element.attr("fill")
        }
    }

    public findColourForSelectColourType(kukriKitDesignProduct: any, selectedColourTypeEnum: ColourTypeEnum): any {
        let selectedColourHexValue: string = null;
        let parentSvgDiv;
        let selectedColourTypeFilter = "[data-p='" + (selectedColourTypeEnum + 1) + "']";

        if (kukriKitDesignProduct.imageFront != undefined) {
            parentSvgDiv = $("<div/>").html(kukriKitDesignProduct.imageFront);

            if (selectedColourTypeEnum == ColourTypeEnum.BrandingColour) {
                selectedColourHexValue = parentSvgDiv.find('[id*="_BRANDING_"]').find("[fill]").first().attr("fill");
            } else {
                // selectedColourHexValue = parentSvgDiv.find(selectedColourTypeFilter).first().attr("fill");
                selectedColourHexValue = this.correctForGradients(parentSvgDiv, selectedColourTypeFilter);
            }
        }

        if (selectedColourHexValue == null) {
            if (kukriKitDesignProduct.imageBack != undefined) {
                parentSvgDiv = $("<div/>").html(kukriKitDesignProduct.imageBack);

                if (selectedColourTypeEnum == ColourTypeEnum.BrandingColour) {
                    selectedColourHexValue = parentSvgDiv.find('[id*="_BRANDING_"] path').first().attr("fill");
                } else {
                    // selectedColourHexValue = parentSvgDiv.find(selectedColourTypeFilter).first().attr("fill");
                    selectedColourHexValue = this.correctForGradients(parentSvgDiv, selectedColourTypeFilter);
                }
            }
        }

        if (selectedColourHexValue == null) {
            if (kukriKitDesignProduct.imageLeft != undefined) {
                parentSvgDiv = $("<div/>").html(kukriKitDesignProduct.imageLeft);
                // selectedColourHexValue = parentSvgDiv.find(selectedColourTypeFilter).first().attr("fill");
                selectedColourHexValue = this.correctForGradients(parentSvgDiv, selectedColourTypeFilter);
            }
        }

        if (selectedColourHexValue == null) {
            if (kukriKitDesignProduct.imageRight != undefined) {
                parentSvgDiv = $("<div/>").html(kukriKitDesignProduct.imageRight);
                // selectedColourHexValue = parentSvgDiv.find(selectedColourTypeFilter).first().attr("fill");
                selectedColourHexValue = this.correctForGradients(parentSvgDiv, selectedColourTypeFilter);
            }
        }

        return selectedColourHexValue;
    }

    public correctHexValueSelectionForGradients(hexValue: any, selectedHexColours: any) {

        if ($.isArray(selectedHexColours)) {
            let chosenHexColours: any[] = []

            $.each(selectedHexColours, (i: number, selectedHexColour: any) => {
                chosenHexColours.push(selectedHexColour.hexValue)
            });

            const start = this.useHexValue === null || $.isArray(this.useHexValue) ? this.gradientHexValuePos : 0

            if (start <= chosenHexColours.length -1) {
                chosenHexColours.splice(start, 1, hexValue)
            }

            return chosenHexColours

        } else {
            return hexValue
        }
    }
}
