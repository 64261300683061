export enum BsInputType {
	BUTTON = 'button',
	CHECKBOX = 'checkbox',
	COLOR = 'color',
	DATE = 'date',
	DATE_TIME_LOCAL = 'datetime-local',
	EMAIL = 'email',
	FILE = 'file',
	HIDDEN = 'hidden',
	IMAGE = 'image',
	MONTH = 'month',
	NUMBER = 'number',
	PASSWORD = 'password',
	RADIO = 'radio',
	RANGE = 'range',
	RESET = 'reset',
	SEARCH = 'search',
	SUBMIT = 'submit',
	TELEPHONE = 'telephone',
	TEXT = 'text',
	TIME = 'time',
	URL = 'url',
	WEEK = 'week'
}