<a *ngIf="isRouterLink" routerLink="/product-variations/{{ productGroupId }}/{{ product.baseProductId }}" skipLocationChange>
    <ng-template [ngTemplateOutlet]="productContainer"></ng-template>
</a>

<ng-container *ngIf="!isRouterLink">
    <a *ngIf="productURL != null && !onlyImageClickable;else onlyImageClickableContainer" [href]="productURL" class="shop-item" (click)="onClicked(product, productPosition)">
        <ng-template [ngTemplateOutlet]="productContainer"></ng-template>
    </a>

    <ng-template #onlyImageClickableContainer class="shop-item">
        <a *ngIf="productURL != null;else imageWrap" [href]="productURL" (click)="onClicked(product, productPosition)">
            <ng-template [ngTemplateOutlet]="imageWrap"></ng-template>
        </a>
        <ng-template [ngTemplateOutlet]="details"></ng-template>
    </ng-template>
</ng-container>

<ng-template #imageWrap>
    <div class="shop-item__image-wrap" (click)="onClicked(product, productPosition)">
        <ng-content select="product-item-image"></ng-content>
        <ng-content select="product-item-image-svg"></ng-content>
        <ng-content select="product-item-tag"></ng-content>
        <ng-content select="product-item-logos"></ng-content>
    </div>
</ng-template>
<ng-template #details>
    <div class="shop-item__details">
        <div class="shop-item__details__title" [class.color]="titleColor">
            {{ product.description}}
<!--            <span *ngIf="product.outofstockLit && product.outofstockLit.length > 0">-->
<!--                <br/><small>({{ product.outofstockLit.indexOf(" - ") < 0 ? product.outofstockLit : product.outofstockLit.substr(0, product.outofstockLit.indexOf(" - ")) }})</small>-->
<!--            </span>-->
        </div>
        <ng-content select="product-item-code"></ng-content>
        <ng-content select="product-item-price"></ng-content>
        <ng-content></ng-content>
    </div>
    <ng-content select="product-item-personalise"></ng-content>
</ng-template>
<ng-template #productContainer>
    <ng-template [ngTemplateOutlet]="imageWrap"></ng-template>
    <ng-template [ngTemplateOutlet]="details"></ng-template>
</ng-template>