export enum ColourTypeEnum {
  MainColour,
  SecondaryColour,
  DetailColour,
  BrandingColour,
  FeatureColour
}

export enum GarmentBackgroundColourEvent {
  BRANDING,
  NAME,
  NUMBER
}

export enum PositionMovementEnum {
  UP,
  DOWN,
  RIGHT,
  LEFT,
  CLOCK_WISE,
  ANTI_CLOCK_WISE,
  RESET,
}

