import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ShopadminAddProductGroupComponent } from "./shopadmin-add-product-group.component";
import { MatDialogModule } from "@angular/material/dialog";
import { BsButtonModule } from "../../../components/bs-button/bs-button.module";
import { BsInputModule } from "../../../components/bs-input/bs-input.module";

@NgModule({
	imports: [FormsModule,
	          CommonModule,
	          MatDialogModule,
	          BsButtonModule,
	          BsInputModule,
	          ReactiveFormsModule],
    declarations: [ShopadminAddProductGroupComponent],
    exports: [ShopadminAddProductGroupComponent],
})
export class ShopadminAddProductGroupModule {}
