import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

import { ReturnArrangeDeliveryComponent } from './return-arrange-delivery.component';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ReactiveFormsModule
    ],
    declarations: [
        ReturnArrangeDeliveryComponent
    ],
    exports: [
        ReturnArrangeDeliveryComponent
    ],
    providers: [
    ]
})
export class ReturnArrangeDeliveryModule {
}