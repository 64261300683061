import { Component, OnInit } from "@angular/core";
import { KukriProduct } from "../models/kukri-product";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Location } from "@angular/common";

@Component({
    selector: "my-app",
    templateUrl: "./shopadmin-product-variation-edit.component.html",
    // styleUrls: ['./shopadmin-product-variation-edit.component.scss'],
})
export class ShopAdminProductVariationEditComponent implements OnInit {
    public shop: any;
    public site: any;
    public isKukriAdmin: boolean;

    public shopProductGroups: any;
    public prodGroupId: number;
    public baseProductId: number;
    public designTemplateId: number;

    public handShakeUrl: string;
    public shopProductVariation: KukriProduct;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private _location: Location
    ) {
        let inputJsonString = document.getElementById(
            "pageInputJson"
        ) as HTMLInputElement;
        let jsonData = JSON.parse(inputJsonString.value);
        console.log(jsonData);

        if (jsonData.shop) this.shop = jsonData.shop;
        if (jsonData.site) this.site = jsonData.site;
        if (jsonData.isKukriAdmin) this.isKukriAdmin = jsonData.isKukriAdmin;
        if (jsonData.handShakeUrl) this.handShakeUrl = jsonData.handShakeUrl;
        if (jsonData.shopProductGroups)
            this.shopProductGroups = jsonData.shopProductGroups;

        // TODO: Need to get this from the JSON Data in the same way this was done for the producation variation table
        // if (jsonData.prodGroupId) this.prodGroupId = jsonData.prodGroupId;
        // if (jsonData.shopProductVariation) this.shopProductVariation = jsonData.shopProductVariation;
        // TODO: Will need to test how to accomodate designs as well...
        if (jsonData.designTemplateId)
            this.designTemplateId = jsonData.designTemplateId;

        // Reflect.ownKeys(this).forEach(k => {
        //     console.log(k);
        //     console.log(Reflect.get(this, k));
        // });
    }

    ngOnInit() {
        // // TODO: This may be better as a service
        // this.route.params
        //     .subscribe((data: Params) => {
        //         console.log(data);
        //         this.prodGroupId = parseInt(data.productGroupID);
        //         this.baseProductId = parseInt(data.productID);
        //     })

        //     this.shopProductGroups.filter(productGroup => {
        //         // debugger;
        //         if (productGroup.id === this.prodGroupId) {
        //             console.log(productGroup);
        //             productGroup.variationProducts.filter(variationProduct => {
        //                 if (variationProduct.baseProductId === this.baseProductId) {
        //                     this.productVariations.push(variationProduct);
        //                 }
        //             })
        //             console.log(this.productVariations);
        //             this.baseProduct = this.productVariations[0];
        //         }
        //     });

        console.log("shop", this.shop);
        console.log("site", this.site);
        console.log("isKukriAdmin", this.isKukriAdmin);

        console.log("shopProductGroups", this.shopProductGroups);
        console.log("prodGroupId", this.prodGroupId);
        console.log("designTemplateId", this.designTemplateId);

        console.log("handShakeUrl", this.handShakeUrl);

        // console.log("shopProductVariation", this.shopProductVariation);
        // console.log(this.router.getCurrentNavigation().extras.state);

        this.shopProductVariation = window.history.state.productVariation;
        console.log("shopProductVariation", this.shopProductVariation);
    }

    public goBack() {
        // this.router.navigate(['retrieveProductsNg.action']);
        this._location.back();
    }
}
