import { Component, Input } from "@angular/core";

@Component({
    selector: "product-item-code",
    templateUrl: "./product-item-code.component.html",
})
export class ProductItemCodeComponent {
    @Input() public productItemCode: string;

    constructor() {}
}
