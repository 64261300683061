import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { CustomerLoginServiceImpl } from "./services/customer-login.service-impl";
import { CustomerLoginModalComponent } from "./containers/customer-login-modal.component";
import { AuthService, AuthServiceConfig } from "angularx-social-login";
import { socialAuthServiceConfig } from "./customer-login.bootstrap.module";
import { ToastrModule } from "ngx-toastr";

@NgModule({
    imports: [CommonModule, FormsModule, ToastrModule.forRoot()],
    exports: [CustomerLoginModalComponent],
    declarations: [CustomerLoginModalComponent],
    providers: [
        AuthService,
        {provide: AuthServiceConfig,
         useFactory: socialAuthServiceConfig},
        CustomerLoginServiceImpl
    ]
})
export class CustomerLoginModalModule { }