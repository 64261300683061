import { Injectable } from "@angular/core";
import { ProductListService } from "./product-list.service";

@Injectable()
export class ProductListServiceImpl implements ProductListService {
    constructor() {}

    public imageOverride(imageName: string): boolean {
        let nameSplit = imageName.split("_");
        return nameSplit.length > 2;
    }
}
