import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsInputComponent } from './bs-input.component';
import { FormsModule } from "@angular/forms";

@NgModule({
	declarations: [BsInputComponent],
	exports:      [
		BsInputComponent
	],
	imports: [
		CommonModule,
		FormsModule
	]
})
export class BsInputModule { }
