import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { HttpClientService } from "../shared/service/impl/http-client.service";
import { SysCountryServiceImpl } from "../shared/service/impl/SysCountryServiceImpl";
import { SafeHtmlPipeModule } from "../shared/common/safehtml-pipe.module";
import { ShopAdminEditShopComponent } from "./shopadmin-edit-shop/shopadmin-edit-shop.component";
import { ShopAdminDashboardComponent } from "./shopadmin-dashboard/shopadmin-dashboard.component";
import { LoaderModule } from "../loader/loader.module";
import { ProductItemServiceImpl } from "../product-item/services/product-item.service";
import { AlertService } from "../components/alert/services/alert.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";


@NgModule({
    imports: [
        LoaderModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        ToastrModule.forRoot(),
        BrowserAnimationsModule,
        SafeHtmlPipeModule
    ],
    declarations: [
        ShopAdminEditShopComponent,
        ShopAdminDashboardComponent
    ],
    exports: [
        LoaderModule,
        ShopAdminEditShopComponent,
        ShopAdminDashboardComponent,
        SafeHtmlPipeModule
    ],
    providers: [
        SysCountryServiceImpl,
        HttpClientService,
        AlertService,
        ProductItemServiceImpl
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ShopAdminModule {}
