import { Component, Input, OnInit } from "@angular/core";
import { TableRow } from "../models/table-row";

@Component({
    selector: "[bs-table-head]",
    templateUrl: "./bs-table-head.component.html",
    // styleUrls: ['./bs-table-head.component.scss']
})
export class BsTableHeadComponent implements OnInit {
    @Input() public row: TableRow;

    constructor() {}

    ngOnInit() {}
}
