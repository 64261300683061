import { Component, OnInit, OnDestroy, enableProdMode } from "@angular/core";
import { Subscription } from "rxjs";
import { LoaderServiceImpl } from "../shared/service/impl/LoaderServiceImpl";

enableProdMode();

@Component({
    selector: "angular-loader",
    templateUrl: "./loader.component.html",
})
export class LoaderComponent implements OnInit, OnDestroy {
    show: boolean = false;

    private subscription: Subscription;

    constructor(private loaderService: LoaderServiceImpl) {}

    ngOnInit() {
        //console.log("loader init called")
        this.subscription = this.loaderService
            .getLoaderHandler()
            .subscribe((state: boolean) => {
                this.show = state;
            });
    }

    ngOnDestroy() {
        //console.log("Destroy is called");
        this.subscription.unsubscribe();
    }
}
