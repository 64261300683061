import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsInputType } from "./models/bs-input-type";

@Component({
  selector:    'bs-input',
  templateUrl: './bs-input.component.html'
})
export class BsInputComponent implements OnInit {

  // Attributes
  @Input() id: string | number = '';
  @Input() type: BsInputType = BsInputType.TEXT;
  @Input() name?: string = '';
  @Input() classes?: string = '';
  @Input() placeholder?: string = '';
  @Input() pattern?: string;
  @Input() value?: string | number | boolean;
  @Input() minLength?: number | string = '';
  @Input() maxLength?: number | string = '';
  @Input() isChecked?: boolean;
  @Input() isReadOnly?: boolean;
  @Input() isDisabled?: boolean;
  @Input() isRequired?: boolean;
  @Input() isAutoFocus?: boolean;

  // Additional Elements Specific to This Component
  @Input() labelText: string;
  @Input() labelClasses?: string;
  @Input() showCharacterLimit?: boolean;

  @Output() readonly onClickEvent: EventEmitter<this> = new EventEmitter<this>();
  @Output() readonly focusOutEvent: EventEmitter<this> = new EventEmitter<this>();
  @Output() readonly keyPressEvent: EventEmitter<this> = new EventEmitter<this>();

  constructor() {}

  ngOnInit() {
  }

  public onClick() {
    if(this.type === BsInputType.CHECKBOX) {
      this.isChecked = this.isChecked == undefined ? true : !this.isChecked;
    }

    this.onClickEvent.emit(this);
  }

  public onFocusOut() {
    this.focusOutEvent.emit(this);
  }

  public onKeyPress(value: any) {
    this['keyPressed'] = value.keyCode;
    this.keyPressEvent.emit(this);
  }

}