import { Component, EventEmitter, Input, Output } from "@angular/core";
import { KukriProduct } from "../shopadmin/models/kukri-product";

@Component({
    selector: "product-item",
    templateUrl: "./product-item.component.html",
})
export class ProductItemComponent {
    @Input() public product: KukriProduct;
    @Input() public productPosition: number;
    @Input() public productURL: string;
    @Input() public shop: any;
    @Input() public productListName?: string = "No Name Set";

    @Input() public onlyImageClickable?: boolean;
    @Input() public productGroupId?: any;
    @Input() public isRouterLink?: boolean = false;
    @Input() public titleColor?: string = '';

    @Output() readonly clicked: EventEmitter<any> = new EventEmitter<any>();

    onClicked(product: any, index: number) {
        console.log(product);
        this.clicked.emit({
            product,
            productListName: this.productListName,
            index,
        });
    }

    constructor() {}
}
