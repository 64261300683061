import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import { LoaderServiceImpl } from "../shared/service/impl/LoaderServiceImpl";
import { LoaderComponent } from "./loader.component";

@NgModule({
    imports: [CommonModule, MatProgressBarModule, MatProgressSpinnerModule],
    exports: [MatProgressBarModule, MatProgressSpinnerModule, LoaderComponent],
    declarations: [LoaderComponent],
    providers: [LoaderServiceImpl],
})
export class LoaderModule {}
