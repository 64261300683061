import { TableCell } from "./table-cell";

export class TableRow {
	public target: any;
	public id: number;
	public cells: Array<TableCell> = [];

	constructor(target: any, id: number, ...cells:Array<string | number | boolean>) {
		this.target = target;
		this.id = id;

		if(cells != null) {
			cells.forEach(cellContent => this.cells.push(new TableCell(cellContent)));
		}
	}

	public appendCell(content: string | number | boolean, scope?: string): TableRow {
		this.cells.push(new TableCell(content, scope));
		return this;
	}
}