import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

declare  var $:any;

@Pipe({name: 'resizesvg'})
export class SvgReSizePipe implements PipeTransform {
 constructor(private sanitizer:DomSanitizer){}
  transform(html : string, width : number, height : number, resizeMeasurement : string) {
  	try {
	  	let $html = $.parseHTML(html);
	  	if (resizeMeasurement != undefined && resizeMeasurement != null && resizeMeasurement == "%") {
	  			$($html).find('svg').first().parent('svg').attr("width", width + resizeMeasurement );
	  			$($html).find('svg').first().parent('svg').attr("height" , height + resizeMeasurement);
	  	} else {
	  		$($html).find('svg').first().parent('svg').attr("width", width );
	  		$($html).find('svg').first().parent('svg').attr("height" , height);
	  	}
	  	$($html).find('svg').first().parent('svg').attr('viewBox','0 0 500 500');
      
      let parentSvgDiv = $('<div/>').html($($html).find('svg').first().parent('svg').wrap('<div></div>').parent().html());
           parentSvgDiv.find('.dummy').find('g').empty();
           html = parentSvgDiv.html();
      
      
     // $($html).find('svg').find('.dummy').find('g').empty();
      
	  	//html = $($html).find('svg').first().parent('svg').wrap('<div></div>').parent().html();
      
	    return this.sanitizer.bypassSecurityTrustHtml(html);
  	} catch (e) {
  		console.log(e);
  	}
  	return null;
  }
}