import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsButtonComponent } from './bs-button.component';

@NgModule({
  declarations: [BsButtonComponent],
  exports:      [
    BsButtonComponent
  ],
  imports:      [
    CommonModule
  ]
})
export class BsButtonModule { }
