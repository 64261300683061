<h2 mat-dialog-title>Add New Group</h2>
<form mat-dialog-content class="h-100" (ngSubmit)="createNewProductGroup(form.value, groupHideCheckbox.isChecked)" #form="ngForm" novalidate>
    <div class="form-group">
        <label for="groupName" class="font--light">Group Name</label>
        <input type="text" class="form-control" [class.is-error]="
            groupNameText.errors?.required &&
            groupNameText.dirty
        " id="groupName" name="groupName" required placeholder="Enter Group Name" #groupNameText="ngModel" [ngModel]="productGroup.name" />
        <p *ngIf="groupNameText.errors?.required && groupNameText.dirty" class="text_colour_error mt-1">
            Group name is required
        </p>
    </div>

    <div class="form-group">
        <label for="groupSortOrder" class="font--light">Group Sort Order</label>
        <input minlength="1" min="1" maxlength="4" max="4" type="number" class="form-control" [class.is-error]="
                groupSortOrderNumber.errors?.required &&
                groupSortOrderNumber.dirty
            " id="groupSortOrder" name="groupSortOrder" required placeholder="Enter Sort Order Number" #groupSortOrderNumber="ngModel" [ngModel]="productGroup.sortOrder" />
        <p *ngIf="
                groupSortOrderNumber.errors?.required &&
                groupSortOrderNumber.dirty
            " class="text_colour_error mt-1">
            Sort Order is Required
        </p>
    </div>

    <div class="form-group">
        <label class="font--light">Hide Group</label>
<!--        <label for="groupHide">Hidden</label>-->
<!--        <input type="checkbox" class="form-control" id="groupHide" name="groupHide" [ngModel]="!productGroup.statusCode" />-->
        <bs-input
                #groupHideCheckbox
                [id]="'groupHide'"
                [name]="'groupHide'"
                [type]="'checkbox'"
                [classes]="'form-control'"
                [labelText]="'Hidden'"
                [isChecked]="productGroup.statusCode"
                [value]="productGroup.statusCode"
        ></bs-input>
    </div>

    <div class="pb-2">
        <bs-button class="pb-2" [classes]="'btn btn-secondary btn--lg w-100'" [text]="'Cancel'" (click)="closeAddGroupModal()"></bs-button>
    </div>
    <button type="submit" class="btn btn-secondary btn--lg w-100" [disabled]="form.invalid" [class.btn--disabled]="form.invalid">
        Submit
    </button>
</form>