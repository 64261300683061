export class NavLink {
	private readonly href: string;
	public text: string;
	public adminAccessRequired: boolean = false;
	public shopUrl: string;
	public allowApplyShopUrl: boolean = true;
	public urlParams: Map<string, string | number | boolean> = new Map<string, string | number | boolean>();
	public active: boolean; // TODO - Saw this being used in bs-nav-base component, don't see anywhere it is being set so placing here as false for now

	constructor(href: string, text: string, adminAccessRequired?: boolean, shopUrl?: string, allowApplyShopUrl?: boolean, params?: Map<string, string>) {
		this.href = href;
		this.text = text;
		this.shopUrl = shopUrl;

		if(adminAccessRequired != null) {
			this.adminAccessRequired = adminAccessRequired;
		}
		if(allowApplyShopUrl != null) {
			this.allowApplyShopUrl = allowApplyShopUrl;
		}
		if(params != null) {
			this.urlParams = params;
		}
	}

	public addUrlParam(key: string, value: string | number | boolean): NavLink {
		this.urlParams.set(key, value);
		return this;
	}

	/**
	 * Every 1st, 3rd, 5th, etc. params will be considered keys and every 2nd, 4th, 6th, etc. params will be considered values
	 *
	 * For example: addParams("prodGroupName", "All Products", "productCount", 123, 5000, "entry5000")
	 * The above call of this method would produce a JSON list of {status: "success", itemCount: 123}
	 *
	 * @param params
	 */
	public addUrlParams(...params: any[]): NavLink {
		for(let index = 0;index < params.length;index += 2) {
			this.urlParams.set(params[index], params[index+1])
		}

		return this;
	}

	public addUrlParamsFromObject(paramObject: any): NavLink {
		for(let key of Reflect.ownKeys(paramObject)) {
			const value = Reflect.get(paramObject, key);
			this.urlParams.set(String(key), value);
		}
		return this;
	}

	public addUrlParamsFromMap(paramMap: Map<string | number, string | number | boolean>): NavLink {
		for(let param of Array.from(paramMap.keys())) {
			this.urlParams.set(String(param), paramMap.get(param));
		}
		return this;
	}

	public getHref(shopUrl?: string) {
		let result = this.href;

		if(shopUrl != null && shopUrl != "") {
			this.shopUrl = shopUrl;
		}

		if(this.allowApplyShopUrl && this.shopUrl != null && this.shopUrl != "") {
			result = this.href.startsWith("/") || this.shopUrl.endsWith("/") ? this.shopUrl + this.href : this.shopUrl + "/" + this.href;
		} else {
			result = this.href;
		}

		if(this.urlParams != null && this.urlParams.size > 0) {
			let isFirstIteration = true;

			this.urlParams.forEach((value, key) => {
				if(isFirstIteration) {
					result += "?" + key + "=" + value;
					isFirstIteration = false;
				} else {
					result += "&" + key + "=" + value;
				}
			});

			this.urlParams.clear();
		}

		return result;
	}
}