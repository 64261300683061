import {Injectable} from "@angular/core";
import {AuthService, FacebookLoginProvider, GoogleLoginProvider} from "angularx-social-login";
import {Observable} from "rxjs";
import {HttpClientService} from "../../shared/service/impl/http-client.service";
import {CustomerLoginService} from "./customer-login.service";
import {Customer} from "../models/customer.interface";
import {SocialSignInUser} from "../models/social-signin-user.interface";

@Injectable()
export class CustomerLoginServiceImpl implements CustomerLoginService {

	constructor(private httpClientService: HttpClientService, private authService: AuthService) {
	}

	public login(submitData: any, customers: Array<Customer>, errors: Array<String>, infoMessages: Array<String>) {
		let uri: string = "customerSigninConfirmNg.action";
		this.httpClientService.postAjaxForm(uri, submitData).subscribe(response => {
				if (response != null) {
					console.log(errors);
					if (response["infoMessages"] != undefined && response["infoMessages"] != null) {
						infoMessages.length = 0;
						response["infoMessages"].forEach((message: string) => errors.push(new String(message)));
					}

					if (response["errors"] != undefined && response["errors"] != null) {
						errors.length = 0;
						response["errors"].forEach((message: string) => errors.push(new String(message)));

						if (response["customers"] != undefined && response["customers"] != null) {
							customers.length = 0;
							customers["customers"].forEach((message: string) => customers.push(eval(message)));
							console.log("customers: " + customers);
						}
					} else {
						this.updateHeaderLoginButtons(true);
						// redirect to different page
						window.location.href = window.location.protocol + "//" + window.location.host + "/teamshop/" + response["redirectUrl"];
					}
				}
			},
			error => console.log(error));
	}

	public silentLogin(submitData: any): Observable<Response> {
		let uri: string = "customerSigninConfirmNg.action";
		return this.httpClientService.postAjaxForm(uri, submitData);
	}


	public register(submitData: any, j_email: string, j_password: string, infoMessages: Array<String>, errors: Array<String>): any {
		let uri: string = "customerRegistrationConfirmationNg.action";

		let customer = submitData.customer;
		customer.user.username = customer.email;
		customer.passwordConfirm = submitData.confPassword;

		this.httpClientService.postAjaxForm(uri, submitData).subscribe(response => {
			if (response["infoMessages"]) {
				infoMessages.length = 0;
				response["infoMessages"].forEach((message: string) => infoMessages.push(new String(message)));
			}

			if (response["errors"]) {
				errors.length = 0;
				response["errors"].forEach((message: string) => errors.push(new String(message)));
				return null;
			} else {
				this.updateHeaderLoginButtons(true);

				return {
					"j_username":   customer.user.username,
					"j_email":      j_email,
					"j_password":   j_password,
					"accountType":  submitData.accountType,
					"fullUserName": true
				}
			}
		});
	}

	public silentRegisterationAndSignIn(submitData: any): Observable<Response> {
		let uri: string = "customerRegistrationConfirmationNg.action";

		let customer = submitData.customer;
		customer.user.username = customer.email;
		customer.passwordConfirm = submitData.confPassword;

		return this.httpClientService.postAjaxForm(uri, submitData);
	}

	public signInWithFb(socialSignInUser: SocialSignInUser, customer: Customer, customers: Array<Customer>, kukriKitDesignProduct: any, errors: Array<String>, kdFlag: boolean = false, productFlag: boolean = false, reloadPageOnSuccess: boolean = true): Promise<void> {
		return this.socialMediaSignIn(socialSignInUser, FacebookLoginProvider.PROVIDER_ID, customer, customers, kukriKitDesignProduct, errors, kdFlag, productFlag, reloadPageOnSuccess);
	}

	public signInWithGoogle(socialSignInUser: SocialSignInUser, customer: Customer, customers: Array<Customer>, kukriKitDesignProduct: any, errors: Array<String>, kdFlag: boolean = false, productFlag: boolean = false, reloadPageOnSuccess: boolean = true): Promise<void> {
		return this.socialMediaSignIn(socialSignInUser, GoogleLoginProvider.PROVIDER_ID, customer, customers, kukriKitDesignProduct, errors, kdFlag, productFlag, reloadPageOnSuccess);
	}

	private socialMediaSignIn(socialSignInUser: SocialSignInUser, providerId: string, customer: Customer, customers: Array<Customer>, kukriKitDesignProduct: any, errors: Array<String>, kdFlag: boolean, productFlag: boolean, reloadPageOnSuccess: boolean): Promise<void> {
		return this.authService.signIn(providerId).then((response) => {
			if (!socialSignInUser) {
				socialSignInUser = response;
			}

			// Condensing the data retrieved from X Provider's social login API down to only what is required
			var condensedSocialSignInUser = {
				"id":        socialSignInUser.id,
				"authToken": socialSignInUser.authToken,
				"idToken":   socialSignInUser.idToken,
				"email":     socialSignInUser.email,
				"firstName": socialSignInUser.firstName,
				"lastName":  socialSignInUser.lastName,
				"name":      socialSignInUser.name,
				"provider":  socialSignInUser.provider,
				"photoUrl":  socialSignInUser.photoUrl,
				"kdFlag":    kdFlag
			};

			this.httpClientService.postAjaxForm("customerSigninConfirmSocialMediaNg.action", condensedSocialSignInUser).subscribe(response => {
				this.socialMediaSignOut(); // Sign user out on Provider's end, as a session with them isn't required

				if (errors) {
					errors.length = 0;
				}
				if (customers) {
					customers.length = 0;
				}

				if (response["errors"] && response["errors"].length > 0) {
					if (errors != null) {
						response["errors"].forEach((message: string) => errors.push(new String(message)));
					}

					if (response["customers"] && response["customers"].length > 0) {
						if (customers != null) {
							customers.length = 0;
							response["customers"].forEach((customer: string) => customers.push(eval(customer)));
						}
					}

					customer.firstname = socialSignInUser.firstName;
					customer.surname = socialSignInUser.lastName;
					customer.email = socialSignInUser.email;
				} else {
					this.updateHeaderLoginButtons(true);

					if (
						response["customer"] != undefined &&
						response["customer"] != null
					) {
						const newCustomer = response["customer"];
						customer.id = newCustomer.id;
						customer.title = newCustomer.title;
						customer.firstname = newCustomer.firstname;
						customer.surname = newCustomer.surname;
						customer.email = newCustomer.email;
						customer.accountType = newCustomer.accountType;
						customer.companyName = newCustomer.companyName;
						customer.user = newCustomer.user;

						if (kukriKitDesignProduct != undefined) {
							kukriKitDesignProduct.customer = customer;
						}
					}
					if (
						response["customerLogos"] != undefined &&
						response["customerLogos"] != null
					) {
						let customerLogos: Array<any> = response["customerLogos"];
						if (productFlag) {
							if(kukriKitDesignProduct == null) {
								kukriKitDesignProduct = new Array<any>();
							}

							for (let customerLogo of customerLogos) {
								kukriKitDesignProduct.push(customerLogo);
							}
						} else {
							if (
								kukriKitDesignProduct == undefined ||
								kukriKitDesignProduct.customerLogos == undefined
							) {
								kukriKitDesignProduct.customerLogos = new Array<any>();
							}

							for (let customerLogo of customerLogos) {
								kukriKitDesignProduct.customerLogos.push(customerLogo);
							}
						}
					}

					if (reloadPageOnSuccess) {
						window.location.href = window.location.protocol + "//" + window.location.host + "/teamshop/" + response["redirectUrl"];
					}
				}

				return response;
			});
		})
			.catch(e => {
				console.log(e);
			});
	}

	private socialMediaSignOut() {
		this.authService.signOut().catch(e => console.log(e));
	}

	public updateHeaderLoginButtons(isCustomerLoggedIn: boolean) {
		if (isCustomerLoggedIn) {
			$('.action-account').removeAttr("hidden").show();
			$('.action-login').hide();
		} else {
			$('.action-account').hide();
			$('.action-login').removeAttr("hidden").show();
		}
	}


}