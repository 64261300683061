import { Component, enableProdMode, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "angularx-social-login";
import { SocialSignInUser } from "./models/social-signin-user.interface";
import { CustomerLoginServiceImpl } from "./services/customer-login.service-impl";
import { HttpClientService } from "../shared/service/impl/http-client.service";
import { Customer } from "./models/customer.interface";

declare var $: any;

enableProdMode();

@Component({
    selector: "my-app",
    templateUrl: "./customer-login.component.html",
})
export class CustomerLoginComponent implements OnInit {
    // Customer Login Details

    public j_email: string;
    public j_username: string;
    public j_password: string;
    public accountType: string;
    public redirectUrl: string;
    public customers: any;
    public fullUserName: boolean;
    public selectedCustomer: any;
    public socialSignInUser: SocialSignInUser;

    public site: any;
    public shop: any;

    public errors: Array<String>;
    public infoMessages: Array<String>;

    // Customer Registeration Detail

    public customer: any;

    public confPassword: string;
    public newsLetterSignUp: boolean;
    public partnerMarketingFlag: boolean;

    public newsLetterInterests: Array<any> = [
        { name: "Rugby", checked: false, category: "sport" },
        { name: "Cricket", checked: false, category: "sport" },
        { name: "Hockey", checked: false, category: "sport" },
        { name: "Netball", checked: false, category: "sport" },
        { name: "Football", checked: false, category: "sport" },
        { name: "Athletics", checked: false, category: "sport" },
        { name: "Basketball", checked: false, category: "sport" },
        { name: "Schools", checked: false, category: "businessSector" },
        { name: "University", checked: false, category: "businessSector" },
        { name: "Club/Team", checked: false, category: "businessSector" },
    ];

    public countries: any;
    public loginViewSelected: string;

    // General Information

    public registrationErrors: Array<String>;

    constructor(
        private customerLoginService: CustomerLoginServiceImpl,
        private authService: AuthService,
        private httpClientService: HttpClientService
    ) {
        const jsonData = JSON.parse($("#pageInputJson").val());
        try {
            this.j_username = jsonData.j_username;
        } catch (e) {
            console.log(e);
        }
        try {
            this.customer = jsonData.customer;
        } catch (e) {
            console.log(
                "Customer Login Modal Component Failed to Init Customer: " + e
            );
        }

        if (jsonData.countries != undefined) {
            this.countries = jsonData.countries;
        }
        this.site = jsonData.site;

        // SPP 25.8.2018 - added for partnerMarketing functionality
        this.shop = jsonData.shop;

        this.redirectUrl = jsonData.redirectUrl;

        this.customers = new Array<Customer>();
        this.selectedCustomer = null;
        this.fullUserName = false;
        this.partnerMarketingFlag = false;

        this.errors = new Array<String>();
        this.infoMessages = new Array<String>();

        this.loginViewSelected = "login";
    }

    ngOnInit() {
        this.authService.authState.subscribe(
            userData => (this.socialSignInUser = userData)
        );
    }

    public login(isFormValid: boolean) {
        if (isFormValid) {
            let uri: string = "customerSigninConfirmNg";
            let submitData = {
                j_username: this.j_username,
                j_email: this.j_email,
                j_password: this.j_password,
                accountType: this.accountType,
                fullUserName: this.fullUserName,
                redirectUrl: this.redirectUrl
            };

            this.httpClientService.postAjaxForm(uri, submitData).subscribe(
                response => {
                    if (response != null) {
                        if (response["infoMessages"])
                            this.infoMessages = response["infoMessages"];
                        if (response["customers"])
                            this.customers = response["customers"].sort((a:any, b:any) => {

                                if (a.user.accountName < b.user.accountName && !a.user.username.includes('.1') && b.user.username.includes('.1')) {
                                    return -1;
                                }

                                if (a.user.accountName > b.user.accountName && a.user.username.includes('.1') && !b.user.username.includes('.1')) {
                                    return 1;
                                }

                                return 0;
                            });
                        if (response["errors"]) {
                            return (this.errors = response["errors"]);
                        } else {
                            window.location.href = response["redirectUrl"];
                        }
                    }
                },
                error => console.log(error)
            );
        }
    }

    public silentLogin(): Observable<Response> {
        let submitData = {
            j_username: this.j_username,
            j_email: this.j_email,
            j_password: this.j_password,
            accountType: this.accountType,
            fullUserName: this.fullUserName,
            kdFlag: true,
            redirectUrl: this.redirectUrl
        };

        return this.customerLoginService.silentLogin(submitData);
    }

    public register(isFormValid: boolean) {
        if (isFormValid) {
            let uri: string = "customerRegistrationConfirmationNg.action";

            this.customer.user.username = this.customer.email;
            this.customer.passwordConfirm = this.confPassword;

            let submitData = {
                customer: this.customer,
                confPassword: this.confPassword,
                accountType: this.accountType,
                newsLetterSignUp: this.newsLetterSignUp,
                newsLetterInterests: this.newsLetterInterests,
                partnerMarketingFlag: this.partnerMarketingFlag,
            };

            // TODO: Look into throwing an error for an error response
            // This should then allow the use of .map and .catch
            this.httpClientService
                .postAjaxForm(uri, submitData)
                .subscribe(response => {
                    if (response != null) {
                        if (response["infoMessages"])
                            this.infoMessages = response["infoMessages"];
                        if (response["errors"]) {
                            this.registrationErrors = response["errors"];
                        } else {
                            const oldPass = this.customer.password;
                            this.customer = response["customer"];
                            this.customer.password = oldPass;
                            this.j_username = this.customer.user.username;
                            this.j_password = this.confPassword;
                            this.fullUserName = true;
                            this.login(true);
                        }
                    }
                });
        }
    }

    public silentRegisterationAndSignIn(): Observable<Response> {
        let submitData = {
            customer: this.customer,
            confPassword: this.confPassword,
            accountType: this.accountType,
            newsLetterSignUp: this.newsLetterSignUp,
            newsLetterInterests: this.newsLetterInterests,
            partnerMarketingFlag: this.partnerMarketingFlag,
        };

        return this.customerLoginService.silentRegisterationAndSignIn(
            submitData
        );
    }

    public chooseUser(customerArg: any) {
        this.j_username = customerArg.user.username;
        this.fullUserName = true;
        this.selectedCustomer = customerArg;
    }

    public signInWithFb() {
        this.customerLoginService.signInWithFb(
            this.socialSignInUser,
            this.customer,
            this.customers,
            null,
            this.errors,
            false
        );
    }

    public signInWithGoogle() {
        this.customerLoginService.signInWithGoogle(
            this.socialSignInUser,
            this.customer,
            this.customers,
            null,
            this.errors,
            false
        );
    }

    public setLoginViewSelected(loginViewSelected: string) {
        console.log(loginViewSelected);
        this.loginViewSelected = loginViewSelected;
        console.log(this.loginViewSelected);

        $(`#${loginViewSelected}`).tab("show");
    }
}
